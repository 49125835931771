import { connect } from 'react-redux';
import React, { Component } from 'react';
import './styles.css';

class Footer extends Component {
    render() {
        return (
            <div className="container-fluid" style={{ backgroundColor: '#EBEBEB', position: 'fixed', bottom: 0, right: 0, left: 0, zIndex: 1 }}>
                <div className="container">
                    <div className="col-12 py-3">
                        <p className="saloon-footer-text">&copy; Copyright 2020 - 2021 Easy 1. All Right Reserved</p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(Footer);