import { connect } from "react-redux";
import React, { Component } from "react";
import Sidebar from "react-sidebar";

import { DotsLoader, Header } from "../../components";
import {
  SidebarContent,
  Footer,
  CustomTable,
  TableContainerHeader,
  Popup,
} from "../../components/SuperAdmin";
import {
  getResource,
  createResource,
  deleteResource,
  updateResource
} from "../../config/simpleApiCalls";
import { get_plans_Api, update_plan_Api, delete_plan_api, get_bundle, edit_bundle, create_bundle } from "../../config/WebServices";
import { Images } from "../../theme";
import Swal from "sweetalert2";
import { ErrorHelper, SuccessHelper } from "../../helpers";

import Sytles from "./styles";
import "./styles.css";

class PricingPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      showPopUp: false,
      superAdminData: null,
      categoryName: "",
      name: "",
      itemName: "",
      isCheck: false,
      title: "",
      categroyImageUrl: null,
      categroyImageFile: null,
      categroyImageName: "",
      formErrorText: "",
      freeTrial: false,
      twoToFive: false,
      fiveToTen: false,
      tenToFifteen: false,
      allPlans: [],
      showModal: false,
      isShowPlain: true,
      licens: [{}],
      bundles: [{}],
      devices: [{}],
      bundleId: null
    };
  }

  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    this.setState({ superAdminData }, () => {
      this.getAllPlans()
      this.getAllBundle()
    });
  }

  onChangePrice = (text) =>
    this.setState({ planPrice: text.target.value });

  onChangeEditItemName = (e, data, index) => {

    const { itemData } = this.state;
    let planItems = [...itemData];

    planItems[index].name = e.target.value
    this.setState({ planItems })
  }

  renderCheckBox = (e, data, index) => {
    const { itemData } = this.state;
    let planItems = [...itemData];
    if (data?.isActive) {
      planItems[index].isActive = false
    } else {
      planItems[index].isActive = true
    }

    this.setState({ planItems })
  }

  renderCreateCheckBox = (e, data) => {
    const { isCheck, isActive } = this.state

    this.setState({ isCheck: !isCheck })


  }

  OnchangeName = (text) => {
    this.setState({ name: text.target.value });
  }

  getAllPlans = () => {
    const { superAdminData } = this.state;

    this.setState({ isloading: true });

    getResource(get_plans_Api, superAdminData?.access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            allPlans: res.data.data,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  getAllBundle = () => {
    const { superAdminData } = this.state;

    this.setState({ isloading: true });

    getResource(get_bundle, superAdminData?.access_token)
      .then((res) => {
        console.log("getAllBundle -> res", res)
        if (res.data.success) {
          this.setState({
            isloading: false,
            bundles: res?.data?.data?.bundle || [],
            licens: res?.data?.data?.licensing || [],
            devices: res?.data?.data?.device_and_equipment || [],
            bundleId: res?.data?.data?._id || null
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        console.log("getAllBundle -> error", error)
        this.setState({ isloading: false });
      });
  };

  updateBundle = async () => {

    const {
      bundleId,
      bundles,
      licens,
      devices,
      superAdminData
    } = this.state
    
    let flag = '';

    [licens, devices, bundles].forEach((arr, index) => {

      arr.forEach(item => {
        if(!item.title){
           if(index === 2) return flag = 'Please Add Bundle Up Title Properly'
           if(index === 1) return flag = 'Please Add Device & Equipment Title Properly'
           if(index === 0) return flag = 'Please Add Licensing Title Properly'
        } else if(!item.price) {
            if(index === 2) return flag = 'Please Add Bundle Up Price Properly'
            if(index === 1) return flag = 'Please Add Device & Equipment Price Properly'
            if(index === 0) return flag = 'Please Add Licensing Price Properly'
        }
      })
    });

    
    console.log("renderUpdatePlan -> flag", flag)

    if(flag) return ErrorHelper.handleErrors(flag, true);
    let payload = {
      bundle: bundles,
      device_and_equipment: devices,
      licensing: licens
    }

    payload = {
      bundle: payload.bundle.map(el =>  {return {...el, quantity: 0}}),
      device_and_equipment: payload.device_and_equipment.map(el =>  {return {...el, quantity: 0}}),
      licensing: payload.licensing
    }

    console.log("renderUpdatePlan -> payload", payload)


    this.setState({ isloading: true });
    
    let request;
    if (bundleId) {
      payload.bundleId = bundleId
      request = updateResource(edit_bundle, payload, superAdminData?.access_token)
    } else {
      request = createResource(create_bundle, payload, superAdminData?.access_token)
    }
    request
      .then((res) => {
      console.log("updateBundle -> res", res)
        if (res.data.success) {
          console.log("updateBundle -> res.data.success", res.data.success)
          this.getAllBundle()
          SuccessHelper.handleSuccess(`Update Successfully.`, true);
          this.setState({
            isloading: false,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        console.log("updateBundle -> error", error)
        ErrorHelper.handleErrors("Some thing went wrong", true);
        this.setState({ isloading: false });
      });
  }

  deletePlanItem = (data) => {
    const { itemData } = this.state;

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#354e68",
      cancelButtonColor: "#ff3600",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // let planItems = [...itemData];
        for (var i = 0; i < itemData.length; i++) {
          if (itemData[i] === data) {
            itemData.splice(i, 1);
            i--;
          }
        }
        this.setState({ planItems: itemData, itemData })
      }
    });
  };



  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderAddButton = () => {
    return (
      <div
        data-toggle="modal"
        data-dismiss="modal"
        data-target="#exampleModalAddMore"
        style={{ color: '#FF5B2F', fontWeight: 'bold', padding: '20px' }}>
        Add more
      </div>
    )
  }

  renderUpdatePlan = () => {

    const {
      planId,
      planPrice,
      superAdminData,
      planName,
      planItems,
    } = this.state

    this.setState({ isloading: true });

    planItems && planItems.map(v => {
      delete v._id
    })


    const payload = {
      planId: planId,
      plan: planName,
      price: planPrice,
      items: planItems
    }

    updateResource(update_plan_Api, payload, superAdminData?.access_token)
      .then((res) => {
        if (res.data.success) {
          this.getAllPlans()
          SuccessHelper.handleSuccess(`Update Successfully.`, true);
          this.setState({
            isloading: false,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors("Some thing went wrong", true);
        this.setState({ isloading: false });
      });
  }

  renderAddData = () => {
    const { itemData, planPrice, planName } = this.state

    return (
      <div class="container-fluid">
        <div class="form-group col-md-6">
          <label for="exampleInputEmail1">Update Price:</label>
          <input
            type="text"
            value={planPrice}
            class="form-control"
            onChange={(text) =>
              this.onChangePrice(text)
            }
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
          />
        </div>
        <div class="row">
          {itemData?.map((val, index) => {

            return (
              <div className="col-md-6 d-flex align-items-center mt-2">
                <div style={{ marginRight: 10, alignItems: 'center' }}>
                  <input type="checkbox" checked={val.isActive} onChange={(e) => this.renderCheckBox(e, val, index)} />
                </div>
                <div className="d-flex" style={{ alignItems: 'center' }}>
                  <input
                    type="text"
                    value={val.name}
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={(text) => this.onChangeEditItemName(text, val, index)
                    }
                  />
                  <img
                    style={{ height: 12, width: 12, marginRight: 8, cursor: "pointer", marginLeft: 15 }}
                    src={Images.table_delete}
                    onClick={() => this.deletePlanItem(val)}
                  />
                </div>
              </div>
            )
          })}
        </div>
        {this.renderAddButton()}
      </div >
    )
  }

  createItemInArrey = () => {
    const { name, isCheck, itemData, planId,
      planPrice,
      superAdminData,
      planName,
      planItems, } = this.state
    let isActive = isCheck
    // append multiple values to the array
    itemData.push({ name, isActive });
    this.setState({ planItems: itemData }, () => {
      this.renderUpdatePlan()
    })

  }

  renderCreateItem = () => {
    const { isCheck } = this.state

    return (
      <div>
        <div class="container-fluid d-flex" style={{ alignItems: 'center' }} >
          <div className="form-group mt-4">
            <input type="checkbox"
              checked={isCheck}
              onChange={(e) => this.renderCreateCheckBox(e, isCheck)}
            />
          </div>

          <div class="form-group col-md-6">
            <label for="exampleInputEmail1">Name:</label>
            <input
              type="text"
              onChange={(text) => this.OnchangeName(text)}
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div >
        <div className="modal-footer" style={{ borderTop: "none" }}>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            style={{
              backgroundColor: "#fff",
              borderColor: "#FF3600",
              color: "#000",
            }}
          >
            Cancel
            </button>
          <button
            type="button"
            data-dismiss="modal"
            onClick={() => { this.createItemInArrey() }}
            className="btn btn-primary"
            style={{ backgroundColor: "#FF3600", borderColor: "#FF3600" }}
          >
            Create
            </button>
        </div>
      </div>
    )
  }


  renderTickMark = (data) => {

    if (data.isActive) {
      return <img
        style={{ height: 18, width: 18 }}
        src={Images.mark_tick}
      />
    } else {
      return <img
        style={{ height: 18, width: 18 }}
        src={Images.cancel_cross}
      />
    }
  }



  renderCreateCheckBox = (e, data) => {
    const { isCheck, isActive } = this.state

    this.setState({ isCheck: !isCheck })

  }

  pricingCard = () => {
    const { allPlans } = this.state

    return (
      <div class="pricing">
        <div class="row pr-2 pl-2">
          {allPlans.map((val, index) => {
            return (
              <div class="col-lg-3">
                <div class="card mb-5 mb-lg-0">
                  <div class="card-body">
                    <div>
                      <h5 class="card-title text-muted text-uppercase text-center">{val.plan}</h5>
                      <h6 class="card-price text-center">${val?.price}</h6>
                    </div>
                    <hr />
                    <div>
                      <ul style={{ listStyleType: 'none' }}>
                        {
                          val?.items?.map((itemVal, index) => {
                            return (
                              <li style={{ marginLeft: '-34px' }}>
                                <div className="d-flex align-items-center">
                                  <div style={{ marginRight: 10 }}>
                                    {this.renderTickMark(itemVal)}
                                  </div>
                                  <div style={{ fontSize: 10 }}>
                                    {itemVal.name}
                                  </div>
                                </div>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                    <div>
                      <button
                        data-toggle="modal"
                        data-target="#exampleModal"
                        className="btn btn-block btn-primary"
                        onClick={() => this.setState({
                          itemData: val?.items,
                          planPrice: val?.price,
                          planName: val.plan,
                          planId: val._id
                        })}
                        style={{ backgroundColor: "#FF3600", borderColor: "#FF3600" }}
                      >
                        Update
                </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  handleAdd = (key) => {
    this.setState({ [key]: [...this.state[key], {}] })
  }

  handleValueChange = (arr, index, key, value) => {
    let data = this.state[arr]
    data[index][key] = value;
    this.setState({ [arr]: data })
  }

  handleDelete = (arr, index) => {
    let data = this.state[arr]
    data.splice(index, 1)
    this.setState({ [arr]: data })
  }

  BundleCard = () => {
    const { bundles, devices, licens } = this.state

    return (
      <div className="col-12 p-3">
        <div className="col-12 p-3 bg-white" style={{ borderRadius: "1rem" }}>
          <h4>Bundle Up</h4>
          <button
            data-toggle="modal"
            className="btn  btn-primary"
            // style={{ position: 'absolute', width: 50 }}
            onClick={() => this.handleAdd('bundles')}
            style={{ backgroundColor: "#FF3600", borderColor: "#FF3600" }}
          >
            Add Item
                </button>
          <div style={{ borderBottomColor: '#d1d1d1', borderBottomWidth: 5, borderBottomStyle: 'solid' }} className="col-12 mb-3">
            {bundles.map((value, index) => (
              <div key={index} className="row col-12 p-5">
                <div className="col-5">
                  <label for="categoryName">Enter Title:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="categoryName"
                    placeholder={"Enter Title"}
                    onChange={e => this.handleValueChange('bundles', index, 'title', e.target.value)}
                    value={value.title || ""}
                  />
                </div>

                <div className="col-5">
                  <label for="categoryName">Enter Price:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="categoryName"
                    placeholder={"Enter Price"}
                    onChange={e => this.handleValueChange('bundles', index, 'price', e.target.value)}
                    value={value.price || ""}
                  />
                </div>
                <div className="col-2">
                  <label for="categoryName" style={{ color: 'transparent' }}>DELETE</label>
                  <button
                    data-toggle="modal"
                    className="btn btn-block  btn-warning"
                    // style={{ position: 'absolute', width: 50 }}
                    onClick={() => this.handleDelete('bundles', index)}
                    style={{ borderColor: "#FF3600" }}
                  >
                    Delete
                </button>
                </div>


              </div>
            ))}
          </div>

          <h4>Device & Equipment</h4>
          <button
            data-toggle="modal"
            className="btn  btn-primary"
            onClick={() => this.handleAdd('devices')}
            // style={{ position: 'absolute', width: 50 }}
            // onClick={() => this.setState({ isShowPlain: !isShowPlain })}
            style={{ backgroundColor: "#FF3600", borderColor: "#FF3600" }}
          >
            Add Item
                </button>
          <div style={{ borderBottomColor: '#d1d1d1', borderBottomWidth: 5, borderBottomStyle: 'solid' }} className="col-12 mb-3">
            {devices.map((value, index) => (
              <div key={index} className="row col-12 p-5">
                <div className="col-5">
                  <label for="categoryName">Enter Title:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="categoryName"
                    placeholder={"Enter Title"}
                    onChange={e => this.handleValueChange('devices', index, 'title', e.target.value)}
                    value={value.title || ""} />
                </div>

                <div className="col-5">
                  <label for="categoryName">Enter Price:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="categoryName"
                    placeholder={"Enter Price"}
                    onChange={e => this.handleValueChange('devices', index, 'price', e.target.value)}
                    value={value.price || ""}
                  />
                </div>

                <div className="col-2">
                  <label for="categoryName" style={{ color: 'transparent' }}>DELETE</label>
                  <button
                    data-toggle="modal"
                    className="btn btn-block  btn-warning"
                    // style={{ position: 'absolute', width: 50 }}
                    onClick={() => this.handleDelete('devices', index)}
                    style={{ borderColor: "#FF3600" }}
                  >
                    Delete
                </button>
                </div>

              </div>
            ))}
          </div>

          <h4>Licensing</h4>
          <button
            data-toggle="modal"
            className="btn  btn-primary"
            onClick={() => this.handleAdd('licens')}
            // style={{ position: 'absolute', width: 50 }}
            // onClick={() => this.setState({ isShowPlain: !isShowPlain })}
            style={{ backgroundColor: "#FF3600", borderColor: "#FF3600" }}
          >
            Add Item
                </button>
          <div style={{ borderBottomColor: '#d1d1d1', borderBottomWidth: 5, borderBottomStyle: 'solid' }} className="col-12 mb-3">
            {licens.map((value, index) => (
              <div key={index} className="row col-12 p-5">
                <div className="col-5">
                  <label for="categoryName">Enter Licens:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="categoryName"
                    placeholder={"Enter Title"}
                    onChange={e => this.handleValueChange('licens', index, 'title', e.target.value)}
                    value={value.title || ""}
                  />
                </div>

                <div className="col-5">
                  <label for="categoryName">Enter Price:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="categoryName"
                    placeholder={"Enter Price"}
                    onChange={e => this.handleValueChange('licens', index, 'price', e.target.value)}
                    value={value.price || ""}
                  />
                </div>

                <div className="col-2">
                  <label for="categoryName" style={{ color: 'transparent' }}>DELETE</label>
                  <button
                    data-toggle="modal"
                    className="btn btn-block  btn-warning"
                    // style={{ position: 'absolute', width: 50 }}
                    onClick={() => this.handleDelete('licens', index)}
                    style={{ borderColor: "#FF3600" }}
                  >
                    Delete
                </button>
                </div>

              </div>
            ))}
          </div>

          <div className="col-2">
            <label for="categoryName" style={{ color: 'transparent' }}>DELETE</label>
            <button
              data-toggle="modal"
              className="btn btn-block  btn-success"
              // style={{ position: 'absolute', width: 50 }}
              onClick={() => this.updateBundle()}
              style={{ borderColor: "#FF3600" }}
            >
              UPDATE
                </button>

          </div>
        </div>
      </div>
    );
  }

  render() {
    const { columns, allPlans, showModal, isShowPlain } = this.state;
    // const newArr = allPlans.map(val => {
    //   const keyArr = Object.keys(val)
    //   const obj = keyArr.map(j => {
    //     return {
    //       name: j,
    //       id: j,
    //       isActive: val[j]
    //     }
    //   })
    //   return obj
    // })

    return (
      <Sidebar
        sidebar={<SidebarContent {...this.props} />}
        open={this.state.sidebarOpen}
        onSetOpen={(val) => this.onSetSidebarOpen(val)}
        sidebarClassName="custom-sidebar"
        styles={Sytles}
      >
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          style={{ flex: 1, backgroundColor: "#F7F7F7" }}
        >
          {<Header {...this.props} onSetSidebarOpen={this.onSetSidebarOpen} />}
          <div className="container py-5">
            <div className="row">
              <div className="col-12 mt-4 mb-5 bg-white shadow rounded px-0">
                <TableContainerHeader
                  headingText={isShowPlain ? "Pricing & Plan" : 'Bundle'}
                  // buttonText="+Add"
                  dataTarget="#exampleModal"
                  dataToggle="modal"
                  buttonOnClick={() => { }}
                />
                <button
                  data-toggle="modal"
                  className="btn  btn-secondary"
                  style={{ position: 'absolute', width: 50 }}
                  // onClick={() => this.setState({
                  //   itemData: val?.items,
                  //   planPrice: val?.price,
                  //   planName: val.plan,
                  //   planId: val._id
                  // })}
                  onClick={() => this.setState({ isShowPlain: !isShowPlain })}
                  style={{ borderColor: "#FF3600" }}
                >
                  {!isShowPlain ? " Show Pricing & Plan" : 'Show Bundle'}
                </button>
                {isShowPlain && this.pricingCard()}
                {!isShowPlain && this.BundleCard()}
                <Popup
                  exampleModal="exampleModal"
                  heading="Edit Pricing & Plan"
                  confirm="Update"
                  confirmBtn={() => { this.renderUpdatePlan() }}
                  cancel="Cancel"
                  data={this.renderAddData()}
                  renderModal={showModal}
                />
                <Popup
                  exampleModal="exampleModalAddMore"
                  heading="Add Item"
                  data={this.renderCreateItem()}
                  renderModal={showModal}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(PricingPlan);
