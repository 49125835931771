import { connect } from "react-redux";
import React, { Component } from "react";
import Sidebar from "react-sidebar";
import { Images } from "../../theme";
import {
  SidebarContent,
  Footer,
  CustomTable,
  TableContainerHeader,
} from "../../components/SuperAdmin";
import { DotsLoader, Header } from "../../components";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import { getResource } from "../../config/simpleApiCalls";
import { salon_rating_reviews } from "../../config/WebServices";
import moment from "moment";
import "./styles.css";
import Sytles from "./styles";
import ReactStars from "react-rating-stars-component";
class SalonRatingAndReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          Header: "",
          Cell: (row) => {
            return (
              <div>
                <img height={45} src={row.row.original.companyLogo} />
              </div>
            );
          },
          id: "companyLogo",
        },
        {
          Header: "Name",
          accessor: "receiver.name",
        },
        // {
        //   Header: "Services",
        //   accessor: "services",
        // },
        {
          Header: "averageRating",
          Cell: (row) => this.renderRating(row.row.original.rate),
          id: "rate",
        },
        {
          Header: "Feed Back",
          accessor: "review",
        },
      ],
      data: [],
      isloading: true,
    };
  }
  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    this.setState({ superAdminData }, () => {
      this.handleGetTopRatedSalon();
    });
  }
  handleGetTopRatedSalon = (el) => {
    const { superAdminData } = this.state;
    getResource(salon_rating_reviews, superAdminData?.access_token)
      .then((res) => {
        console.log("res: ", res);
        if (res.data.success) {
          this.setState({
            isloading: false,
            data: res.data.data,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };
  handleNavigation = (route) => {
    this.props.history.push(route);
  };
  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };
  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };
  renderRating(rating) {
    return (
      <ReactStars
        count={5}
        value={rating}
        size={24}
        edit={false}
        activeColor="#FFD700"
      />
    );
  }
  render() {
    const { columns, data:all, searchText } = this.state;
    const data = all.filter(val => {
      if(!val?.receiver?.name) return false
      return val.receiver.name.toLocaleLowerCase().includes(searchText || '')
    })
    console.log("render -> data", data)
    return (
      <Sidebar
        sidebar={<SidebarContent {...this.props} />}
        open={this.state.sidebarOpen}
        onSetOpen={(val) => this.onSetSidebarOpen(val)}
        sidebarClassName="custom-sidebar"
        styles={Sytles}
      >
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          {<Header {...this.props} onSetSidebarOpen={this.onSetSidebarOpen} />}
          <div className="container pt-5">
            <div className="row">
              <div className="col-12 mt-4 bg-white shadow rounded px-0">
                <TableContainerHeader
                  headingText="Salon Ratings & Reviews"
                  onSearchVal={searchText => this.setState({searchText})}
                  // buttonText="+Add"
                  buttonOnClick={() => { }}
                />
                <CustomTable columns={columns} data={data} />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Sidebar>
    );
  }
}
const mapStateToProps = (state) => ({});
const action = {};
export default connect(mapStateToProps, action)(SalonRatingAndReview);