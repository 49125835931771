import { connect } from "react-redux";
import React, { Component } from "react";

import "./styles.css";
import { Images } from "../../theme";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterDescription: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      filterDescription: nextProps.homePageData,
    });
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderPowerfulAdminPanel = () => {
    return (
      <div
        className="container-fluid py-5"
        style={{ backgroundColor: "#F7F8FA" }}
      >
        <div className="container mt-lg-3 my-xl-5">
          <div className="row">
            <div className="col-12 col-lg-6 order-lg-2 text-lg-left text-center pb-5">
              <img
                className=""
                src={Images.admin_panel_laptop_1}
                id="admin-panel-laptop"
              />
            </div>
            <div className="col-12 col-lg-6">
              <h2 id="admin-panel-heading">Powerful &mdash; Admin Panel</h2>
              {this.renderAdminPanelCard()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderAdminPanelCard = () => {
    const cardDetail = [
      {
        img: Images.setting_icon,
        imgBgColor: "#FFD954",
        title: "Customization",
        detail:
          "There is no need to limit your imagination with predefine color scheme or block structure.",
      },
      {
        img: Images.setting_icon,
        imgBgColor: "#D249FF",
        title: "Collections",
        detail:
          "It is very important to take attention to the most value element of each store.",
      },
      {
        img: Images.setting_icon,
        imgBgColor: "#2879FE",
        title: "Reports",
        detail:
          "Does someone read this? FREE HTML version for anyone who report about reading this block.",
      },
    ];
    return cardDetail.map((val, ind) => {
      return (
        <div key={ind} className="row mb-4 align-items-center">
          <div className="col-12 col-md-2 mb-3 mb-md-0">
            <span
              className="feature-icon shadow mx-auto"
              style={{ backgroundColor: val.imgBgColor }}
            >
              <img src={val.img} style={{ width: 50, height: 50 }} />
            </span>
          </div>
          <div className="col-12 col-md-10">
            <p className="mb-0 feature-heading">{val.title}</p>
            <p className="mb-0 feature-text">{val.detail}</p>
          </div>
        </div>
      );
    });
  };

  renderMuchMore = () => {
    return (
      <div className="container-fluid py-md-5 py-3">
        <div className="container mt-md-5 mt-4">
          <div className="col-12 mb-5">
            <h2 id="much-more-heading">And Much More...</h2>
          </div>
          <div className="col-lg-10 mx-auto">
            <div className="row">
              {this.renderMuchMoreCard()}
              {this.renderMuchMoreCard()}
              {this.renderMuchMoreCard()}
              {this.renderMuchMoreCard()}
              {this.renderMuchMoreCard()}
              {this.renderMuchMoreCard()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderMuchMoreCard = () => {
    return (
      <div className="col-md-4 mb-4">
        <span className="much-more-icon mx-auto mb-3">
          <img src={Images.setting_icon} style={{ width: 70, height: 70 }} />
        </span>
        <p className="much-more-sub-heading">Lorem Ipsum</p>
        <p className="much-more-des">
          Lorem ipsum dolor sit amet, consecture adipiscing elit, sed do
        </p>
      </div>
    );
  };

  renderDownloadSection = () => {
    return (
      <div
        className="container-fluid py-md-5 py-3"
        style={{ backgroundColor: "#ff3600" }}
      >
        <div className="container my-lg-5 ">
          <div className="row">
            <div className="col-md-6 order-md-2 text-center">
              <img src={Images.iphone_and_ipad} id="download-iphone-ipad" />
            </div>
            <div className="col-md-6">
              <h2 id="download-easy1-heading">Download EASY1</h2>
              <p className="text-white mb-4">
                Lorem ipsum dolor sit amet, consecture adipiscing elit, sed do
              </p>
              <span className="download-btn mr-2">
                <img src={Images.download_andriod} id="android-btn-img" />
              </span>
              <span className="download-btn ml-2">
                <img src={Images.download_ios} id="ios-btn-img" />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderNewletterCard = () => {
    return (
      <div className="container-fluid py-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 py-md-5 py-3 mx-auto" id="newletter-card">
              <h3 className="mb-md-5 mb-4" id="subscribe-heading">
                Subscribe to our newsletter
              </h3>
              <div className="d-flex flex-md-row flex-column justify-content-center align-items-center">
                <img
                  className="mb-3 mb-md-0"
                  src={Images.email_icon}
                  style={{ width: 30 }}
                />
                <input
                  type="email"
                  id="subscribe-input"
                  className="mb-3 mb-md-0"
                  name="email"
                  placeholder="email@example.com"
                />
                <span id="subscribe-btn" className="mb-3 mb-md-0">
                  Subscribe
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderAboutSection = () => {
    const { filterDescription } = this.state;
    return (
      <div className="container-fluid pb-4" id="about-sec">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-5 col-lg-3">
              <img src={Images.easy1_logo_800x300} style={{ width: 200 }} />
              <p>
                <small>{filterDescription?.footerDescription}</small>
              </p>
            </div>
            <div className="col-md-5 col-lg-3 text-md-right ml-auto pt-md-4 pb-2 pb-md-0">
              <img
                src={Images.facebook_icon}
                style={{ width: 45, marginRight: 15 }}
              />
              <img
                src={Images.instagram_icon}
                style={{ width: 45, marginRight: 15 }}
              />
              <img
                src={Images.youtube_icon}
                style={{ width: 45, marginRight: 15 }}
              />
              <img src={Images.google_plus_icon} style={{ width: 45 }} />
            </div>
          </div>
          <div
            className="row pt-2 px-md-4"
            style={{ borderTop: "1px solid #000" }}
          >
            <div className="col-md-5 col-lg-4">
              <p>
                <small>2020 copyright EASY1. All Rights Reserved</small>
              </p>
            </div>
            <div className="col-md-6 col-lg-5 text-md-right ml-auto">
              <span>
                <small>Legal Info</small>
              </span>
              <span className="mx-2">
                <small>&ndash;</small>
              </span>
              <span>
                <small>Privacy Policy</small>
              </span>
              <span className="mx-2">
                <small>&ndash;</small>
              </span>
              <span>
                <small>Cookie Policy</small>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderPowerfulAdminPanel()}
        {this.renderMuchMore()}
        {this.renderDownloadSection()}
        {this.renderNewletterCard()}
        {this.renderAboutSection()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(Footer);
