import { connect } from "react-redux";
import React, { Component, useImperativeHandle } from "react";
import Sidebar from "react-sidebar";

import { Images } from "../../theme";
import {
  SidebarContent,
  Footer,
  CustomTable,
  TableContainerHeader,
  Popup,
} from "../../components/SuperAdmin";
import { DotsLoader, Header } from "../../components";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import {
  getResource,
  deleteResource,
  updateResource,
  createResource
} from "../../config/simpleApiCalls";
import {
  get_all_salon_Api,
  delete_salon_Api,
  update_salon_personal_info_Api,
  update_salon_business_info_Api,
  get_salon_active_plans,
  update_salon_active_plans,
  impersonation
} from "../../config/WebServices";
import moment from "moment";
import "./styles.css";
import Sytles from "./styles";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SalonManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleValidationFirst: true,
      toggleValidationSecond: true,
      toggleValidationThird: true,
      allSalon: [],
      plans: [],
      columns: [
        {
          Header: "",
          Cell: (row) => {
            return (
              <div>
                <img height={45} src={row.row.original.companyImage} />
              </div>
            );
          },
          accessor: "companyImage",
        },
        {
          Header: "Company Name",
          accessor: "name",
        },
        {
          Header: "Address",
          accessor: "address",
        },
        {
          Header: "Email",
          accessor: "businessEmail",
        },
        {
          Header: "Telephone",
          accessor: "businessTelephone",
        },
        {
          Header: "Certificate",
          accessor: "businessCertificate",
        },
        {
          Header: "Status",
          Cell: (row) => this.renderTableEditDeleteIcon(row.row.original),
          accessor: "status",
        },
      ],
      isloading: true,
      data: [],

      // plans: [
      //   {
      //     name: "Quality",
      //     qty: "1",
      //     price: "50",
      //   },
      //   {
      //     name: "Stage",
      //     qty: "1",
      //     price: "50",
      //   },
      //   {
      //     name: "Initial",
      //     qty: "1",
      //     price: "50",
      //   },
      // ],
    };
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  onChangeDateOfBirth = (text) =>
    this.setState({ personalDOB: text.target.value });
  onChangePersonalFirstName = (text) =>
    this.setState({ personalFirstName: text.target.value });
  onChangePersonalLastName = (text) =>
    this.setState({ personalLastName: text.target.value });
  onChangePersonalCity = (text) =>
    this.setState({ personalCity: text.target.value });
  onChangePersonalProvince = (text) =>
    this.setState({ personalProvince: text.target.value });
  onChangePersonalAddress = (text) =>
    this.setState({ personalAddress: text.target.value });
  onChangePersonalPostalCode = (text) =>
    this.setState({ personalPostalCode: text.target.value });
  onChangePersonalPhoneNo = (text) =>
    this.setState({ personalPhoneNo: text.target.value });

  onChangeBusinessName = (text) =>
    this.setState({ businessName: text.target.value });
  onChangeBusinessNumberOfEmployer = (text) =>
    this.setState({ businessNumberOfEmployer: text.target.value });
  onChangeBusinessAddress = (text) =>
    this.setState({ businessAddress: text.target.value });
  onChangeBusinessCompanyTagLine = (text) =>
    this.setState({ businessCompanyTagLine: text.target.value });
  onChangeBusinessCity = (text) =>
    this.setState({ businessCity: text.target.value });
  onChangeBusinessProvince = (text) =>
    this.setState({ businessProvince: text.target.value });
  onChangeBusinessCompanyShortDescription = (text) =>
    this.setState({ businessCompanyShortDescription: text.target.value });
  onChangeBusinessTaxId = (text) =>
    this.setState({ businessTaxId: text.target.value });
  onChangeBusinessTelephone = (text) =>
    this.setState({ businessTelephone: text.target.value });
  onChangeBusinessCertificate = (text) =>
    this.setState({ businessCertificate: text.target.value });
  onChangeBusinessPostalCode = (text) =>
    this.setState({ businessPostalCode: text.target.value });
  onChangeHidden = (text) =>
    this.setState({ hidden: text.target.value });
  onChangePreferred = (text) =>
    this.setState({ preferred: text.target.value });

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    this.setState({ superAdminData }, () => {
      this.getAllSalon();
    });
  }

  getAllSalon = () => {
    const { superAdminData } = this.state;
    getResource(get_all_salon_Api, superAdminData?.access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            allSalon: res.data.data,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  handleDeleteSalon = (data) => {
    const { superAdminData } = this.state;

    deleteResource(
      `${delete_salon_Api}?companyId=${data._id}`,
      superAdminData?.access_token
    )
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(`Salon successfully delete.`, true);
          this.getAllSalon();
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  handleUpdatePersonalInfo = () => {
    const {
      superAdminData,
      personalFirstName,
      personalLastName,
      personalDOB,
      personalCity,
      personalProvince,
      personalAddress,
      personalPostalCode,
      personalPhoneNo,
      personalEmail,
      conpamyId,
    } = this.state;

    const payload = {
      companyId: conpamyId,
      firstName: personalFirstName,
      lastName: personalLastName,
      email: personalEmail,
      phoneNo: personalPhoneNo,
      address: personalAddress,
      postalCode: personalPostalCode,
      dob: personalDOB,
      city: personalCity,
      province: personalProvince,
      gcm_id: "string",
      platform: "ios",
    };
    // console.log(payload, "payloadpayload");

    updateResource(
      update_salon_personal_info_Api,
      payload,
      superAdminData?.access_token
    )
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            `Salon personal successfully updated.`,
            true
          );
          this.getAllSalon();
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  handleUpdateBusinessInfo = () => {
    const {
      superAdminData,
      conpamyId,
      businessName,
      businessNumberOfEmployer,
      businessAddress,
      businessCompanyTagLine,
      businessCity,
      businessProvince,
      businessCompanyShortDescription,
      businessPostalCode,
      businessTaxId,
      businessEmail,
      businessTelephone,
      businessCertificate,
      businessIsActive,
      hidden,
      preferred
    } = this.state;

    const payload = {
      businessCertificate: businessCertificate,
      address: businessAddress,
      postalCode: businessPostalCode,
      city: businessCity,
      province: businessProvince,
      businessTaxId: businessTaxId,
      isActive: businessIsActive,
      name: businessName,
      numberOfEmployer: businessNumberOfEmployer,
      businessTelephone: businessTelephone,
      companyTagLine: businessCompanyTagLine,
      companyShortDescription: businessCompanyShortDescription,
      companyId: conpamyId,
      hidden,
      preferred
    };
    console.log(payload, "payloadpayload");

    updateResource(
      update_salon_business_info_Api,
      payload,
      superAdminData?.access_token
    )
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            `Salon company successfully delete.`,
            true
          );
          this.getAllSalon();
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  renderFirstModal = () => {
    const {
      personalFirstName,
      personalLastName,
      personalDOB,
      personalCity,
      personalProvince,
      personalAddress,
      personalPostalCode,
      personalPhoneNo,
      personalEmail,
    } = this.state;

    return (
      <div class="container-fluid">
        <div class="row">
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">First Name:</label>
            <input
              type="text"
              class="form-control"
              value={personalFirstName}
              onChange={(text) => this.onChangePersonalFirstName(text)}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Last Name:</label>
            <input
              type="text"
              class="form-control"
              value={personalLastName}
              onChange={(text) => this.onChangePersonalLastName(text)}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Date of Birth:</label>
            <input
              type="date"
              class="form-control saloon-form-input"
              id="date-of-birth"
              min="1970-01-01"
              value={moment(personalDOB).format("YYYY-MM-DD")}
              max={moment(Date.now()).format("YYYY-MM-DD")}
              onChange={(text) => this.onChangeDateOfBirth(text)}
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">City:</label>
            <input
              type="text"
              class="form-control"
              value={personalCity}
              onChange={(text) => this.onChangePersonalCity(text)}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Province:</label>
            <input
              type="text"
              class="form-control"
              value={personalProvince}
              onChange={(text) => this.onChangePersonalProvince(text)}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Postal Code:</label>
            <input
              type="text"
              class="form-control"
              value={personalPostalCode}
              id="exampleInputEmail1"
              onChange={(text) => this.onChangePersonalPostalCode(text)}
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label for="exampleInputEmail1">Address:</label>
            <input
              type="text"
              class="form-control"
              value={personalAddress}
              onChange={(text) => this.onChangePersonalAddress(text)}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Telephone:</label>
            <input
              type="text"
              class="form-control"
              value={personalPhoneNo}
              onChange={(text) => this.onChangePersonalPhoneNo(text)}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Email:</label>
            <input
              disabled
              type="email"
              class="form-control"
              value={personalEmail}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="modal-footer" style={{ borderTop: "none" }}>
            <button
              type="button"
              data-dismiss="modal"
              onClick={() => {
                this.handleUpdatePersonalInfo();
              }}
              data-toggle="modal"
              data-target="#exampleModalTwo"
              className="btn btn-primary"
              style={{ backgroundColor: "#FF3600", borderColor: "#FF3600" }}
            >
              Update
            </button>
          </div>
          <div className="modal-footer" style={{ borderTop: "none" }}>
            <button
              type="button"
              data-dismiss="modal"
              data-toggle="modal"
              data-target="#exampleModalTwo"
              className="btn btn-primary"
              style={{ backgroundColor: "#FF3600", borderColor: "#FF3600" }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  };

  handleGetActivePlans() {
    const { salonId, superAdminData } = this.state;

    this.setState({ isloading: true });

    getResource(get_salon_active_plans(salonId), superAdminData?.access_token)
      .then((res) => this.setState({ plans: res.data.data, isloading: false }))
      .catch((err) => {
        ErrorHelper.handleErrors(
          "Something Went Wrong While Getting Plans",
          true
        );
        console.log("erererererer", err);
      });
  }

  updatePlans(id) {
    let { salonId, superAdminData } = this.state;

    let payload = {
      planId: id,
      companyId: salonId,
    };

    this.setState({ isloading: true });

    updateResource(
      update_salon_active_plans,
      payload,
      superAdminData?.access_token
    )
      .then((res) => {
        this.setState({ isloading: false });
        SuccessHelper.handleSuccess(res.data.msg, true);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors("Something Went Wrong", true);

        console.log("error", err);
      });
  }

  onChangeCheckbox(value) {
    console.log("sfsdfsdfsdf", { value });
  }

  renderSecondModal = () => {
    const {
      businessName,
      businessNumberOfEmployer,
      businessAddress,
      businessCompanyTagLine,
      businessCity,
      businessProvince,
      businessCompanyShortDescription,
      businessPostalCode,
      businessTaxId,
      businessEmail,
      businessTelephone,
      businessCertificate,
      hidden,
      preferred
    } = this.state;
    return (
      <div class="container-fluid">
        <div class="row">
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Company Name:</label>
            <input
              type="text"
              value={businessName}
              onChange={(text) => this.onChangeBusinessName(text)}
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">No of Employer:</label>
            <input
              type="text"
              value={businessNumberOfEmployer}
              onChange={(text) => this.onChangeBusinessNumberOfEmployer(text)}
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Address:</label>
            <input
              type="text"
              value={businessAddress}
              class="form-control"
              onChange={(text) => this.onChangeBusinessAddress(text)}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Company Tag Line:</label>
            <input
              type="text"
              onChange={(text) => this.onChangeBusinessCompanyTagLine(text)}
              class="form-control"
              value={businessCompanyTagLine}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">City:</label>
            <input
              type="text"
              class="form-control"
              onChange={(text) => this.onChangeBusinessCity(text)}
              value={businessCity}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Province:</label>
            <input
              type="text"
              class="form-control"
              onChange={(text) => this.onChangeBusinessProvince(text)}
              value={businessProvince}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label for="exampleInputEmail1">Company Short Description:</label>
            <input
              type="text"
              class="form-control"
              onChange={(text) =>
                this.onChangeBusinessCompanyShortDescription(text)
              }
              value={businessCompanyShortDescription}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Postal Code:</label>
            <input
              type="text"
              class="form-control"
              onChange={(text) => this.onChangeBusinessPostalCode(text)}
              value={businessPostalCode}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Business Tax ID or NEQ:</label>
            <input
              type="text"
              class="form-control"
              value={businessTaxId}
              onChange={(text) => this.onChangeBusinessTaxId(text)}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Email:</label>
            <input
              disabled
              type="email"
              class="form-control"
              value={businessEmail}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Telephone:</label>
            <input
              type="text"
              class="form-control"
              onChange={(text) => this.onChangeBusinessTelephone(text)}
              value={businessTelephone}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Company Certificate:</label>
            <input
              type="text"
              class="form-control"
              onChange={(text) => this.onChangeBusinessCertificate(text)}
              value={businessCertificate}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label for="exampleInputEmail1">Hidden:</label>
            <select
              id="function-role"
              class="form-control"
              value={hidden}
              onChange={(text) => this.onChangeHidden(text)}
            >
              <option
                value={true}
              >
                Yes
              </option>
              <option
                value={false}
              >
                No
              </option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label for="exampleInputEmail1">Preferred:</label>
            <select
              id="function-role"
              class="form-control"
              value={preferred}
              onChange={(text) => this.onChangePreferred(text)}
            >
              <option
                value={true}
              >
                Yes
              </option>
              <option
                value={false}
              >
                No
              </option>
            </select>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="modal-footer" style={{ borderTop: "none" }}>
            <button
              type="button"
              data-dismiss="modal"
              data-toggle="modal"
              onClick={() => {
                this.handleUpdateBusinessInfo();
              }}
              data-target="#exampleModalThird"
              className="btn btn-primary"
              style={{ backgroundColor: "#FF3600", borderColor: "#FF3600" }}
            >
              Update
            </button>
          </div>
          <div className="modal-footer" style={{ borderTop: "none" }}>
            <button
              type="button"
              data-dismiss="modal"
              data-toggle="modal"
              onClick={() => {
                this.handleGetActivePlans();
              }}
              data-target="#exampleModalThird"
              className="btn btn-primary"
              style={{ backgroundColor: "#FF3600", borderColor: "#FF3600" }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderThirdModal = () => {
    const { plans } = this.state;
    return (
      <div class="container">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th scope="col">
                {" "}
                <span className="tab-heading">Plans</span>
              </th>

              <th scope="col">
                <span className="tab-heading">Price</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {!!plans.length &&
              plans.map((val, ind) => {
                return (
                  <tr>
                    <td>
                      <span className="tab-text">{val?.plan}</span>
                    </td>

                    <td>
                      <span className="tab-text">${val?.price}</span>
                    </td>
                    <td>
                      <div class="custom-control custom-radio">
                        {!val.isActive ? (
                          <button
                            onClick={() => this.updatePlans(val._id)}
                            type="button"
                            data-dismiss="modal"
                            className="btn btn-primary"
                            style={
                              val.price
                                ? {
                                  backgroundColor: "#FF3600",
                                  borderColor: "#FF3600",
                                }
                                : {
                                  backgroundColor: "lightgray",
                                  borderColor: "lightgray",
                                }
                            }
                            disabled={val.price ? false : true}
                          >
                            {"Update"}
                          </button>
                        ) : (
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-primary"
                              style={{
                                backgroundColor: "lightgray",
                                borderColor: "lightgray",
                              }}
                              disabled={true}
                            >
                              {"Activate"}
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {/* {myPlanError ? (
            <div className="col-12 py-3">
              <p className="mb-0 text-danger">
                <strong>Error:</strong> {myPlanError}
              </p>
            </div>
          ) : null} */}
        <hr />

        <div className="modal-footer" style={{ borderTop: "none" }}>
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-primary"
            // onClick={() => this.updatePlans()}
            style={{ backgroundColor: "#FF3600", borderColor: "#FF3600" }}
          >
            Done
          </button>
        </div>
      </div>
    );
  };

  intializeImpersonation = (userId) => {
    const { superAdminData } = this.state;
    this.setState({ isloading: true })
    createResource(impersonation, { userId }, superAdminData?.access_token)
      .then(({data}) => {
        this.setState({ isloading: false })
        if(data.success) {
          const ewindow = window.open('https://easy1booking.com', '_blank')
          setTimeout(() => {
            ewindow.postMessage(JSON.stringify({ id: data.impersonation._id, type: 'salon' }), '*')
          }, 10000)
        }
      })
      .catch(() => {
        this.setState({ isloading: false })
      })
  }

  renderTableEditDeleteIcon = (data) => {
    return (
      <div>
        <span style={{marginRight: 8, backgroundColor: "green", padding: 4, borderRadius: 700}}>
          <FontAwesomeIcon
            onClick={() => this.intializeImpersonation(data.userId._id)}
            style={{width: 16, height: 16, cursor: "pointer", }}
            color='white'
            icon={["fas", "user-cog"]}
          />
        </span>
        <img
          onClick={() => {
            // console.log("SF????data", data);
            console.log(data)
            this.setState({
              salonId: data._id,
              conpamyId: data._id,
              personalFirstName: data.userId.firstName,
              personalLastName: data.userId.lastName,
              personalDOB: data.userId.dob,
              personalCity: data.userId.city,
              personalProvince: data.userId.province,
              personalAddress: data.userId.address,
              personalPostalCode: data.userId.postalCode,
              personalPhoneNo: data.userId.phoneNo,
              personalEmail: data.userId.email,
              businessName: data.name,
              businessNumberOfEmployer: data.numberOfEmployer,
              businessAddress: data.address,
              businessCompanyTagLine: data.companyTagLine,
              businessCity: data.city,
              businessProvince: data.province,
              businessCompanyShortDescription: data.companyShortDescription,
              businessPostalCode: data.postalCode,
              businessTaxId: data.businessTaxId,
              businessEmail: data.businessEmail,
              businessTelephone: data.businessTelephone,
              businessCertificate: data.businessCertificate,
              businessIsActive: data.isActive,
              hidden: 'hidden' in data ? data.hidden : false,
              preferred: 'preferred' in data ? data.preferred : false,
            });
          }}
          style={{ height: 18, width: 18, marginRight: 8, cursor: "pointer" }}
          src={Images.table_edit}
          data-toggle="modal"
          data-target="#exampleModal"
        />
        <img
          onClick={() => {
            Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#354e68",
              cancelButtonColor: "#ff3600",
              confirmButtonText: "Yes, delete it!",
            }).then((result) => {
              if (result.isConfirmed) {
                this.handleDeleteSalon(data);
              }
            });
          }}
          style={{ height: 18, width: 18, marginLeft: 8, cursor: "pointer" }}
          src={Images.table_delete}
        />
      </div>
    );
  };

  renderModel = () => {
    return <div renderModel={true}>sssssssssssss</div>;
  };

  render() {
    const { columns, data, planColumns, planData, allSalon:all, searchText } = this.state;
    const allSalon = all.filter(val => {
      if(!val.name) return false
      return val.name.toLocaleLowerCase().includes(searchText || "")
    })

    return (
      <Sidebar
        sidebar={<SidebarContent {...this.props} />}
        open={this.state.sidebarOpen}
        onSetOpen={(val) => this.onSetSidebarOpen(val)}
        sidebarClassName="custom-sidebar"
        styles={Sytles}
      >
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          {<Header {...this.props} onSetSidebarOpen={this.onSetSidebarOpen} />}
          <div className="container pt-5" style={{ paddingBottom: 70}}>
            <div className="row">
              <div className="col-12 mt-4 bg-white shadow rounded px-0">
                <TableContainerHeader
                  headingText="Salon Management"
                  // buttonText="+Add"
                  // dataTarget="#exampleModal"
                  // dataToggle="modal"
                  onSearchVal={searchText => this.setState({searchText})}
                  buttonOnClick={() => { }}
                />
                <CustomTable columns={columns} data={allSalon} />
                <Popup
                  exampleModal="exampleModal"
                  heading="Add Salon Management"
                  confirmBtn={() => { }}
                  renderModal={"modal"}
                  data={this.renderFirstModal()}
                />
                <Popup
                  exampleModal="exampleModalTwo"
                  heading="Edit Salon Management"
                  confirmBtn={() => { }}
                  renderModal={"modal"}
                  data={this.renderSecondModal()}
                />
                <Popup
                  exampleModal="exampleModalThird"
                  heading="Edit Salon Management"
                  confirmBtn={() => { }}
                  renderModal={"modal"}
                  data={this.renderThirdModal()}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(SalonManagement);
