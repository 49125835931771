// @flow
import Immutable from "seamless-immutable";
import * as types from "../actions/ActionTypes";

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: [],
});

export default (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case types.ANNOUNCEMENTS.GET_SEGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.ANNOUNCEMENTS.SET:
      return Immutable.merge(state, {
        isFetching: false,
        data: action.data,
      });
    case types.ANNOUNCEMENTS.DELETE: {
      const newState = { ...state, isFetching: false, data: [...state.data] };
      const { _id } = action.data;
      const index = state.data.findIndex((element) => element._id == _id);
      if (index != -1) {
        newState.data.splice(index, 1)
      }
      return newState;
    }
    case types.ANNOUNCEMENTS.DELETE_SEGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.ANNOUNCEMENTS.UPSERT:
      const newState = { ...state, isFetching: false, data: [...state.data] };
      const { _id } = action.data;
      const index = state.data.findIndex((element) => element._id == _id);
      if (index != -1) {
        newState.data[index] = action.data
      } else {
        newState.data.push(action.data)
      }
      return newState;
    case types.ANNOUNCEMENTS.UPSERT_SEGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.ANNOUNCEMENTS.REQUEST_FAILURE:
      return Immutable.merge(state, {
        isFetching: false,
      });
    case types.LOGOUT:
      return initialState;
    case types.CLEAR_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
