import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import SuperAdmin from "./SuperAdmin";

const customHistory = createBrowserHistory();
customHistory.listen(_ => {
  window.scrollTo(0, 0)
})

// Routes For Navigation
const MyRoutes = () => (
  <Router history={customHistory}>
    <SuperAdmin />
  </Router>
);

export default MyRoutes;
