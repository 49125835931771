import { connect } from "react-redux";
import React, { Component } from "react";
import Sidebar from "react-sidebar";

import { Images } from "../../theme";
import { DotsLoader, Header } from "../../components";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import "./styles.css";
import Sytles from "./styles";
import {
  SidebarContent,
  Footer,
  CustomTable,
  TableContainerHeader,
  Popup,
} from "../../components/SuperAdmin";
import {
  getResource,
  updateResource,
  deleteAppointment,
} from "../../config/simpleApiCalls";
import {
  get_all_bookings_Api,
  update_user_booking_Api,
  delete_user_booking_Api,
} from "../../config/WebServices";
import Swal from "sweetalert2";

class AppoinetmentManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true,
      ServiceDate: "",
      serviceTime: "",
      customerEmail: "",
      customerAmount: "",
      allAppointments: [],
      columns: [
        {
          Header: "Name",
          accessor: "userId.userName",
        },

        {
          Header: "Email",
          accessor: "email",
        },
        {
          Header: "Payment Method",
          accessor: "paymentMethod",
        },
        {
          Header: "Total Amount",
          accessor: "totalAmount",
        },
        {
          Header: "Status",
          Cell: (row) => this.renderTableEditDeleteIcon(row.row.original),
          id: "",
        },
      ],
      allAppointments: [],
    };
  }

  onChangeServiceDate = (text) => {
    this.setState({ ServiceDate: text.target.value });
  };
  onChangeServiceTime = (text) => {
    this.setState({ serviceTime: text.target.value });
  };
  onChangeCustomerEmail = (text) => {
    this.setState({ customerEmail: text.target.value });
  };
  onChangeCustomerAmount = (text) => {
    this.setState({ customerAmount: text.target.value });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    this.setState({ superAdminData }, () => {
      this.handleAllAppointments();
    });
  }

  handleAllAppointments = (el) => {
    const { superAdminData } = this.state;
    getResource(get_all_bookings_Api, superAdminData?.access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            allAppointments: res.data.data,
            // ServiceDate: "",
            // serviceTime: "",
            // customerEmail: "",
            // customerAmount: "",
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({
          isloading: false,
          // ServiceDate: "",
          // serviceTime: "",
          // customerEmail: "",
          // customerAmount: "",
        });
        console.log(error);
      });
  };

  renderUpdateBooking = (data) => {
    const {
      superAdminData,
      ServiceDate,
      serviceTime,
      customerEmail,
      customerAmount,
    } = this.state;

    let serviceData = data.services[0];
    let userData = data.userId;

    const payload = {
      bookingId: data._id,
      services: [
        {
          serviceId: serviceData.serviceId,
          employeeId: serviceData.employeeId,
          categoryId: serviceData.categoryId,
          date: ServiceDate,
          time: serviceTime,
        },
      ],
      postalCode: userData.postalCode,
      email: customerEmail,
      phoneNo: userData.phoneNo,
      status: data.status,
      totalAmount: customerAmount,
      paymentMethod: data.paymentMethod,
    };

    this.setState({ isloading: true });

    updateResource(
      update_user_booking_Api,
      payload,
      superAdminData?.access_token
    )
      .then((res) => {
        if (res.data.success) {
          Swal.fire({
            title: "Updated",
            text: "Successfully Updated",
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
          this.handleAllAppointments();
          this.setState({
            isloading: false,
            // getSuccessMsg: "Modal",
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
        ErrorHelper("Something Went Wrong", true);
      });
  };

  renderDeleteBooking = (getByOneAppointment) => {
    const { superAdminData } = this.state;

    const payload = {
      bookingId: getByOneAppointment._id,
    };

    deleteAppointment(payload, superAdminData?.access_token)
      .then((res) => {
        console.log("renderDeleteBooking -> res", res);
        if (res.data.success) {
          this.handleAllAppointments();
          this.setState({
            isloading: false,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        ErrorHelper("Something Went Wrong", true);

        console.log("renderDeleteBooking -> error", error);
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  renderTableEditDeleteIcon = (getByOneAppointment) => {
    return (
      <div>
        <img
          onClick={() => {
            this.setState({
              getByOneAppointment,
              ServiceDate: getByOneAppointment?.services[0]?.date,
              serviceTime: getByOneAppointment?.services[0]?.time,
              customerEmail: getByOneAppointment?.userId?.email,
              customerAmount: getByOneAppointment?.totalAmount,
            });
          }}
          data-toggle="modal"
          data-target="#exampleModalEdit"
          style={{ height: 18, width: 18, marginRight: 8, cursor: "pointer" }}
          src={Images.table_edit}
        />
        <img
          // onClick={() => { this.renderDeleteBooking(getByOneAppointment) }}
          onClick={() => {
            Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, delete it!",
            }).then((result) => {
              this.renderDeleteBooking(getByOneAppointment);
            });
          }}
          style={{ height: 18, width: 18, marginLeft: 8, cursor: "pointer" }}
          src={Images.table_delete}
        />
      </div>
    );
  };

  renderData = () => {
    return (
      <div class="container-fluid">
        <div class="row">
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Name:</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Customer ID:</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Email:</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Phone:</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Salon Name:</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Category:</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        {/* Company Short Description: */}

        <div class="row">
          <div class="form-group col-md-12">
            <label for="exampleInputEmail1">Service:</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Employee:</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Payment Pethod:</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Total Ammount:</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div className="modal-footer" style={{ borderTop: "none" }}>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            style={{
              backgroundColor: "#fff",
              borderColor: "#FF3600",
              color: "#000",
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-primary"
            style={{ backgroundColor: "#FF3600", borderColor: "#FF3600" }}
          >
            Confirm
          </button>
        </div>
      </div>
    );
  };

  renderEditData = () => {
    const { getByOneAppointment } = this.state;

    return (
      <div class="container-fluid">
        <div class="row">
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Name:</label>
            <input
              disabled
              type="text"
              value={getByOneAppointment?.userId?.userName}
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Customer ID:</label>
            <input
              type="text"
              disabled
              value={getByOneAppointment?.userId?._id}
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Email:</label>
            <input
              value={this.state.customerEmail}
              type="email"
              class="form-control"
              onChange={(value) => this.onChangeCustomerEmail(value)}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Phone:</label>
            <input
              type="text"
              disabled
              value={getByOneAppointment?.userId?.phoneNo}
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Postal Code:</label>
            <input
              disabled
              type="text"
              value={getByOneAppointment?.userId?.postalCode}
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Total Ammount:</label>
            <input
              type="text"
              class="form-control"
              onChange={(value) => this.onChangeCustomerAmount(value)}
              value={this.state.customerAmount}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Date:</label>
            <input
              type="text"
              class="form-control"
              value={this.state.ServiceDate}
              onChange={(value) => this.onChangeServiceDate(value)}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Time:</label>
            <input
              type="email"
              value={this.state.serviceTime}
              onChange={(value) => this.onChangeServiceTime(value)}
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Payment Pethod:</label>
            <input
              type="text"
              disabled
              class="form-control"
              value={getByOneAppointment?.paymentMethod}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div className="modal-footer" style={{ borderTop: "none" }}>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            style={{
              backgroundColor: "#fff",
              borderColor: "#FF3600",
              color: "#000",
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            data-dismiss="modal"
            onClick={() => this.renderUpdateBooking(getByOneAppointment)}
            style={{ backgroundColor: "#FF3600", borderColor: "#FF3600" }}
          >
            Confirm
          </button>
        </div>
      </div>
    );
  };

  render() {
    const { columns, allAppointments:all, searchText } = this.state;
    const allAppointments = all.filter(val => {
      if(!val?.userId?.userName) return false
      return val.userId.userName.toLocaleLowerCase().includes(searchText || "")
    })

    return (
      <Sidebar
        sidebar={<SidebarContent {...this.props} />}
        open={this.state.sidebarOpen}
        onSetOpen={(val) => this.onSetSidebarOpen(val)}
        sidebarClassName="custom-sidebar"
        styles={Sytles}
      >
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          {<Header {...this.props} onSetSidebarOpen={this.onSetSidebarOpen} />}
          <div className="container py-5 mb-4 mb-md-5">
            <div className="row">
              <div className="col-12 mt-4 bg-white shadow rounded px-0">
                <TableContainerHeader
                  headingText="Appointmtnt Management"
                  dataTarget="#exampleModal"
                  dataToggle="modal"
                  onSearchVal={searchText => this.setState({searchText})}
                  buttonOnClick={() => {}}
                />
                <CustomTable columns={columns} data={allAppointments} />
                <Popup
                  exampleModal="exampleModal"
                  heading="Add Appointment Management"
                  data={this.renderData()}
                  renderModal={"modal"}
                />
                <Popup
                  exampleModal="exampleModalEdit"
                  heading="Edit Appointment"
                  data={this.renderEditData()}
                  renderModal={"modal"}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(AppoinetmentManagement);
