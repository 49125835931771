const hero_bg = require("../assets/Images/hero_bg.png");
const easy1_logo_800x300 = require("../assets/Images/easy1_logo_800x300.png");
const selling_card_img_1 = require("../assets/Images/selling_card_img_1.jpg");
const selling_card_img_2 = require("../assets/Images/selling_card_img_2.jpg");
const selling_card_img_3 = require("../assets/Images/selling_card_img_3.jpg");
const selling_card_img_4 = require("../assets/Images/selling_card_img_4.jpg");
const selling_template_img_4 = require("../assets/Images/selling_template_img_4.jpg");
const admin_panel_bg = require("../assets/Images/admin_panel_bg.png");
const admin_panel_laptop_1 = require("../assets/Images/admin_panel_laptop_1.png");
const admin_panel_laptop_2 = require("../assets/Images/admin_panel_laptop_2.png");
const iphone_and_ipad = require("../assets/Images/iphone_and_ipad.png");
const newsletter_bg = require("../assets/Images/newsletter_bg.png");
const services_hero_bg = require("../assets/Images/services_hero_bg.png");

const order_sort = require("../assets/Images/Icons/order_sort.png");
const show_list_icon = require("../assets/Images/Icons/show_list_icon.png");
const close_icon = require("../assets/Images/Icons/close.png");
const show_grid_icon = require("../assets/Images/Icons/show_grid_icon.png");
const rating_star = require("../assets/Images/Icons/rating_star.png");
const rating_star_blue = require("../assets/Images/Icons/rating_star_blue.png");
const pagination_forward = require("../assets/Images/Icons/pagination_forward.png");
const pagination_back = require("../assets/Images/Icons/pagination_back.png");
const setting_icon = require("../assets/Images/Icons/setting_icon.png");
const download_ios = require("../assets/Images/Icons/download_ios.png");
const download_andriod = require("../assets/Images/Icons/download_andriod.png");
const email_icon = require("../assets/Images/Icons/email_icon.png");
const facebook_icon = require("../assets/Images/Icons/facebook_icon.png");
const google_plus_icon = require("../assets/Images/Icons/google_plus_icon.png");
const instagram_icon = require("../assets/Images/Icons/instagram_icon.png");
const youtube_icon = require("../assets/Images/Icons/youtube_icon.png");
const google_signin = require("../assets/Images/Icons/google_signin.png");
const facebook_signin = require("../assets/Images/Icons/facebook_signin.png");
const mail_signin = require("../assets/Images/Icons/mail_signin.png");
const step_done_tick = require("../assets/Images/Icons/step_done_tick.png");
const step_done_tick_orange = require("../assets/Images/Icons/step_done_tick_orange.png");
const saloon_form_submit = require("../assets/Images/Icons/saloon_form_submit.png");
const saloon_footer_facebook = require("../assets/Images/Icons/saloon_footer_facebook.png");
const saloon_footer_instagram = require("../assets/Images/Icons/saloon_footer_instagram.png");
const saloon_footer_twitter = require("../assets/Images/Icons/saloon_footer_twitter.png");
const saloon_footer_youtube = require("../assets/Images/Icons/saloon_footer_youtube.png");
const saloon_form_visa = require("../assets/Images/Icons/saloon_form_visa.png");
const saloon_form_american_express = require("../assets/Images/Icons/saloon_form_american_express.png");
const saloon_form_credit_card = require("../assets/Images/Icons/saloon_form_credit_card.png");
const saloon_form_master_card = require("../assets/Images/Icons/saloon_form_master_card.png");
const saloon_form_paypal = require("../assets/Images/Icons/saloon_form_paypal.png");
const _preview = require("../assets/Images/6609538_preview.png");
const dots = require("../assets/Images/dots.png");
const Layer5 = require("../assets/Images/Layer 5.png");
const Layer6 = require("../assets/Images/Layer 6.png");
const cross = require("../assets/Images/cross.png");
const tick = require("../assets/Images/tick.png");
const pic1 = require("../assets/Images/pic1.png");
const viewAll = require("../assets/Images/viewAll.png");
const clock = require("../assets/Images/clock.png");
const calendar = require("../assets/Images/calendar.png");
const star_empty = require("../assets/Images/Icons/star-empty.png");
const star_yellow = require("../assets/Images/Icons/star-yellow.png");
const bookingClock = require("../assets/Images/bookingclock.png");
const price_tag = require("../assets/Images/price_tag.png");
const tag_grey = require("../assets/Images/Icons/tag_grey.png");
const saloon_plus = require("../assets/Images/plus.png");
const saloon_dashboard_wallet = require("../assets/Images/Wallethub.png");
const saloon_dashboard_radishlab = require("../assets/Images/RadishLab.png");
const saloon_dashboard_roshi = require("../assets/Images/Roshi.png");
const saloon_headingDropdown = require("../assets/Images/Icons/dropdown.png");
const white_profile = require("../assets/Images/Icons/profile_white.png");
const down_white = require("../assets/Images/Icons/headingdropdown.png");
const saloon_search = require("../assets/Images/admin_search.png");
const saloon_horizontaldots = require("../assets/Images/saloon_horizontaldots.png");
const saloon_addEmployee_plus = require("../assets/Images/Icons/plus.png");
const saloon_employeeRegister = require("../assets/Images/Icons/employee_register.png");
const saloon_addEmployee_tooltiparrow = require("../assets/Images/Icons/tooltiparrow.png");
const service_bulb_with_bg = require("../assets/Images/Icons/service_bulb_with_bg.png");
const service_bulb = require("../assets/Images/Icons/service_bulb.png");
const service_benefits_icon = require("../assets/Images/Icons/service_benefits_icon.png");
const template_selling_card_img_1 = require("../assets/Images/template_selling_card_img_1.png");
const template_selling_card_img_2 = require("../assets/Images/template_selling_card_img_2.png");

// For Saloon Dashboard
const saloon_profile_icon = require("../assets/Images/Icons/saloon_profile_icon.png");
const saloon_navbar_menu = require("../assets/Images/Icons/saloon_navbar_menu.png");
const saloon_navbar_profile_dropdown = require("../assets/Images/Icons/saloon_navbar_profile_dropdown.png");
const saloon_chat_send = require("../assets/Images/Icons/saloon_chat_send.png");
const saloon_chat_clip = require("../assets/Images/Icons/saloon_chat_clip.png");
const saloon_edit_profile = require("../assets/Images/saloon_edit_profile.png");
const saloon_upload_media_icon = require("../assets/Images/Icons/saloon_upload_media_icon.png");
const saloon_link_social_facebook = require("../assets/Images/Icons/saloon_link_social_facebook.png");
const saloon_link_social_instagram = require("../assets/Images/Icons/saloon_link_social_instagram.png");
const saloon_link_social_linkedin = require("../assets/Images/Icons/saloon_link_social_linkedin.png");
const saloon_link_social_snapchat = require("../assets/Images/Icons/saloon_link_social_snapchat.png");
const saloon_link_social_twitter = require("../assets/Images/Icons/saloon_link_social_twitter.png");
const saloon_link_social_youtube = require("../assets/Images/Icons/saloon_link_social_youtube.png");
const saloon_working_hour_edit = require("../assets/Images/Icons/saloon_working_hour_edit.png");
const saloon_working_hour_delete = require("../assets/Images/Icons/saloon_working_hour_delete.png");
const saloon_category_pic = require("../assets/Images/saloon_category_pic.png");
const saloon_edit_employee_check_icon = require("../assets/Images/Icons/saloon_edit_employee_check_icon.png");
const appointment_details_download_icon = require("../assets/Images/Icons/appointment_details_download_icon.png");
const saloon_available_services_date = require("../assets/Images/Icons/date.png");
const saloon_available_services_time = require("../assets/Images/Icons/time.png");
const saloon_add_image = require("../assets/Images/saloon_add_image.png");
const saloon_add = require("../assets/Images/Icons/saloon_add.png");
const saloon_check = require("../assets/Images/Icons/saloon_check.png");

const img = require("../assets/Images/img.jpg");

// For Saloon Dashboard Drawer Icons
const saloon_drawer_dashboard = require("../assets/Images/Icons/saloon_drawer_dashboard.png");
const saloon_drawer_profile = require("../assets/Images/Icons/saloon_drawer_profile.png");
const saloon_drawer_chat_box = require("../assets/Images/Icons/saloon_drawer_chat_box.png");
const saloon_drawer_employees = require("../assets/Images/Icons/saloon_drawer_employees.png");
const saloon_drawer_category = require("../assets/Images/Icons/saloon_drawer_category.png");
const saloon_drawer_working_hours = require("../assets/Images/Icons/saloon_drawer_working_hours.png");
const saloon_drawer_upload_media = require("../assets/Images/Icons/saloon_drawer_upload_media.png");
const saloon_drawer_link_social = require("../assets/Images/Icons/saloon_drawer_link_social.png");
const saloon_drawer_dropdown = require("../assets/Images/Icons/saloon_drawer_dropdown.png");
const saloon_drawer_all_appointment = require("../assets/Images/Icons/saloon_drawer_all_appointment.png");
const saloon_drawer_choose_a_template = require("../assets/Images/Icons/saloon_drawer_choose_a_template.png");
const saloon_drawer_services = require("../assets/Images/Icons/saloon_drawer_services.png");
const saloon_drawer_log_out = require("../assets/Images/Icons/saloon_drawer_log_out.png");
const saloon_drawer_rating = require("../assets/Images/Icons/saloon_drawer_rating.png");
const saloon_drawer_reports = require("../assets/Images/Icons/saloon_drawer_reports.png");
const saloon_drawer_booking = require("../assets/Images/Icons/saloon_drawer_booking.png");
const salon_management = require("../assets/Images/Icons/salon-management.png");
const salon_category = require("../assets/Images/Icons/salon-category.png");
const appointment_management = require("../assets/Images/Icons/appointment-management.png");
const Salon_Rating_reviews = require("../assets/Images/Icons/Salon Rating & reviews.png");
const Top_Salons_List = require("../assets/Images/Icons/Top Salons List.png");
const Employee_Drawer = require("../assets/Images/Icons/Employee.png");
const Customer_Drawer = require("../assets/Images/Icons/customer.png");
const Faqs_Drawer = require("../assets/Images/Icons/faqs.png");
const terms_conditon = require("../assets/Images/Icons/terms&conditon.png");
const pricing = require("../assets/Images/Icons/pricing.png");
const link = require("../assets/Images/Icons/link.png");
const setting = require("../assets/Images/Icons/setting.png");

//For Company Setup
const saloon_companysetup_edit = require("../assets/Images/Icons/saloon_comapnysetup_edit.png");
const mark_tick = require("../assets/Images/Icons/mark.png");
const cancel_cross = require("../assets/Images/Icons/cancel.png");
const saloon_companysetup_delete = require("../assets/Images/Icons/saloon_companysetup_delete.png");

const user_profile_image = require("../assets/Images/user_profile_image.png");

//For Template
const saloon_template_barber = require("../assets/Images/slider.jpg");
const saloon_template_nav_img = require("../assets/Images/navlogo.png");
const saloon_template_nav_phone = require("../assets/Images/Icons/phone.png");
const saloon_template_nav_mail = require("../assets/Images/Icons/email.png");
const saloon_template_nav_time = require("../assets/Images/Icons/navtime.png");
const saloon_template_service_hair = require("../assets/Images/Icons/hair.png");
const saloon_template_service_haircolor = require("../assets/Images/Icons/haircolor.png");
const saloon_template_service_facemask = require("../assets/Images/Icons/facemask.png");
const saloon_template_service_shave = require("../assets/Images/Icons/shave.png");
const saloon_template_service_scisssor = require("../assets/Images/Icons/scissor.png");
const saloon_template_service_mustache = require("../assets/Images/Icons/mustache.png");
const saloon_template_about = require("../assets/Images/templateabout.png");
const saloon_template_price = require("../assets/Images/price.jpg");
const saloon_template_employee1 = require("../assets/Images/employee1.jpg");
const saloon_template_employee2 = require("../assets/Images/employee2.jpg");
const saloon_template_employee3 = require("../assets/Images/employee3.jpg");
const saloon_template_employee4 = require("../assets/Images/employee4.jpg");
const saloon_template_employee5 = require("../assets/Images/employee5.jpg");
const saloon_template_facebook = require("../assets/Images/facebook.png");
const saloon_template_youtube = require("../assets/Images/youtube.png");
const saloon_template_twitter = require("../assets/Images/twitter.png");
const saloon_template_insta = require("../assets/Images/insta.png");
const saloon_template_sponser1 = require("../assets/Images/sponsor-1.png");
const saloon_template_sponser2 = require("../assets/Images/sponsor-2.png");
const saloon_template_sponser3 = require("../assets/Images/sponsor-3.png");
const saloon_template_sponser4 = require("../assets/Images/sponsor-4.png");
const saloon_template_sponser5 = require("../assets/Images/sponsor-5.png");
const saloon_template_footer_phone = require("../assets/Images/phone.png");
const saloon_template_footer_email = require("../assets/Images/email.png");
const saloon_template_footer_location = require("../assets/Images/location.png");
const saloon_template_footer_nav = require("../assets/Images/footer_nav.png");
const saloon_template_dropdown = require("../assets/Images/down.png");

//Saloon Template 2
const saloon_template2_nav = require("../assets/Images/logo.png");
const saloon_template2_slider = require("../assets/Images/slide-1.jpg");
const saloon_template2_aboutus = require("../assets/Images/aboutus.png");
const saloon_template2_aboutlogo = require("../assets/Images/about-logo.png");
const saloon_template2_headline = require("../assets/Images/heading-line.png");
const saloon_template2_service_razor = require("../assets/Images/razor.png");
const saloon_template2_service_razor_1 = require("../assets/Images/razor-1.png");
const saloon_template2_service_haircut = require("../assets/Images/hair-cut.png");
const saloon_template2_service_cream = require("../assets/Images/cream.png");
const saloon_template2_service_electric_razor = require("../assets/Images/electric-razor.png");
const saloon_template2_service_brush = require("../assets/Images/brush.png");
const saloon_template2_service_straightener = require("../assets/Images/straightener.png");
const saloon_template2_service_hair_dryer = require("../assets/Images/hairdryer.png");
const saloon_template2_img1 = require("../assets/Images/img1.jpg");
const saloon_template2_img2 = require("../assets/Images/img2.jpg");
const saloon_template_border = require("../assets/Images/border.png");
const saloon_template2_emp3 = require("../assets/Images/emp_3.png");
const saloon_template2_star = require("../assets/Images/fill.png");
const saloon_template2_mustache = require("../assets/Images/mustache-border.png");

//Saloon Template 3
const saloon_template3_nav = require('../assets/Images/template3_nav.png')
const saloon_template3_main = require('../assets/Images/template3_main.jpg')
const saloon_template3_badge = require('../assets/Images/Home1-Badge.png')
const saloon_template3_about = require('../assets/Images/template3_about.png')
const saloon_template3_seprator = require('../assets/Images/dark_seperator.png')
const saloon_template3_service1 = require('../assets/Images/template3_service1.png')
const saloon_template3_service2 = require('../assets/Images/template3_service2.png')
const saloon_template3_service3 = require('../assets/Images/template3_service3.png')
const saloon_template3_service5 = require('../assets/Images/template3_service5.png')
const saloon_template3_service6 = require('../assets/Images/template3_service6.png')
const saloon_template3_service7 = require('../assets/Images/template3_service7.png')
const saloon_template3_service8 = require('../assets/Images/template3_service8.png')
const saloon_template3_service9 = require('../assets/Images/template3_service9.png')
const saloon_template3_menu = require('../assets/Images/MenuBackrground.jpg')
const saloon_template3_menu_seprator = require('../assets/Images/white_seprator.png')
const saloon_template3_employee1 = require('../assets/Images/member1.jpg')
const saloon_template3_employee2 = require('../assets/Images/member2.jpg')
const saloon_template3_employee3 = require('../assets/Images/member3.jpg')
const saloon_template3_employee4 = require('../assets/Images/member4.jpg')
const saloon_template3_portfolio1 = require('../assets/Images/portfolio1.jpg')
const saloon_template3_portfolio2 = require('../assets/Images/portfolio2.jpg')
const saloon_template3_portfolio3 = require('../assets/Images/portfolio3.jpg')
const saloon_template3_portfolio4 = require('../assets/Images/portfolio4.jpg')
const saloon_template3_portfolio5 = require('../assets/Images/portfolio5.jpg')
const saloon_template3_portfolio6 = require('../assets/Images/portfolio6.jpg')
const saloon_template3_portfolio7 = require('../assets/Images/portfolio7.jpg')
const saloon_template3_portfolio8 = require('../assets/Images/portfolio8.jpg')
const saloon_template3_portfolio9 = require('../assets/Images/portfolio9.jpg')
const saloon_template3_portfolio10 = require('../assets/Images/portfolio10.jpg')
const saloon_template3_video = require('../assets/Images/Video.mp4')
const profile_eidt_icon = require('../assets/Images/custprofile.png')
const my_appointment = require("../assets/Images/Icons/myappointment.png");
const wallet_icon = require("../assets/Images/Icons/wallet.png");

//Customer
const saloon_customer_name = require('../assets/Images/custname.png')
const saloon_customer_phone = require('../assets/Images/custphone.png')
const saloon_customer_email = require('../assets/Images/custemail.png')
const saloon_customer_postal = require('../assets/Images/custpostal.png')
const saloon_customer_date = require('../assets/Images/custdob.png')

// Dashboard Content Images
const card_discount = require('../assets/Images/discount.png')
const card_menu = require('../assets/Images/menu.png')
const card_appointment = require('../assets/Images/appointment.png')
const bar_shop_one = require('../assets/Images/barshop-1.png')
const bar_shop_two = require('../assets/Images/barshop-2.png')
const bar_shop_three = require('../assets/Images/barshop-3.png')

// Admin Salon
const table_edit = require('../assets/Images/Icons/table_edit.png');
const table_delete = require('../assets/Images/Icons/table_delete.png');


export default {
  card_discount,
  card_menu,
  card_appointment,
  bar_shop_one,
  bar_shop_two,
  bar_shop_three,
  mark_tick,
  cancel_cross,
  profile_eidt_icon,
  hero_bg,
  wallet_icon,
  easy1_logo_800x300,
  selling_card_img_1,
  selling_card_img_2,
  selling_card_img_3,
  selling_card_img_4,
  selling_template_img_4,
  admin_panel_bg,
  admin_panel_laptop_1,
  admin_panel_laptop_2,
  iphone_and_ipad,
  newsletter_bg,
  services_hero_bg,
  template_selling_card_img_1,
  template_selling_card_img_2,
  salon_management,
  appointment_management,
  Salon_Rating_reviews,
  Top_Salons_List,
  Employee_Drawer,
  Faqs_Drawer,
  terms_conditon,
  pricing,
  setting,
  link,
  Customer_Drawer,
  salon_category,
  my_appointment,
  close_icon,
  order_sort,
  show_list_icon,
  show_grid_icon,
  rating_star,
  rating_star_blue,
  pagination_forward,
  pagination_back,
  setting_icon,
  download_ios,
  download_andriod,
  email_icon,
  facebook_icon,
  google_plus_icon,
  instagram_icon,
  youtube_icon,
  google_signin,
  facebook_signin,
  mail_signin,
  step_done_tick,
  step_done_tick_orange,
  saloon_form_submit,
  saloon_footer_facebook,
  saloon_footer_instagram,
  saloon_footer_twitter,
  saloon_footer_youtube,
  saloon_form_visa,
  saloon_form_american_express,
  saloon_form_credit_card,
  saloon_form_master_card,
  saloon_form_paypal,
  _preview,
  dots,
  Layer5,
  Layer6,
  cross,
  tick,
  pic1,
  viewAll,
  clock,
  calendar,
  bookingClock,
  price_tag,
  tag_grey,
  star_empty,
  star_yellow,
  saloon_plus,
  saloon_dashboard_wallet,
  saloon_dashboard_radishlab,
  saloon_dashboard_roshi,
  saloon_headingDropdown,
  white_profile,
  down_white,
  saloon_search,
  saloon_horizontaldots,
  saloon_addEmployee_plus,
  saloon_employeeRegister,
  saloon_addEmployee_tooltiparrow,
  service_bulb_with_bg,
  service_bulb,
  service_benefits_icon,

  // For Saloon Dashboard
  saloon_profile_icon,
  saloon_navbar_menu,
  saloon_navbar_profile_dropdown,
  saloon_chat_send,
  saloon_chat_clip,
  saloon_edit_profile,
  saloon_upload_media_icon,
  saloon_link_social_facebook,
  saloon_link_social_instagram,
  saloon_link_social_linkedin,
  saloon_link_social_snapchat,
  saloon_link_social_twitter,
  saloon_link_social_youtube,
  saloon_working_hour_edit,
  saloon_working_hour_delete,
  saloon_category_pic,
  saloon_edit_employee_check_icon,
  appointment_details_download_icon,
  saloon_available_services_date,
  saloon_available_services_time,
  img,
  saloon_add_image,
  saloon_add,
  saloon_check,

  // For Saloon Dashboard Drawer Icons
  saloon_drawer_dashboard,
  saloon_drawer_profile,
  saloon_drawer_chat_box,
  saloon_drawer_employees,
  saloon_drawer_category,
  saloon_drawer_working_hours,
  saloon_drawer_upload_media,
  saloon_drawer_link_social,
  saloon_drawer_dropdown,
  saloon_drawer_all_appointment,
  saloon_drawer_choose_a_template,
  saloon_drawer_services,
  saloon_drawer_log_out,
  saloon_drawer_rating,
  saloon_drawer_reports,
  saloon_drawer_booking,

  // For Company Setup
  saloon_companysetup_edit,
  saloon_companysetup_delete,

  //For Template
  saloon_template_barber,
  saloon_template_nav_img,
  saloon_template_nav_phone,
  saloon_template_nav_mail,
  saloon_template_nav_time,
  saloon_template_service_hair,
  saloon_template_service_haircolor,
  saloon_template_service_facemask,
  saloon_template_service_shave,
  saloon_template_service_scisssor,
  saloon_template_service_mustache,
  saloon_template_about,
  saloon_template_price,
  saloon_template_employee1,
  saloon_template_employee2,
  saloon_template_employee3,
  saloon_template_employee4,
  saloon_template_employee5,
  saloon_template_facebook,
  saloon_template_youtube,
  saloon_template_twitter,
  saloon_template_insta,
  saloon_template_sponser1,
  saloon_template_sponser2,
  saloon_template_sponser3,
  saloon_template_sponser4,
  saloon_template_sponser5,
  saloon_template_footer_phone,
  saloon_template_footer_email,
  saloon_template_footer_location,
  saloon_template_footer_nav,
  saloon_template_dropdown,

  user_profile_image,

  //For Saloon Template 3
  saloon_template3_nav,
  saloon_template3_main,
  saloon_template3_badge,
  saloon_template3_about,
  saloon_template3_seprator,
  saloon_template3_service1,
  saloon_template3_service2,
  saloon_template3_service3,
  saloon_template3_service5,
  saloon_template3_service6,
  saloon_template3_service7,
  saloon_template3_service8,
  saloon_template3_service9,
  saloon_template3_menu,
  saloon_template3_menu_seprator,
  saloon_template3_employee1,
  saloon_template3_employee2,
  saloon_template3_employee3,
  saloon_template3_employee4,
  saloon_template3_portfolio1,
  saloon_template3_portfolio2,
  saloon_template3_portfolio3,
  saloon_template3_portfolio4,
  saloon_template3_portfolio5,
  saloon_template3_portfolio6,
  saloon_template3_portfolio7,
  saloon_template3_portfolio8,
  saloon_template3_portfolio9,
  saloon_template3_portfolio10,
  saloon_template3_video,
  //Template 2
  saloon_template2_nav,
  saloon_template2_slider,
  saloon_template2_aboutus,
  saloon_template2_aboutlogo,
  saloon_template2_headline,
  saloon_template2_service_razor,
  saloon_template2_service_razor_1,
  saloon_template2_service_haircut,
  saloon_template2_service_cream,
  saloon_template2_service_electric_razor,
  saloon_template2_service_brush,
  saloon_template2_service_straightener,
  saloon_template2_service_hair_dryer,
  saloon_template2_img1,
  saloon_template2_img2,
  saloon_template_border,
  saloon_template2_emp3,
  saloon_template2_star,
  saloon_template2_mustache,

  //Customer
  saloon_customer_name,
  saloon_customer_phone,
  saloon_customer_date,
  saloon_customer_email,
  saloon_customer_postal,

  // Admin Salon
  table_edit,
  table_delete,
};
