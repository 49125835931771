// @flow

import { ANNOUNCEMENTS } from "./ActionTypes";

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ANNOUNCEMENTS.GET_SEGA
  };
}

export function set(data) {
  return {
    data,
    type: ANNOUNCEMENTS.SET
  };
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ANNOUNCEMENTS.DELETE_SEGA
  };
}

export function remove(data) {
  return {
    data,
    type: ANNOUNCEMENTS.DELETE
  };
}

export function upsertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: ANNOUNCEMENTS.UPSERT_SEGA
  };
}

export function upsert(data) {
  return {
    data,
    type: ANNOUNCEMENTS.UPSERT
  };
}

export function requestFailure() {
  return {
    type: ANNOUNCEMENTS.REQUEST_FAILURE
  };
}
