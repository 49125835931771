import React from 'react';
import './styles.css';

const TableContainerHeader = ({ headingText, buttonText, buttonOnClick, dataTarget, dataToggle, onSearchVal }) => {
    return (
        <div className="col-12" >
            <div className="row">
                <div className="col-md-6">
                    <h2 className="h4 py-3" style={{color: '#424242'}}>{headingText}</h2>
                </div>
                {buttonText ? (
                    <div className="col-md-6 d-flex justify-content-end align-items-center">
                        <span className="tableContainerHeaderbtn" onClick={buttonOnClick} data-toggle={dataToggle}
                            data-target={dataTarget}>{buttonText}</span>
                    </div>
                ) : null}
                {!!onSearchVal && 
                <div style={{ width:450}} className="form-group search_active_input ">
                  <div className="pt-3 justify-content-center align-items-center">
                    <input
                      style={{ backgroundColor: "#ffffff", borderColor: "#EBEBEB" }}
                      type="text"
                      id="addEmployee_search_bar"
                      className="form-control form-control-sm pl-5"
                      placeholder="Search here"
                      onChange={e => onSearchVal(e.target.value.toLocaleLowerCase())}
                    />
                  </div>
                </div>
                }
            </div>
        </div>
    )
}

export default TableContainerHeader;