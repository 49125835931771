import { connect } from "react-redux";
import React, { Component } from "react";
import Sidebar from "react-sidebar";

import { Images } from "../../theme";
import { SidebarContent, CustomTable } from "../../components/SuperAdmin";
import {
  top_rated_salon,
  get_all_bookings_Api,
  get_stats
} from "../../config/WebServices";
import { getResource } from "../../config/simpleApiCalls";
import ReactStars from "react-rating-stars-component";

import { DotsLoader, Header } from "../../components";
// import { ErrorHelper } from "../../helpers";

import "./styles.css";
import Sytles from "./sytles";
// import MyResponsiveLine from "./Charts/index";
import MyResponsiveBar from "./Bars/index";
import Footer from "../../components/SuperAdmin/Footer/index";
import StarRating from "./../../components/Star/star";
import StarRatingComponent from "react-star-rating-component";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      usersList: [],
      blockUsersList: [],
      allAppointments: [],
      unblockUsersList: [],
      totalEmploye: [],
      totalCategories: [],
      topRatedSalon: [],
      totalAppointment: "",
      statusArr: [
        {
          status: 1,
          color: "red",
        },
        {
          status: 2,
          color: "blue",
        },
        {
          status: 3,
          color: "green",
        },
        {
          status: 4,
          color: "yellow",
        },
      ],
    };
  }

  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    this.setState({ superAdminData }, () => {
      this.handleGetTopRatedSalon();
      this.handleAllAppointments();
      this.getStats()
    });
  }
  
  getStats = (el) => {
    getResource(get_stats)
      .then((res) => {
        if (res.data.success) {
          const { numberOfBookings, numberOfActiveUser, numberOfSalons, totalDollarAmount} = res.data.data
          this.setState({
            isloading: false,
            numberOfBookings,
            numberOfActiveUser,
            numberOfSalons,
            totalDollarAmount,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  handleGetTopRatedSalon = (el) => {
    const { superAdminData } = this.state;
    getResource(top_rated_salon, superAdminData?.access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            topRatedSalon: res.data.data,
            showModal: el,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  handleAllAppointments = (el) => {
    const { superAdminData } = this.state;
    getResource(get_all_bookings_Api, superAdminData?.access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            allAppointments: res.data.data,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  _renderBaseCard = (value, title, cardClass) => {
    return (
      <>
        <div className="col-12 col-md-6 col-lg-3 offset-1 offset-md-0 offset-lg-0 p-3">
          <div className={`py-3 px-2 shadow-sm mb-5 ${cardClass} rounded cards`}>
            <div className="row justify-content-between">
              <div className="col-8 col-md-8 col-lg-8 col-sm-12">
                <h3 style={{ color: "#FFFFFF", fontSize: "17px" }}>
                  {title}
                </h3>
              </div>
            </div>
            <div className="row justify-content-between my-2 mx-0">
              <img
                src={Images.card_menu}
                className="p-1 bg-white col-3 col-md-3 col-lg-3"
                style={{ borderRadius: 10, objectFit: 'contain'}}
              />
              <div
                className="col-9 col-md-9 col-lg-9 col-sm-12 card-counting"
                style={{
                  color: "white",
                  fontSize: 24,
                  fontWeight: "600",
                  alignSelf: "center",
                }}
              >
                {value}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  _renderCategoriesCard = () => {
    const { numberOfSalons = 0 } = this.state;
    return this._renderBaseCard(numberOfSalons, 'Total Salon', 'color-card-one')
  };

  _renderSupportCard = () => {
    const { numberOfBookings = 0 } = this.state;
    return this._renderBaseCard(numberOfBookings, 'Appointments', 'color-card-two')
  };

  _renderActiveUserCard = () => {
    const { numberOfActiveUser = 0 } = this.state;
    return this._renderBaseCard(numberOfActiveUser, 'Active Users', 'color-card-three')
  };

  _renderTotaSalesCard = () => {
    const { totalDollarAmount = 0 } = this.state;
    return this._renderBaseCard(`$${totalDollarAmount}`, 'Total Sales', 'color-card-four')
  };


  _renderCard = () => {
    let { topRatedSalon, columns } = this.state;

    let filterTopSalon = topRatedSalon.slice(0, 4);

    return (
      <>
        <div className="row">
          {this._renderTotaSalesCard()}
          {this._renderCategoriesCard()}
          {this._renderSupportCard()}
          {this._renderActiveUserCard()}
        </div>
        <div className="row">
          <div className="col-10 col-md-6 col-lg-6 offset-1 offset-md-0 offset-lg-0 pl-3 p-0 py-0 px-3">
            <div
              className="py-3 shadow-sm mb-5 bg-white rounded cards"
              style={{ height: 400 }}
            >
              <div>
                <h6 className="pb-3 mt-2">
                  <strong className="pl-4" style={{ color: "#424242" }}>
                    MANAGING CLIENTS / EMPLOYEES / OWNERS
                  </strong>
                </h6>
                <hr className="separator" />
                <MyResponsiveBar />
                <div className="row mt-3 ml-4">
                  <div className="col-12 col-lg-7 col-md-12 ">
                    <div className="dropdown_button" style={{ height: 24 }}>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ paddingTop: 2 }}
                      >
                        <span>
                          <img
                            src={Images.saloon_available_services_date}
                            style={{
                              width: 10,
                              height: 10,
                              color: "white",
                              marginRight: 7,
                            }}
                          ></img>
                          Confirm Date
                        </span>
                        <img
                          src={Images.saloon_headingDropdown}
                          className=" ml-5"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row ml-4 mt-4 mb-1">
                               <div className="col-12 col-lg-12 col-md-12 ">
                            <span className="add_conform_btn px-5 px-md-4 " style={{ paddingTop: 7, paddingBottom: 7 }}>
                                <img src={Images.saloon_available_services_date} style={{ width: 17, height: 17, color: "white", marginRight: 7 }}></img>
                                Conform Date
                            </span>
                                            </div>
                               </div> */}
                {/* <div className="row justify-content-center">
                                <div className=" d-flex">
                                    <div>
                                        <p className="p-1 small">New Clients</p>
                                    </div>
                                    <div>
                                        <p className="p-1 small">Existing Clients</p>
                                    </div>
                                </div>
                                </div> */}
              </div>
            </div>
          </div>
          {/* ///// */}

          <div className="col-10 col-md-6 col-lg-6 offset-1 offset-md-0 offset-lg-0 pl-3 p-0 px-3">
            <div
              className="py-3 shadow-sm mb-5 bg-white rounded cards"
              style={{ height: 400 }}
            >
              <div className="d-md-flex row justify-content-between mb-3">
                <h6 className="text-align-center mt-2 px-3">
                  <strong className="pl-4" style={{ color: "#424242" }}>
                    TOP RATED SALOON
                  </strong>
                </h6>
                <div
                  onClick={() =>
                    this.props.history.push("super-salon-top-list")
                  }
                  className="viewAll_btn px-3 py-1 align-items-center mt-2 mt-md-0 mt-lg-0 ml-2 ml-md-0 ml-lg-0 mr-4"
                >
                  View All
                  {/* <img
                    src={Images.viewAll}
                    className="viewAll_icon mb-1 ml-1 ml-md-1 ml-lg-1"
                  ></img> */}
                </div>
              </div>
              {filterTopSalon.map((v, i) => {
                console.log(v, "vvvvvvvvvvvvvvvv");
                return (
                  <div>
                    <hr className="separator" />
                    <div
                      className="d-flex row ml-4 justify-content-between pr-3"
                      // style={{ backgroundColor: "red" }}
                    >
                      <img
                        src={v?.salon?.companyLogo}
                        style={{
                          backgroundColor: "gray",
                          height: 80,
                          width: 120,
                        }}
                      ></img>
                      <div className="d-flex row ml-3">
                        <div className="">
                          <div className="available_employee_heading">
                            {/* {v.name} */}
                            {v?.salon?.name}
                          </div>
                          <ReactStars
                            count={5}
                            value={v?.salon?.averageRating}
                            size={17}
                            edit={false}
                            activeColor="#ffd700"
                          />
                          <span className="">
                            {v.salon.NumberOfReviews} Reviews
                          </span>
                        </div>
                      </div>
                      <div className="align-self-center ml-5">
                        <div className="">
                          <button className="preview-outline-btn btn-sm">
                            Preview
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="row ">{this.renderUserTable()}</div>
      </>
    );
  };

  renderUserTable = () => {
    return (
      <div className="col-12 col-md-12 col-lg-12 ">
        <div className="py-3 shadow-sm mb-5 bg-white rounded cards">
          <div className="d-md-flex row justify-content-between mb-3">
            <div className="pl-5 pt-2">
              <strong className="appointment-management">
                Appointment Management
              </strong>
            </div>
            <div
              className="viewAll_btn px-3 py-1 align-items-center mt-2 mt-md-0 mt-lg-0 ml-2 ml-md-0 ml-lg-0 mr-5"
              onClick={() =>
                this.props.history.push("super-appointment-management")
              }
            >
              View All
              {/* <img
                src={Images.viewAll}
                className="viewAll_icon mb-1 ml-1 ml-md-1 ml-lg-1"
              ></img> */}
            </div>
          </div>
          <hr className="separator" />

          <div className="col-12 p-3">
            <div className="table-responsive">
              <table
                border="0"
                className="table table-borderless appointment-details-table"
              >
                <thead style={{ borderTop: "hidden", borderBottom: "hidden" }}>
                  <tr>
                    <th scope="col">
                      <span className="appointment-details-th-text ml-5">
                        Name
                      </span>
                    </th>
                    <th scope="col">
                      <span className="appointment-details-th-text">email</span>
                    </th>
                    <th scope="col">
                      <span className="appointment-details-th-text">Price</span>
                    </th>
                    <th scope="col">
                      <span className="appointment-details-th-text">
                        phoneNo
                      </span>
                    </th>
                    <th scope="col">
                      <span className="appointment-details-th-text">
                        Payment Method
                      </span>
                    </th>
                    <th scope="col">
                      <span className="appointment-details-th-text">
                        Status
                      </span>
                    </th>
                  </tr>
                </thead>
                {this.renderTableBody()}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderTableBody = () => {
    const { allAppointments, statusArr } = this.state;
    return (
      <tbody>
        {allAppointments.map((val, ind) => {
          if (ind > 3) return;
          // if (val.status === 1) {
          //   <div>Panding</div>
          // } else if (val.status === 2) {
          //   <div>Now Serving</div>
          // } else if (val.status === 3) {
          //   <div>Cancel</div>
          // } else if (val.status === 4) {
          //   <div>Done</div>
          // }

          return (
            <tr border="0">
              <td>
                <div className="d-flex align-items-center ">
                  <div
                    style={{
                      height: 30,
                      width: 30,
                      backgroundColor: "#5558EE",
                      borderRadius: 200,
                    }}
                  ></div>
                  <div>
                    <span
                      style={{ marginLeft: "10px" }}
                      className="appointment-details-employee-name"
                    >
                      {val.userId?.userName}
                    </span>
                  </div>
                </div>
              </td>
              <td className="Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name">
                    {val.email}
                  </span>
                </div>
              </td>
              <td className="Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name">
                    {val.totalAmount}
                  </span>
                </div>
              </td>
              <td className="Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name">
                    {val.phoneNo}
                  </span>
                </div>
              </td>
              <td className="Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name">
                    {val.paymentMethod}
                  </span>
                </div>
              </td>
              <td className="Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span
                    className="appointment-details-employee-name"
                    style={{
                      color: "#5558EE",
                      font: "normal normal bold 16px arial",
                    }}
                  >
                    {val.status ? (
                      <div>
                        {val.status === 1 && (
                          <div style={{ color: "#FF8B00" }}>Current</div>
                        )}
                        {val.status === 2 && (
                          <div style={{ color: "#5558EE" }}>Now Serving</div>
                        )}
                        {val.status === 3 && (
                          <div style={{ color: "#FFD530" }}>Cancel</div>
                        )}
                        {val.status === 4 && (
                          <div style={{ color: "#00D48F" }}>Done</div>
                        )}
                      </div>
                    ) : null}
                  </span>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  render() {
    const { usersList, blockUsersList, unblockUsersList } = this.state;
    return (
      <Sidebar
        sidebar={<SidebarContent {...this.props} />}
        open={this.state.sidebarOpen}
        onSetOpen={(val) => this.onSetSidebarOpen(val)}
        sidebarClassName="custom-sidebar"
        styles={Sytles}
      >
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          <Header {...this.props} onSetSidebarOpen={this.onSetSidebarOpen} />
          <div className="container pt-5">
            <div className="row">
              <div className=" col-12 col-lg-10 col-md-10  pt-3">
                <h1 className="Dashboard_heading">Dashboard For Salon</h1>
              </div>
              <div className="col-12 col-lg-2 col-md-2  mt-4">
                <div className="dropdown_button ">
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="mt-1">Edit your options</p>
                    <img
                      src={Images.saloon_headingDropdown}
                      className="mb-2 ml-1"
                    />
                  </div>
                </div>
              </div>
            </div>
            {this._renderCard()}
          </div>
          <Footer />
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(Dashboard);
