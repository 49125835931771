import { connect } from 'react-redux';
import React, { Component } from 'react';

import { request as user_logout } from "../../redux/actions/LogOut";
import { clearLogout as clear_logout } from "../../redux/actions/LogOut";
import { Images } from '../../theme'
import { Sidebar } from '../../components'
import { SuccessHelper } from "../../helpers";
import { Link } from 'react-router-dom'

import './styles.css'

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            routeName: '',
            showSidebar: false,
            showDropdownMenu: false
        }
    }

    componentDidMount() {
        const userData = JSON.parse(sessionStorage.getItem('user'));
        if (userData && userData.access_token) {
            this.setState({ user: userData })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.logout) {
            if (
                !nextProps.logout.failure &&
                !nextProps.logout.isFetching &&
                !nextProps.logout.errorMessage &&
                nextProps.logout.data &&
                nextProps.logout.data.success
            ) {
                nextProps.clear_logout()
                sessionStorage.setItem("user", "{}")
                SuccessHelper.handleSuccess('You have successfully logged out.', true);
                this.setState({ user: null })
            } else if (
                nextProps.logout.failure &&
                !nextProps.logout.isFetching &&
                nextProps.logout.errorMessage
            ) {
                this.setState({ isloading: false });
            }
        }
    }

    handleNavigation = (route) => {
        this.props.history.push(route)
    }

    handleLogout = () => {
        const { user } = this.state;
        this.props.user_logout({ access_token: user.access_token });
    }

    renderLargeNavbar = () => {
        const { user } = this.state;
        return (
            <div id="large-navbar" className="d-flex align-items-center justify-content-between">
                <img alt="easy1 logo 800x300" src={Images.easy1_logo_800x300} className="logo cursor-pointer" onClick={() => this.handleNavigation('/')} />
                <span className="d-flex align-items-center">
                    <span className="navbar-link" onClick={() => this.handleNavigation('/our-services')}>Our Services</span>
                    <span className="navbar-link" onClick={() => this.handleNavigation('/saloon-login')}>Own a Salon</span>
                    <span className="navbar-link" onClick={() => this.handleNavigation('/employee-login')}>Staff</span>
                    {!user && <button
                        type="button"
                        className="btn btn-outline-light btn-sm"
                        onClick={() => this.handleNavigation('/login')}
                    >Login</button>}
                    {!user && <button
                        type="button"
                        className="btn btn-light btn-sm"
                        onClick={() => this.handleNavigation('/register')}
                    >Sign Up</button>}
                    {user && user.access_token && <button
                        type="button"
                        className="btn btn-light btn-sm"
                        onClick={() => this.handleLogout()}
                    >Log Out</button>}
                </span>
            </div>
        )
    }

    renderSmallNavbar = () => {
        const { showSidebar } = this.state;
        return (
            <div id="small-navbar">
                <div className="py-2 d-flex align-items-center justify-content-between">
                    <img alt="easy1 logo 800x300" src={Images.easy1_logo_800x300} className="logo cursor-pointer" onClick={() => this.handleNavigation('/')} />
                    <span className="d-flex align-items-center" onClick={() => this.setState({ showSidebar: showSidebar ? false : true })}>
                        <img src={Images.saloon_navbar_menu} style={{ width: 35, height: 35, cursor: 'pointer' }} />
                    </span>
                </div>
                <div className="px-2">
                    <Sidebar {...this.props} showSidebar={showSidebar} />
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="container-fluid fixed-top shadow px-3 py-2 mb-5" style={{
                backgroundColor: this.props.isBackgroundColor ? '#fff' : 'transparent',
                transition: "background-color 300ms linear"
            }}>
                {this.renderLargeNavbar()}
                {this.renderSmallNavbar()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ logout: state.logout });

const action = { user_logout, clear_logout };

export default connect(mapStateToProps, action)(Navbar);