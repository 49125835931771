import { connect } from "react-redux";
import React, { Component } from "react";

import { DotsLoader, Navbar } from "../../components";
import { SuccessHelper, ErrorHelper } from "../../helpers";
import { Images } from "../../theme";
import { request as super_user_login } from "../../redux/actions/Login";

import "./styles.css";

class SaloonLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      handle: "",
      username: "",
      password: "",
      isloading: false,
      access_token: "",
      messaging_token: "",
      platform_name: "",
      formErrors: {
        emailError: "",
        usernameError: "",
        passwordError: "",
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.login) {
      console.log('nextProps.login: ', nextProps.login);
      if (
        !nextProps.login.failure &&
        !nextProps.login.isFetching &&
        !nextProps.login.errorMessage &&
        nextProps.login.data &&
        nextProps.login.data.data &&
        nextProps.login.data.data.access_token &&
        nextProps.login.data.success
      ) {
        this.handleNavigation("/super-dashboard");
        if (nextProps.login.data.data.role == 1) {
          sessionStorage.setItem(
            "superAdmin",
            JSON.stringify(nextProps.login.data.data)
          );
          this.setState(
            {
              isloading: false,
              access_token: nextProps.login.data.data.access_token,
            });
        } else {
          ErrorHelper.handleErrors("Access Denied", true);
          this.setState({ isloading: false, handle: "", password: "" });
        }
      } else if (
        nextProps.login.failure &&
        !nextProps.login.isFetching &&
        nextProps.login.errorMessage &&
        !nextProps.login.data.success
      ) {
        this.setState({ isloading: false });
      }
    }
  }

  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    if (superAdminData) {
      this.handleNavigation("/super-dashboard");
    }
  }

  onChangeHandle = (text) => this.setState({ handle: text.target.value });
  onChangeUserName = (text) => this.setState({ username: text.target.value });
  onChangePassword = (text) => this.setState({ password: text.target.value });

  checkValidation() {
    const { username, handle, password } = this.state;
    const nameRegex = /^[A-Za-z0-9_]{4,}$/;
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!handle) {
      this.setState({
        formErrors: {
          emailError: "Email or Phone Number is Required",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            emailError: "",
          },
        });
      }, 8000);
    } else if (!handle.match(emailRegex)) {
      this.setState({
        formErrors: {
          emailError: "Invalid Email or Phone Number",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            emailError: "",
          },
        });
      }, 8000);
    } else if (!password) {
      this.setState({
        formErrors: {
          passwordError: "Password is required!",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            passwordError: "",
          },
        });
      }, 6000);
    } else {
      this.handleLogin();
    }
  }

  handleLogin = () => {
    this.setState({ isloading: true });
    const { handle, password, messaging_token, platform_name } = this.state;
    const payload = {
      handle,
      password,
    };
    this.props.super_user_login(payload);
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderEmail = () => {
    const { formErrors } = this.state;
    return (
      <div className="form-label-group mb-4 ">
        <label for="inputusername">Email or Phone Number</label>
        <input
          type="text"
          id="inputusername"
          className="form-control mt-1"
          placeholder="Enter your Email or Phone Number..."
          onChange={(text) => this.onChangeHandle(text)}
          value={this.state.handle}
        />
        {formErrors.emailError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.emailError}
          </small>
        ) : null}
      </div>
    );
  };

  renderUserName = () => {
    const { formErrors } = this.state;
    return (
      <div className="form-label-group mb-4 ">
        <label for="inputusername">Username</label>
        <input
          type="text"
          id="inputusername"
          className="form-control mt-1"
          placeholder="Enter your username..."
          onChange={(text) => this.onChangeUserName(text)}
          value={this.state.username}
        />
        {formErrors.nameError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.nameError}
          </small>
        ) : null}
      </div>
    );
  };

  renderPassword = () => {
    const { formErrors } = this.state;
    return (
      <div className="form-label-group">
        <label for="inputPassword ">Password</label>
        <input
          type="password"
          id="inputPassword"
          className="form-control mt-1"
          placeholder="Enter your password..."
          onChange={(text) => this.onChangePassword(text)}
          value={this.state.password}
        />
        {formErrors.passwordError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.passwordError}
          </small>
        ) : null}
      </div>
    );
  };

  renderLoginBtn = () => {
    return (
      <button
        className="btn btn-lg"
        type="submit"
        // onClick={() => this.handleNavigation("/super-dashboard")}
        onClick={() => this.checkValidation()}
      >
        Login Now
      </button>
    );
  };

  renderForgetBtn = () => {
    return (
      <div className="form-signin  px-8">
        <span
          style={{
            color: "#ff3600",
            fontWeight: "700",
            cursor: "pointer",
            marginBottom: 20,
          }}
          onClick={() => this.handleNavigation("/saloon-forgot-password")}
        >
          Forget Password
        </span>
      </div>
    );
  };

  renderLoginForm = () => {
    return (
      <form action="javascript:void(0)" className="form-signin  px-4">
        {this.renderEmail()}
        {this.renderPassword()}
        {this.renderForgetBtn()}
        {this.renderLoginBtn()}
      </form>
    );
  };

  renderLoginCard = () => {
    return (
      <div className="card card-signin my-5 border-0">
        <div className="card-body px-0">
          <h5 className="card-title text-center">Sign in as Admin</h5>
          <div className="row">
            <hr className="my-4 mx-3 " style={{ width: "100%" }} />
          </div>
          {this.renderLoginForm()}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="container-fluid px-0">
        {this.renderLoader()}
        <div className="container" style={{ backgroundColor: "#F8FDFF" }}>
          <div className="row mt-5">
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto mt-5">
              {this.renderLoginCard()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ login: state.login });

const action = { super_user_login };

export default connect(mapStateToProps, action)(SaloonLogin);
