import { connect } from "react-redux";
import React, { Component, useImperativeHandle } from "react";
import Sidebar from "react-sidebar";

import { Images } from "../../theme";
import {
  SidebarContent,
  Footer,
  CustomTable,
  TableContainerHeader,
  Popup,
} from "../../components/SuperAdmin";
import { DotsLoader, Header } from "../../components";
import Announcement from "../../components/Announcements";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import NumberFormat from "react-number-format";
import {
  getRequest,
  removeRequest,
  upsertRequest
} from "../../redux/actions/Announcements";
import moment from "moment";
import "./styles.css";
import Sytles from "./styles";

import Swal from "sweetalert2";

class SalonManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleValidationFirst: true,
      toggleValidationSecond: true,
      toggleValidationThird: true,
      allReservations: [],
      selectedAnnoucement: undefined,
      columns: [
        {
          Header: "Title",
          accessor: "title",
        },
        {
          Header: "Status",
          Cell: (row) => this.renderTableEditDeleteIcon(row.row.original),
          accessor: "",
        },
      ],
      isloading: true,
      data: [],
      unformattedDate: undefined
    };
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  renderLoader = () => {
    const { isLoading } = this.props;
    return <DotsLoader isloading={isLoading} />;
  };

  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    this.props.getRequest();
    this.setState({ superAdminData });
  }


  delete = (_id) => {
    const { superAdminData, allReservations } = this.state;
  }
  
  renderTableEditDeleteIcon = (announcememt) => {
    return (
      <div>
        <img
          onClick={() => {
            let data = JSON.parse(JSON.stringify(announcememt))
            this.setState({
              selectedAnnoucement: data
            });
          }}
          data-toggle="modal"
          data-target="#announcementEdit"
          style={{ height: 18, width: 18, marginRight: 8, cursor: "pointer" }}
          src={Images.table_edit}
        />
        <img
          // onClick={() => { this.renderDeleteBooking(getByOneAppointment) }}
          onClick={() => {
            Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, delete it!",
            }).then(({value}) => {
              if(value) {
                this.props.removeRequest({ ids: announcememt._id}, {
                  success: (data) => {
                    SuccessHelper.handleSuccess(`Announcement Deleted Successfully`, true)
                  },
                  failure: (data) => {
                    ErrorHelper(data?.msg ? data.msg : "Something Went Wrong", true);
                  }
                });
              }
            });
          }}
          style={{ height: 18, width: 18, marginLeft: 8, cursor: "pointer" }}
          src={Images.table_delete}
        />
      </div>
    );
  };

  renderEditAnnouncement = () => {
    const { selectedAnnoucement } = this.state;
    return (
      <>
        <Announcement
          data={selectedAnnoucement}
          onChange={(value, key, child) => {
            if(child) {
              if(!selectedAnnoucement[key]) {              
                selectedAnnoucement[key] = {}
              }        
              selectedAnnoucement[key][child] = value
            } else {
              selectedAnnoucement[key] = value
            }
            this.setState({ selectedAnnoucement })
          }}
        />
        <div className="row mx-0 justify-content-end">
          <button
            onClick={() => {
              this.props.upsertRequest(selectedAnnoucement, {
                success: ({data}) => {
                  SuccessHelper.handleSuccess(`Announcement ${selectedAnnoucement._id ? 'Updated' : 'Added'} Successfully`, true)
                  this.setState({ selectedAnnoucement: data })
                },
                failure: (data) => {
                  ErrorHelper.handleErrors(data?.msg ? data?.msg : "Something Went Wrong", true);
                }
              })
            }}
            type="button"
            className="btn btn-primary"
            style={{ backgroundColor: "#FF3600", borderColor: "#FF3600" }}
          >
            Save
          </button>
        </div>
      </>
    )
  }

  render() {
    const { columns, time, allReservations:all, selectedAnnoucement } = this.state;
    const { announcements = [] } = this.props;

    return (
      <Sidebar
        sidebar={<SidebarContent {...this.props} />}
        open={this.state.sidebarOpen}
        onSetOpen={(val) => this.onSetSidebarOpen(val)}
        sidebarClassName="custom-sidebar"
        styles={Sytles}
      >
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          {<Header {...this.props} onSetSidebarOpen={this.onSetSidebarOpen} />}
          <div className="container pt-5" style={{ paddingBottom: 70}}>
            <div className="row">
              <div className="col-12 mt-4 bg-white shadow rounded px-0">
                <TableContainerHeader
                  headingText="Salon Management"
                  buttonText="+Add"
                  dataTarget="#announcementEdit"
                  dataToggle="modal"
                  // dataToggle="modal"
                  buttonOnClick={() => { this.setState({ selectedAnnoucement: {} }) }}
                />
                <Popup
                  exampleModal="announcementEdit"
                  heading={`Annoucement${selectedAnnoucement?.title ? ' - ' + selectedAnnoucement?.title : ''}`}
                  data={this.renderEditAnnouncement()}
                  backEvent={() => { this.setState({ selectedAnnoucement: undefined }) }}
                  renderModal={"modal"}
                />

                <CustomTable columns={columns} data={announcements} />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => ({
  announcements: state.announcements.data,
  isLoading: state.announcements.isFetchting
});

const action = {
  getRequest,
  removeRequest,
  upsertRequest
};

export default connect(mapStateToProps, action)(SalonManagement);
