import { connect } from "react-redux";
import React, { Component } from "react";
import Sidebar from "react-sidebar";

import { DotsLoader, Header } from "../../components";
import {
  SidebarContent,
  Footer,
  CustomTable,
  TableContainerHeader,
  Popup,
} from "../../components/SuperAdmin";
import {
  getResource,
  createResource,
  deleteResource,
  updateResource,
} from "../../config/simpleApiCalls";
import {
  get_categories_Api,
  create_category_Api,
  delete_categories_Api,
  edit_category,
} from "../../config/WebServices";
import { Images } from "../../theme";
import { SuccessHelper, ErrorHelper } from "../../helpers";
import Swal from "sweetalert2";

import Sytles from "./styles";
import "./styles.css";

class SalonCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true,
      showPopUp: false,
      isUpdate: false,
      superAdminData: null,
      categoryName: "",
      categroyImageUrl: null,
      categroyImageFile: null,
      categroyImageName: "",
      formErrorText: "",
      columns: [
        {
          Header: "Image",
          Cell: (row) => this.renderCategoryImage(row.row.original.image),
          id: "image",
        },
        {
          Header: "Category Name",
          accessor: "name",
        },
        {
          Header: "Status",
          Cell: (row) => this.renderTableEditDeleteIcon(row.row.original),
          id: "_id",
        },
      ],
      data: [],
      showModal: false,
    };
  }

  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    this.setState({ superAdminData }, () => {
      this.handleGetCategories();
    });
  }

  handleGetCategories = (el) => {
    const { superAdminData } = this.state;
    getResource(get_categories_Api, superAdminData?.access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            data: res.data.data,
            showModal: el,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  handleCreateCategory = () => {
    const { superAdminData, categoryName, categroyImageFile } = this.state;
    let payloadFormdata = new FormData();
    payloadFormdata.append("image", categroyImageFile);
    payloadFormdata.append("name", categoryName);
    createResource(
      create_category_Api,
      payloadFormdata,
      superAdminData?.access_token
    )
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(`Category successfully added.`, true);
          this.setState({ showModal: true });
          this.handleGetCategories(true);
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  // handleBtn(){
  //   return
  // }

  handleDeleteCategory = (_id) => {
    const { superAdminData } = this.state;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#354e68",
      cancelButtonColor: "#ff3600",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ isloading: true });
        deleteResource(delete_categories_Api(_id), superAdminData?.access_token)
          .then((res) => {
            if (res.data.success) {
              SuccessHelper.handleSuccess(
                `Category successfully delete.`,
                true
              );
              this.handleGetCategories();
            } else {
              this.setState({ isloading: false });
            }
          })
          .catch((error) => {
            this.setState({ isloading: false });
            console.log(error);
          });
      }
    });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  handleOnChangeCategoryName = (event) =>
    this.setState({ categoryName: event.target.value });

  handleSelectedCategroyImage = (event) => {
    const file = URL.createObjectURL(event.target.files[0]);
    this.setState({
      categroyImageUrl: file,
      categroyImageFile: event.target.files[0],
      categroyImageName: event.target.files[0].name,
    });
  };

  checkValidation = () => {
    const { categoryName, categroyImageUrl } = this.state;
    // this.setState({ showModal: true });
    if (!categroyImageUrl) {
      this.setState({
        formErrorText: "Image is required.",
      });
      setTimeout(() => {
        this.setState({
          formErrorText: "",
        });
      }, 3000);
    } else if (!categoryName) {
      this.setState({
        formErrorText: "Category name is required.",
      });
      setTimeout(() => {
        this.setState({
          formErrorText: "",
        });
      }, 3000);
    } else {
      this.setState({ isloading: true }, () => this.handleCreateCategory());
    }
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  handleCategoryUpdate() {
    const {
      categoryId,
      superAdminData,
      categroyImageFile,
      categoryName,
      categroyImageUrl,
    } = this.state;

    this.setState({ isloading: true });

    let payloadFormdata = new FormData();
    payloadFormdata.append("image", categroyImageFile);
    payloadFormdata.append("name", categoryName);
    payloadFormdata.append("categoryId", categoryId);

    console.log("payloadFormdata: ", categroyImageUrl);
    updateResource(edit_category, payloadFormdata, superAdminData?.access_token)
      .then((res) => {
        console.log("res:updateResourceupdateResource ", res);
        if (res.data.success) {
          this.setState({
            isloading: false,
          });
          SuccessHelper.handleSuccess(`Category successfully updated.`, true);
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        console.log("error: updateResourceupdateResource", error);
        this.setState({ isloading: false });

        ErrorHelper.handleErrors("Something Went Wrong", true);
        console.log(error);
      });
  }

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderData = () => {
    const { categoryName, categroyImageName, formErrorText } = this.state;
    return (
      <div className="col-12 py-3">
        <div className="form-row">
          <div className="col-12 mb-3">
            <label>Upload an Image:</label>
            <div className="custom-file">
              <input
                type="file"
                onChange={this.handleSelectedCategroyImage}
                className="custom-file-input"
                id="customFile"
              />
              <label className="custom-file-label" for="customFile">
                {categroyImageName ? categroyImageName : "Choose image"}
              </label>
            </div>
          </div>
          <div className="col-12">
            <label for="categoryName">Enter Category Name:</label>
            <input
              type="text"
              className="form-control"
              id="categoryName"
              onChange={(event) => this.handleOnChangeCategoryName(event)}
              value={categoryName}
            />
          </div>
          {formErrorText ? (
            <p className="form-text text-danger mb-0 mt-2 ml-1">
              <strong>Error : </strong>
              {formErrorText}
            </p>
          ) : null}
          {/* <div className="modal-footer" style={{ borderTop: "none" }}>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              style={{
                backgroundColor: "#fff",
                borderColor: "#FF3600",
                color: "#000",
              }}
            >
              {"cancel"}
            </button>
            <button
              // onClick={confirmBtn}
              type="button"
              className="btn btn-primary"
              style={{ backgroundColor: "#FF3600", borderColor: "#FF3600" }}
            >
              {"confirm"}
            </button>
          </div> */}
        </div>
      </div>
    );
  };

  renderCategoryImage = (image) => {
    return (
      <div>
        <img
          style={{
            backgroundColor: "#FF3600",
            height: 65,
            width: 65,
            padding: 8,
            borderRadius: 40,
          }}
          src={image}
        />
      </div>
    );
  };

  renderTableEditDeleteIcon = (info) => {
    const { showModal } = this.state;

    return (
      <div>
        <img
          onClick={() => {
            this.setState({
              categoryName: info.name,
              categroyImageUrl: info.image,
              isUpdate: true,
              categoryId: info._id,
            });
          }}
          style={{ height: 18, width: 18, marginRight: 8, cursor: "pointer" }}
          src={Images.table_edit}
          data-toggle="modal"
          data-target="#exampleModal"
        />
        <img
          onClick={() => this.handleDeleteCategory(info._id)}
          style={{ height: 18, width: 18, marginLeft: 8, cursor: "pointer" }}
          src={Images.table_delete}
        />
      </div>
    );
  };

  render() {
    const { columns, data:all, showModal, isUpdate, searchText } = this.state;
    const data = all.filter(val => {
      if(!val?.name) return false
      return val.name.toLocaleLowerCase().includes(searchText || '')
    })

    return (
      <Sidebar
        sidebar={<SidebarContent {...this.props} />}
        open={this.state.sidebarOpen}
        onSetOpen={(val) => this.onSetSidebarOpen(val)}
        sidebarClassName="custom-sidebar"
        styles={Sytles}
      >
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          style={{ flex: 1, backgroundColor: "#F7F7F7" }}
        >
          {<Header {...this.props} onSetSidebarOpen={this.onSetSidebarOpen} />}
          <div className="container py-5">
            <div className="row">
              <div className="col-12 mt-4 mb-5 bg-white shadow rounded px-0">
                <TableContainerHeader
                  headingText="Salon Category"
                  buttonText="+Add"
                  dataTarget="#exampleModal"
                  dataToggle="modal"
                  onSearchVal={searchText => this.setState({searchText})}
                  buttonOnClick={() => {}}
                />
                <CustomTable columns={columns} data={data} />
                <Popup
                  exampleModal="exampleModal"
                  heading="Add Salon Category"
                  confirm={!isUpdate ? "Confirm" : "Update"}
                  confirmBtn={() =>
                    !isUpdate
                      ? this.checkValidation()
                      : this.handleCategoryUpdate()
                  }
                  cancel="Cancel"
                  data={this.renderData()}
                  renderModal={showModal}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(SalonCategory);
