import React from "react";
import { useTable } from "react-table";

const CustomTable = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
  return (
    <div className="table-responsive">
      <table
        className="table table-hover table-borderless"
        {...getTableProps()}
      >
        <thead style={{ backgroundColor: "#FAFAFA" }}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  style={{ color: "#8D8D8D" }}
                  className="text-uppercase"
                  scope="col"
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr className="border-bottom" {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      style={{ verticalAlign: "middle", color: "#424242" }}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
