import { connect } from "react-redux";
import React, { Component } from "react";
import Sidebar from "react-sidebar";

import { Images } from "../../theme";
import { DotsLoader, Header } from "../../components";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import "./styles.css";
import Sytles from "./styles";
import {
  SidebarContent,
  Footer,
  CustomTable,
  TableContainerHeader,
  Popup,
} from "../../components/SuperAdmin";
import { getResource, updateResource } from "../../config/simpleApiCalls";
import {
  update_privacyPolicy,
  update_cookiePolicy,
  update_legalInfo,
  get_privacyPolicy,
  get_cookiePolicy,
  get_legalInfo,
} from "../../config/WebServices";
import Swal from "sweetalert2";

class TermAndCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getTermData: [],
      textArea: "",
      isloading: true,
    };
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    this.setState({ superAdminData }, () => {
      this.getCookiePolicy()
      this.getLegalInfo()
      this.getPrivacyPolicy()
    });
  }

  updateData = async (url, payload) => {
    const { superAdminData } = this.state;

    this.setState({ isloading: true });
    try {
      await updateResource(url, payload, superAdminData?.access_token)
      Swal.fire({
        title: "Updated",
        text: "Successfully Updated",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
      this.setState({ isloading: false })
    } catch (error) {
      this.setState({ isloading: false })
      console.log("renderCreateTermAndCondition -> error", error)
      ErrorHelper("Something Went Wrong", true);
    }
  };

  updatePolicy = () => {
    const { PrivacyPolicy } = this.state
    const payload = {
      privacyPolicyId: PrivacyPolicy._id,
      title: PrivacyPolicy.title,
      description: PrivacyPolicy.description
    }
    this.updateData(update_privacyPolicy, payload)
  }

  updateCook = () => {
    const { CookiePolicy } = this.state
    const payload = {
      cookiePolicyId: CookiePolicy._id,
      title: CookiePolicy.title,
      description: CookiePolicy.description
    }
    this.updateData(update_cookiePolicy, payload)
  }

  updateLegal = () => {
    const { LegalInfo } = this.state
    const payload = {
      legalId: LegalInfo._id,
      title: LegalInfo.title,
      description: LegalInfo.description
    }
    this.updateData(update_legalInfo, payload)
  }

  renderTerm = () => {
    const { PrivacyPolicy } = this.state
    console.log("renderTerm -> PrivacyPolicy", PrivacyPolicy)
    return (
      <div className="col-12" >
        <div className="col-md-6">
          <h2 className="h4 py-3" style={{ color: '#424242' }}>Privacy Policy</h2>
        </div>
        <div className="row">

          <div className="col-6">
            <label for="categoryName">Enter Title:</label>
            <input
              type="text"
              className="form-control"
              id="categoryName"
              onChange={(e) => this.setState({ PrivacyPolicy: { ...PrivacyPolicy, title: e.target.value } })}
              value={PrivacyPolicy?.title || ""}
            />
          </div>

          <div className="col-12">
            <label for="categoryName">Enter Description:</label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="6"
              value={PrivacyPolicy?.description || ""}
              onChange={(e) => this.setState({ PrivacyPolicy: { ...PrivacyPolicy, description: e.target.value } })}
            ></textarea>
          </div>
        </div>

        <div onClick={this.updatePolicy} className="col pt-2" style={{ width: 150, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span className="tableContainerHeaderbtn">Save</span>
        </div>
      </div>
    )
  }

  renderleg = () => {
    const { LegalInfo } = this.state

    return (
      <div className="col-12" >
        <div className="col-md-6">
          <h2 className="h4 py-3" style={{ color: '#424242' }}>Legal Info</h2>
        </div>
        <div className="row">

          <div className="col-6">
            <label for="categoryName">Enter Title:</label>
            <input
              type="text"
              className="form-control"
              id="categoryName"
              value={LegalInfo?.title || ""}
              onChange={(e) => this.setState({ LegalInfo: { ...LegalInfo, title: e.target.value } })}
            />
          </div>

          <div className="col-12">
            <label for="categoryName">Enter Description:</label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="6"
              value={LegalInfo?.description || ""}
              onChange={(e) => this.setState({ LegalInfo: { ...LegalInfo, description: e.target.value } })}
            ></textarea>
          </div>
        </div>

        <div onClick={this.updateLegal} className="col pt-2" style={{ width: 150, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span className="tableContainerHeaderbtn">Save</span>
        </div>
      </div>
    )
  }

  renderCook = () => {
    const { CookiePolicy } = this.state
    console.log("renderCook -> CookiePolicy", CookiePolicy)

    return (
      <div className="col-12" >
        <div className="col-md-6">
          <h2 className="h4 py-3" style={{ color: '#424242' }}>Cookie Policy</h2>
        </div>
        <div className="row">

          <div className="col-6">
            <label for="categoryName">Enter Title:</label>
            <input
              type="text"
              className="form-control"
              id="categoryName"
              value={CookiePolicy?.title || ""}
              onChange={(e) => this.setState({ CookiePolicy: { ...CookiePolicy, title: e.target.value } })}
            />
          </div>

          <div className="col-12">
            <label for="categoryName">Enter Description:</label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="6"
              value={CookiePolicy?.description || ""}
              onChange={(e) => this.setState({ CookiePolicy: { ...CookiePolicy, description: e.target.value } })}
            ></textarea>
          </div>

        </div>

        <div onClick = {this.updateCook} className="col pt-2" style={{ width: 150, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span className="tableContainerHeaderbtn">Save</span>
        </div>
      </div>
    )
  }

  getPrivacyPolicy = (el) => {
    const { superAdminData } = this.state;
    getResource(get_privacyPolicy, superAdminData?.access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            PrivacyPolicy: res.data.data,
          });
          console.log("getPrivacyPolicy -> res.data.data", res.data.data)
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  getLegalInfo = (el) => {
    const { superAdminData } = this.state;
    getResource(get_legalInfo, superAdminData?.access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            LegalInfo: res.data.data,
          });
          console.log("getLegalInfo -> res.data.data", res.data.data)
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  getCookiePolicy = (el) => {
    const { superAdminData } = this.state;
    getResource(get_cookiePolicy, superAdminData?.access_token)
      .then((res) => {
        if (res.data.success) {
          console.log("getCookiePolicy -> res.data.data", res.data.data)
          this.setState({
            isloading: false,
            CookiePolicy: res.data.data,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };


  render() {
    const { columns, data } = this.state;
    return (
      <Sidebar
        sidebar={<SidebarContent {...this.props} />}
        open={this.state.sidebarOpen}
        onSetOpen={(val) => this.onSetSidebarOpen(val)}
        sidebarClassName="custom-sidebar"
        styles={Sytles}
      >
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          {<Header {...this.props} onSetSidebarOpen={this.onSetSidebarOpen} />}
          <div className="container py-5 mb-4 mb-md-5" >
            <div className="row">
              <div className="col-12 mt-4 bg-white shadow rounded px-3">
                {this.renderTerm()}
                {this.renderleg()}
                {this.renderCook()}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(TermAndCondition);
