import React, { useRef } from "react"
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import "react-datepicker/dist/react-datepicker.css"
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css'

const Annoucement = ({
  onChange,
  data
}) => {
  const headerEnRef = useRef()
  const headerFrRef = useRef()
  const cMsgEnRef = useRef()
  const cMsgFrRef = useRef()
  const eMsgEnRef = useRef()
  const eMsgFrRef = useRef()
  const onSuneditorChange = (value, ref, ...params) => {
    if(!ref.current.editor.getText()) {
      onChange(null, ...params)
    } else {
      onChange(value, ...params)
    }
  }
  return (
    <div className="p-3">
      { data &&
        <>
          <label for="address">Title:</label>
          <input
            type="text"
            value={data.title}
            onChange={(e) => {
              onChange(e.target.value, 'title')
            }}
            className="form-control"
            id="address"
            aria-describedby="emailHelp"
          />
          <label for="header-en">Header - English:</label>
          <SunEditor 
            ref={headerEnRef}
            id="header-en"
            defaultValue={data.header?.en}
            onChange={(e) => {
              onSuneditorChange(e, headerEnRef, 'header', 'en')
            }}
          />
          <label for="header-fr">Header - French:</label>
          <SunEditor 
            ref={headerFrRef}
            id="header-fr"
            defaultValue={data.header?.fr}
            onChange={(e) => {
              onSuneditorChange(e, headerFrRef, 'header', 'fr')
            }}
          />
          <label for="cl-msg-en">Client Message - English:</label>
          <SunEditor 
            ref={cMsgEnRef}
            id="cl-msg-en"
            defaultValue={data.clientMessage?.en}
            onChange={(e) => {
              onSuneditorChange(e, cMsgEnRef, 'clientMessage', 'en')
            }}
          />
          <label for="cl-msg-fr">Client Message - French:</label>
          <SunEditor 
            ref={cMsgFrRef}
            id="cl-msg-fr"
            defaultValue={data.clientMessage?.fr}
            onChange={(e) => {
              onSuneditorChange(e, cMsgFrRef, 'clientMessage', 'fr')
            }}
          />
          <label for="emp-msg-en">Employee Message - English:</label>
          <SunEditor 
            ref={eMsgEnRef}
            id="emp-msg-en"
            defaultValue={data.employeeMessage?.en}
            onChange={(e) => {
              onSuneditorChange(e, eMsgEnRef, 'employeeMessage', 'en')
            }}
          />
          <label for="emp-msg-fr">Employee Message - French:</label>
          <SunEditor 
            ref={eMsgFrRef}
            id="emp-msg-fr"
            defaultValue={data.employeeMessage?.fr}
            onChange={(e) => {
              onSuneditorChange(e, eMsgFrRef, 'employeeMessage', 'fr')
            }}
          />
          <label for="status">Status:</label>
          <select
            id="function-role"
            value={data.status ? data.status : 'Active' }
            class="form-control mt-1"
            onChange={(e) => {
              onChange(e.target.value, 'status')
            }}
          >
            <option
              value='Active'
            >
              Active
            </option>
            <option
              value='Inactive'
            >
              Inactive
            </option>
          </select>
        </>
      }
    </div>
  )
}

export default Annoucement