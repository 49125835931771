import { Bar } from "@nivo/bar";
import React, { Component } from 'react';
// import data from './Data.json'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
  };
  
  const data = [
    {
      map: "A",
      "Existing Clients": 0,
      "New Clients": 0
    },
    {
      map: "B",
      "Existing Clients": 0,
      "New Clients": 0
    },
    {
      map: "C",
      "Existing Clients": 20,
      "New Clients": 30
    },
    {
      map: "D",
      "Existing Clients": 0,
      "New Clients": 10
    },
    {
      map: "E",
      "Existing Clients": 0,
      "New Clients": 0
    },
    {
      map: "F",
      "Existing Clients": 20,
      "New Clients": 30
    },
    {
      map: "G",
      "Existing Clients": 20,
      "New Clients": 30
    },
    {
      map: "H",
      "Existing Clients": 0,
      "New Clients": 0
    },
    {
      map: "I",
      "Existing Clients": 0,
      "New Clients": 0
    },
    {
      map: "J",
      "Existing Clients": 10,
      "New Clients": 30
    },
    {
      map: "K",
      "Existing Clients": 10,
      "New Clients": 30
    },
    {
      map: "L",
      "Existing Clients": 20,
      "New Clients": 5
    },
    {
      map: "M",
      "Existing Clients": 10,
      "New Clients": 20
    }
  ];
  
  const axisBottom = {
    tickSize: 0,
    // tickPadding: 5,
    // tickRotation: 0,
    // legend: "Map",
    // legendPosition: "middle",
    // legendOffset: 32
  };
  
  const axisLeft = {
    tickSize: 0,
    // tickPadding: 5,
    // tickRotation: 0,
    // legend: "Existing Clients" / New",
    // legendPosition: "middle",
    // legendOffset: -40
  };
  
  const theme = {
    axis: {
      fontSize: "14px",
      tickColor: "#eee",
      ticks: {
        line: {
          stroke: "#555555"
        },
        text: {
          fill: "#000"
        }
      },
      legend: {
        text: {
          fill: "#aaaaaa"
        }
      }
    },
    grid: {
      line: {
        stroke: "#DDDDDD"
      }
    }
  };
  const colorBy = ({ id }) => (id === "New Clients" ? "#364DFF" : "#9CC7FE");
  
  const legends = [
    {
      dataFrom: "keys",
      anchor: "bottom",
      direction: "row",
      justify: false,
      translateX: 0,
      translateY: 0,
      itemsSpacing: 2,
      itemWidth: 110,
      itemHeight: -80,
    //   itemDirection: "left-to-right",
      itemOpacity: 0.85,
      itemTextColor: "#000",
      symbolSize: 20,
      effects: [
        {
          on: "hover",
          style: {
            itemOpacity: 1
          }
        }
      ]
    }
  ];
  
  class MyResponsiveBar extends Component {
      state = {  }
      render() { 
          return ( 
            <div>
            <Bar
              width={330}
              height={250}
              margin={{ top: 10, right: 0, bottom: 50, left: 60 }}
              data={data}
              keys={["New Clients", "Existing Clients"]}
              indexBy="map"
              labelTextColor="inherit:darker(2.4)"
              labelSkipWidth={50}
              labelSkipHeight={55}
              enableGridX={false}
              axisBottom={axisBottom}
              axisLeft={axisLeft}
              colors={colorBy}
              theme={theme}
              legends={legends}
            />
          </div>
          );
      }
  }

   
  export default MyResponsiveBar;