export const baseUrl = "https://prod-dot-scenic-lane-275515.uc.r.appspot.com/api/"; // Live
// export const baseUrl = 'http://192.168.2.82:8080/api/'; // H 
// export const baseUrl = 'http://192.168.0.108:8080/api/'; // H 

// SALOON AUTH API
export const login_Api = `${baseUrl}user/login`;
export const forget_password_Api = `${baseUrl}password/forget`;
export const verify_reset_code_Api = `${baseUrl}password/otpcode`;
export const reset_password_Api = `${baseUrl}password/reset`;
export const logout_Api = `${baseUrl}user/logout`;

export const place_Autocomplete_URL = (value, secret_Key) =>
  `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${value}&key=${secret_Key}`;

export const get_salons_Api = `${baseUrl}admin/salon/getSalons`;
export const get_all_bookings_Api = `${baseUrl}admin/bookings/getBookings`;
export const get_categories_Api = `${baseUrl}admin/category/getCategories`;
export const create_category_Api = `${baseUrl}admin/category/createCategory`;
export const top_rated_salon = `${baseUrl}admin/salon/topRated`;
export const salon_rating_reviews = `${baseUrl}admin/ratings/getRatings`;
export const update_user_booking_Api = `${baseUrl}admin/bookings/updateBooking`;
export const delete_user_booking_Api = `${baseUrl}admin/bookings/deleteBooking`;
export const get_employees = `${baseUrl}admin/employees/getEmployees`;
export const get_customers = `${baseUrl}admin/customers/getCustomers`;
export const get_term_and_condition_Api = `${baseUrl}admin/terms/getTerms`;
export const create_term_and_condition_Api = `${baseUrl}admin/terms/createTerms`;
export const get_faqs_Api = `${baseUrl}faq/getFAQ`;
export const create_faqs_Api = `${baseUrl}admin/faqs/createFaqs`;
export const get_all_salon_Api = `${baseUrl}admin/salon/getSalons`;
export const delete_salon_Api = `${baseUrl}admin/salon/deleteSalon`;
export const update_salon_personal_info_Api = `${baseUrl}admin/salon/updateSalonPersonalInfo`;
export const update_salon_business_info_Api = `${baseUrl}admin/salon/updateSalon`;
export const update_employee = `${baseUrl}admin/employees/updateEmployeePersonalInfo`;
export const update_customer = `${baseUrl}admin/customers/updateCustomer`;
export const get_wallet = `${baseUrl}admin/wallet/getWallet`;
export const create_cashback = `${baseUrl}admin/wallet/createCashBack`;
export const get_plans_Api = `${baseUrl}admin/plans/getPlans`;
export const add_social_link = `${baseUrl}admin/socialLinks/createLink`;
export const get_social_links = `${baseUrl}admin/socialLinks/getSocialLink`;
export const update_social_links = `${baseUrl}admin/socialLinks/updateLink`;
export const update_faqs = `${baseUrl}admin/faqs/updateFaqs`;
export const update_plan_Api = `${baseUrl}admin/plans/updatePlan`;
export const get_homeContent = `${baseUrl}admin/home/getHome`;
export const update_homeContent = `${baseUrl}admin/home/updateHome`;
export const update_homeMedia = `${baseUrl}admin/home/uploadPost`;
export const update_privacyPolicy = `${baseUrl}admin/privacyPolicy/updatePrivacyPolicy`;
export const update_cookiePolicy = `${baseUrl}admin/cookiePolicy/updateCookiePolicy`;
export const update_legalInfo = `${baseUrl}admin/legalInfo/updateLegalInfo`;
export const get_privacyPolicy = `${baseUrl}admin/privacyPolicy/getPrivacyPolicy`;
export const get_cookiePolicy = `${baseUrl}admin/cookiePolicy/getCookiePolicy`;
export const get_legalInfo = `${baseUrl}admin/legalInfo/getLegalInfo`;
export const delete_plan_api = `${baseUrl}admin/plans/deletePlan`;
export const edit_category = `${baseUrl}admin/category/editCategory`;
export const get_bundle = `${baseUrl}admin/bundle/getBundle`;
export const edit_bundle = `${baseUrl}admin/bundle/editBundle`;
export const create_bundle = `${baseUrl}admin/bundle/createBundle`;

export const update_salon_active_plans = `${baseUrl}admin/plans/updateCompanyPlan`;

export const get_salon_active_plans = (companyId) =>
  `${baseUrl}admin/plans/getPlans?companyId=${companyId}`;

export const delete_categories_Api = (categoryId) =>
  `${baseUrl}admin/category/deleteCategories?categoryId=${categoryId}`;
export const delete_customer_Api = (customerId) =>
  `${baseUrl}admin/customers/deleteCustomer?customerId=${customerId}`;
// export const delete_customer_Api = (customerId) =>
//   `${baseUrl}admin/customers/deleteCustomer?customerId=${customerId}`;
export const delete_employee_Api = (employeeId) =>
  `${baseUrl}admin/employees/deleteEmployee?employeeId=${employeeId}`;
export const delete_faq_Api = (faqId) =>
  `${baseUrl}admin/faqs/deleteFaqs?faqId=${faqId}`;

// demo
export const demonstration = `${baseUrl}demostrationReservation`;
export const get_stats = `${baseUrl}admin/getStats`;
export const impersonation = `${baseUrl}admin/impersonation`;
export const announcements = `${baseUrl}admin/announcements`;
