import { combineReducers } from "redux";

import login from "./Login";
import forgotPassword from "./ForgotPassword";
import verifyResetCode from "./VerifyResetCode";
import resetPassword from "./ResetPassword";
import socialLogin from "./SocialLogin";
import logout from "./LogOut";
import announcements from "./Announcements";

// TEMPLATE
import templateNavigation from "./TemplateNavigation"

export const rootReducer = combineReducers({
  login,
  forgotPassword,
  verifyResetCode,
  resetPassword,
  socialLogin,
  logout,
  announcements,

  // TEMPLATE
  templateNavigation,
});
