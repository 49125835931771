import { connect } from "react-redux";
import React, { Component } from "react";
import Sidebar from "react-sidebar";

import { Images } from "../../theme";
import { SidebarContent } from "../../components/SuperAdmin";
import { top_rated_salon } from "../../config/WebServices";
import { getResource } from "../../config/simpleApiCalls";
import { DotsLoader, Header } from "../../components";
// import { ErrorHelper } from "../../helpers";

import "./styles.css";
import Sytles from "./styles";
import StarRating from "./../../components/Star/star";

import ReactStars from "react-rating-stars-component";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      isloading: true,
      usersList: [],
      blockUsersList: [],
      unblockUsersList: [],
      totalEmploye: [],
      totalCategories: [],
      totalAppointment: "",
      topRatedSalon: [],
    };
  }

  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    this.setState({ superAdminData }, () => {
      this.handleGetTopRatedSalon();
    });
  }

  handleGetTopRatedSalon = (el) => {
    const { superAdminData } = this.state;
    getResource(top_rated_salon, superAdminData?.access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            topRatedSalon: res.data.data,
            showModal: el,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderTopSalonCard = () => {
    const { topRatedSalon } = this.state;

    return (
      <div>
        {!!topRatedSalon.length &&
          topRatedSalon.map((v) => (
            <div className="col-12 bg-white shadow rounded p-3 d-md-flex align-items-center m-2 ">
              <img
                className="pl-3 pl-md-0"
                src={v.salon.userId.profile_img}
                style={{ width: "100px", height: "100px" }}
              />
              <div className="pl-3" style={{ overflow: "hidden" }}>
                <h2>{v.salon.name}</h2>
                <p>{v.salon.companyShortDescription}</p>
                <div className=" row col-md-11">
                  <ReactStars
                    count={5}
                    size={28}
                    edit={false}
                    value={v.salon.averageRating}
                    activeColor="#ffd700"
                  />
                </div>

                <div className="d-flex justify-content-between row col-md-12">
                  <p>
                    Location:
                    <strong> {v.salon.city}, ON</strong>
                  </p>
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "white",
                      border: "2px solid #FF3600",
                      color: "gray",
                      borderRadius: "40px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                  >
                    Preview
                  </button> */}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
    // });
  };

  renderUserTable = () => {
    return (
      <div className="col-12 col-md-12 col-lg-12">
        <div
          className="py-1 shadow-sm mb-5 bg-red cards border-0"
          style={{
            borderRadius: "1px",
            marginTop: "5vh",
            backgroundColor: "white",
          }}
        >
          <div className="d-md-flex row justify-content-between aligh-items-center mb-3 col-md-12">
            <div className="pl-5 pt-3">
              <strong className="appointment-management">
                Top Salons List
              </strong>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { usersList, blockUsersList, unblockUsersList } = this.state;
    return (
      <Sidebar
        sidebar={<SidebarContent {...this.props} />}
        open={this.state.sidebarOpen}
        onSetOpen={(val) => this.onSetSidebarOpen(val)}
        sidebarClassName="custom-sidebar"
        styles={Sytles}
      >
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          //   style={{ backgroundColor: "#F7F7F7" }}
        >
          <Header {...this.props} onSetSidebarOpen={this.onSetSidebarOpen} />
          <div className="container pt-5">
            {this.renderUserTable()}
            {this.renderTopSalonCard()}
          </div>
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(Dashboard);
