import { connect } from "react-redux";
import React, { Component } from "react";
import Sidebar from "react-sidebar";
import axios from "axios";
import { Images } from "../../theme";
import { SidebarContent, Footer } from "../../components/SuperAdmin";
import {
    createResource,
    getResource,
    updateResource,
} from "../../config/simpleApiCalls";
import {
    add_social_link,
    get_social_links,
    update_social_links,
    get_homeContent,
    update_homeContent,
    update_homeMedia
} from "../../config/WebServices";
import { DotsLoader, Header } from "../../components";
import { SuccessHelper, ErrorHelper } from "../../helpers";

import "./styles.css";
import Styles from "./styles";

class Setting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarOpen: false,
            facebookLink: "",
            instagramLink: "",
            twitterLink: "",
            youtubeLink: "",
            snapchatLink: "",
            linkedinLink: "",

            facebookIcon: "https://img.icons8.com/color/2x/facebook-new.png",
            instagramIcon:
                "https://image.flaticon.com/icons/png/128/2111/2111463.png",
            twitterIcon: "https://img.icons8.com/fluent/2x/twitter.png",
            youtubeIcon: "https://img.icons8.com/fluent/2x/youtube-squared.png",
            snapChatIcon: "https://img.icons8.com/fluent/2x/snapchat.png",
            linkedInIcon: "https://image.flaticon.com/icons/png/512/145/145807.png",
            // facebookLink: 'https://www.facebook.com/test-user-91',
            // instagramLink: 'https://www.instagram.com/test-user-91',
            // twitterLink: 'https://twitter.com/test-user-91',
            // youtubeLink: 'https://www.youtube.com/test-user-91',
            // snapchatLink: 'https://www.snapchat.com/test-user-91',
            // linkedinLink: 'https://www.linkedin.com/test-user-91',
            hasLink: false,
            access_token: "",
            companyId: "",
            isloading: false,
            linkId: "",
        };
    }

    componentDidMount() {
        const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
        this.setState({ superAdminData }, () => this.handleGetLinks());
        this.handleGetContent(superAdminData)
    }

    handleGetContent = (superAdminData) => {
        console.log("Setting -> handleGetContent -> superAdminData", superAdminData)
        getResource(get_homeContent, superAdminData?.access_token)
            .then((res) => {
                console.log("getResource: ", res.data.data);
                if (res.data.success) {
                    this.setState({
                        isloading: false,
                        data: res.data.data,
                    });
                } else {
                    this.setState({ isloading: false });
                }
            })
            .catch((error) => {
                this.setState({ isloading: false });
                console.log(error);
            });
    };

    handleGetLinks = () => {
        const { superAdminData } = this.state;
        getResource(get_social_links, superAdminData?.access_token)
            .then((res) => {
                console.log("res: ", res.data.data.__id);
                if (res.data.success) {
                    this.setState({
                        isloading: false,
                        linkId: res.data.data && res.data.data._id,
                        facebookLink: res.data.data.socialURL
                            ? res.data.data?.socialURL.url
                            : "",
                        instagramLink: res.data.data.socialURL[3]
                            ? res.data.data?.socialURL[3].url
                            : "",
                        twitterLink: res.data.data.socialURL[1]
                            ? res.data.data?.socialURL[1].url
                            : "",
                        youtubeLink: res.data.data.socialURL[4]
                            ? res.data.data?.socialURL[4].url
                            : "",
                        snapchatLink: res.data.data.socialURL[2]
                            ? res.data.data?.socialURL[2].url
                            : "",
                        linkedinLink: res.data.data.socialURL[5]
                            ? res.data.data?.socialURL[5].url
                            : "",
                    });
                } else {
                    this.setState({ isloading: false });
                }
            })
            .catch((error) => {
                this.setState({ isloading: false });
                console.log(error);
            });
    };

    onCreateSocialMedia = async () => {
        this.setState({ isloading: true });
        const {
            access_token,
            facebookLink,
            instagramLink,
            twitterLink,
            youtubeLink,
            linkedinLink,
            snapchatLink,
            companyId,
            superAdminData,
        } = this.state;
        const payload = {
            socialURL: [
                { socialType: "facebook", url: facebookLink },
                { socialType: "twitter", url: twitterLink },
                { socialType: "snapchat", url: snapchatLink },
                { socialType: "instagram", url: instagramLink },
                { socialType: "youtube", url: youtubeLink },
                { socialType: "linkedin", url: linkedinLink },
            ],
        };

        createResource(add_social_link, payload, superAdminData.access_token)
            .then((res) => {
                console.log("res: ", res);
                if (res.data.success) {
                    SuccessHelper.handleSuccess(`Links Successfully Updated!.`, true);
                    this.setState({ isloading: false });
                    this.handleGetLinks();
                } else {
                    this.setState({ isloading: false });
                }
            })
            .catch((error) => {
                this.setState({ isloading: false });
                // ErrorHelper.handleSuccess(`Something Went Wrong!.`, true);
                console.log("ll", error);
            });
    };

    onUpdateSocialMedia = async () => {
        this.setState({ isloading: true });
        const {
            superAdminData,
            facebookLink,
            instagramLink,
            twitterLink,
            youtubeLink,
            linkedinLink,
            snapchatLink,
            linkId,
        } = this.state;
        const payload = {
            socialURL: [
                { socialType: "facebook", url: facebookLink },
                { socialType: "twitter", url: twitterLink },
                { socialType: "snapchat", url: snapchatLink },
                { socialType: "instagram", url: instagramLink },
                { socialType: "youtube", url: youtubeLink },
                { socialType: "linkedin", url: linkedinLink },
            ],
            linkId,
        };

        updateResource(update_social_links, payload, superAdminData?.access_token)
            .then((res) => {
                console.log("update_employee: ", res);
                if (res.data.success) {
                    this.setState({
                        isloading: false,
                    });
                    SuccessHelper.handleSuccess(`Links successfully updated.`, true);
                } else {
                    this.setState({ isloading: false });
                }
            })
            .catch((error) => {
                this.setState({ isloading: false });

                ErrorHelper.handleErrors("Something Went Wrong", true);
                console.log(error);
            });

        // console.log(access_token);
        // console.log(companyId);
        // UpdateSaloonSocialMedia(payload, access_token)
        //   .then((res) => {
        //     if (res.data.success) {
        //       this.getAllSocialMedia();
        //     } else {
        //       this.setState({ isloading: false });
        //     }
        //   })
        //   .catch((error) => {
        //     console.log(error, "errorrrrrrrrrrrrrrrrrrr");
        //   });
    };

    handleNavigation = (route) => {
        this.props.history.push(route);
    };

    onSetSidebarOpen = (open) => {
        this.setState({ sidebarOpen: open });
    };

    onChangeFacebook = (text) =>
        this.setState({ facebookLink: text.target.value });
    onChangeInstagram = (text) =>
        this.setState({ instagramLink: text.target.value });
    onChangeTwitter = (text) => this.setState({ twitterLink: text.target.value });
    onChangeYoutube = (text) => this.setState({ youtubeLink: text.target.value });
    onChangeSnapchat = (text) =>
        this.setState({ snapchatLink: text.target.value });
    onChangeLinkedin = (text) =>
        this.setState({ linkedinLink: text.target.value });

    renderLoader = () => {
        const { isloading } = this.state;
        return <DotsLoader isloading={isloading} />;
    };

    renderSaloonDashboardHeading = (title) => {
        return (
            <div className="col-12 p-3">
                <h1 className="saloon-dashboard-heading">{title}</h1>
                {/* <p className="saloon-dashboard-sub-heading">
          Lorem ipsum dolor sit amet, consectetur
        </p> */}
            </div>
        );
    };

    renderInputCol6 = (socialIcon, inputType, placeholder, onChange, value) => {
        return (
            <div class="form-group col-md-6 px-0 px-md-3 d-flex align-items-center">
                <img
                    onClick={() => alert("SF")}
                    class="mr-2"
                    src={socialIcon}
                    style={{
                        width: 40,
                        height: 40,
                        borderRadius: "30px",
                    }}
                />
                <input
                    value={value}
                    type={inputType}
                    placeholder={placeholder}
                    class="form-control saloon-link-social-input"
                    onChange={(text) => onChange(text)}
                />
            </div>
        )
    };

    onHeaderDataChange = (main, key, value, page) => {
        console.log("Setting -> onHeaderDataChange -> main, key, value", main, key, value)
        let { data } = this.state
        data[page][main][key] = value
        this.setState({ data })
    }

    onHeaderItmeChange = (main, key, value, index, page) => {
        console.log("Setting -> onHeaderDataChange -> main, key, value", main, key, value)
        let { data } = this.state
        data[page][main].listItem[index][key] = value
        this.setState({ data })
    }

    updateHomeContent = () => {
        const { superAdminData, data } = this.state;
       this.setState({isloading:true})
        updateResource(update_homeContent, data, superAdminData?.access_token)
            .then((res) => {
                console.log("update_employee: ", res);
                if (res.data.success) {
                    this.setState({
                        isloading: false,
                    });
                    SuccessHelper.handleSuccess(`Links successfully updated.`, true);
                } else {
                    this.setState({ isloading: false });
                }
                this.handleGetContent(superAdminData)
            })
            .catch((error) => {
                this.setState({ isloading: false });

                ErrorHelper.handleErrors("Something Went Wrong", true);
                console.log(error);
            });
    }

    fileSelectedHandler = (event, main, obj, index) => {
        let { superAdminData, data } = this.state
        if(!event.target.files[0]) return
        let payloadFormData = new FormData();
        payloadFormData.append("image", event.target.files[0] );

        this.setState({ isloading: true });

        createResource(update_homeMedia, payloadFormData, superAdminData?.access_token)
            .then((res) => {
                console.log("update_employee: ", res);
                    this.setState({
                        isloading: false,
                    });
                    if(index === undefined){
                        data[main][obj].img_url = res.data.image
                        this.setState({data})
                    } else {
                        data[main][obj].listItem[index].img_url = res.data.image
                        this.setState({data})
                    }
            })
            .catch((error) => {
                this.setState({ isloading: false });
                ErrorHelper.handleErrors("Something Went Wrong", true);
                console.log(error);
            });

        // const file = URL.createObjectURL(event.target.files[0]);
        // this.setState({ imageUrl: file, imageFile: event.target.files[0] });
      };

    renderHomePage = () => {
        const {
            data,
            linkId,
        } = this.state;

        if (!data) return <h1>Loading ...</h1>
        const { homePage: { body, header, muchMore, footer } } = data
        return (
            <div className="col-12 p-3">
                <div className="col-12 p-3 bg-white" style={{ borderRadius: "1rem" }}>

                    <h4>Header Content</h4>
                    <div className="col-12 mb-3">
                        <div style={{ borderBottomColor: '#d1d1d1', borderBottomWidth: 5, borderBottomStyle: 'solid' }} className="row col-12 p-5">
                            <div className="col-6">
                                <label for="categoryName">Enter Title:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="categoryName"
                                    placeholder={"Enter Title"}
                                    onChange={e => this.onHeaderDataChange('header', 'title', e.target.value, 'homePage')}
                                    value={header ? header.title : ""}
                                />
                            </div>

                            <div className="col-6 ">
                                <label>Upload Header Image:</label>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        // onChange={e => this.onHeaderDataChange('header', 'img_url', e.target.value)}
                                        onChange={e => this.fileSelectedHandler(e,"homePage", 'header')}
                                        // onChange={this.handleSelectedCategroyImage}
                                        className="custom-file-input"
                                        id="customFile"
                                    />
                                    <label className="custom-file-label" for="customFile">
                                        {header ? header.img_url.slice(0,30) + '...' : "Choose image"}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <h4>Body Content</h4>
                    <div class="form-row mb-3">
                        <div style={{ borderBottomColor: '#d1d1d1', borderBottomWidth: 1, borderBottomStyle: 'solid' }} className="row col-12 p-5">
                            <div className="col-6">
                                <label for="categoryName">Enter Title:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="categoryName"
                                    placeholder={"Enter Title"}
                                    onChange={e => this.onHeaderDataChange('body', 'mainTitle', e.target.value, 'homePage')}

                                    //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                    value={body.mainTitle || ""}
                                />
                            </div>

                            <div className="col-6 ">
                                <label>Upload Header Image:</label>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        // onChange={this.handleSelectedCategroyImage}
                                        // onChange={e => this.onHeaderDataChange('body', 'img_url', e.target.value)}
                                        onChange={e => this.fileSelectedHandler(e,"homePage", 'body')}

                                        className="custom-file-input"
                                        id="customFile"
                                    />
                                    <label className="custom-file-label" for="customFile">
                                        {body ? body.img_url.slice(0,30) + '...' : "Choose image"}
                                    </label>
                                </div>
                            </div>
                        </div>

                        {body.listItem.map((value, index) => (
                            <div key={index} style={{ borderBottomColor: '#d1d1d1', borderBottomWidth: 1, borderBottomStyle: 'solid' }} className="row col-12 p-5">
                                <div className="col-6">
                                    <h5>List Item  {index + 1}</h5>
                                    <label for="categoryName">Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="categoryName"
                                        //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                        onChange={e => this.onHeaderItmeChange('body', 'title', e.target.value, index, 'homePage')}
                                        value={value.title || ""}
                                    />
                                </div>

                                <div className="col-6">
                                    <h5 style={{ color: 'transparent' }}>not show</h5>
                                    <label for="categoryName">Description</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="categoryName"
                                        onChange={e => this.onHeaderItmeChange('body', 'des', e.target.value, index, 'homePage')}

                                        //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                        value={value.des || ""}
                                    />
                                </div>

                                <div className="col-6 ">
                                    <label>Upload Header Image:</label>
                                    <div className="custom-file">
                                        <input
                                            type="file"
                                            // onChange={this.handleSelectedCategroyImage}
                                            // onChange={e => this.onHeaderItmeChange('body', 'img_url', e.target.value, index)}
                                            onChange={e => this.fileSelectedHandler(e, "homePage", 'body', index)}
                                            className="custom-file-input"
                                            id="customFile"
                                        />
                                        <label className="custom-file-label" for="customFile">
                                            {value && value.img_url.slice(0,30) + '...' || ""}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>


                    <h4>Much More Content</h4>
                    <div class="form-row mb-3">
                        <div style={{ borderBottomColor: '#d1d1d1', borderBottomWidth: 1, borderBottomStyle: 'solid' }} className="row col-12 p-5">
                            <div className="col-6">
                                <label for="categoryName">Enter Title:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="categoryName"
                                    onChange={e => this.onHeaderDataChange('muchMore', 'mainTitle', e.target.value, 'homePage')}
                                    //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                    value={muchMore.mainTitle || ""}
                                />
                            </div>

                            <div className="col-6 ">
                                <label>Upload Header Image:</label>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        // onChange={this.handleSelectedCategroyImage}
                                        // onChange={e => this.onHeaderDataChange('muchMore', 'img_url', e.target.value)}
                                        onChange={e => this.fileSelectedHandler(e,"homePage", 'muchMore')}

                                        className="custom-file-input"
                                        id="customFile"
                                    />
                                    <label className="custom-file-label" for="customFile">
                                        {muchMore.img_url.slice(0,30) + '...' || ""}
                                    </label>
                                </div>
                            </div>
                        </div>

                        {muchMore.listItem.map((value, index) => (
                            <div key={index} style={{ borderBottomColor: '#d1d1d1', borderBottomWidth: 1, borderBottomStyle: 'solid' }} className="row col-12 p-5">
                                <div className="col-6">
                                    <h5>List Item  {index + 1}</h5>
                                    <label for="categoryName">Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="categoryName"
                                        onChange={e => this.onHeaderItmeChange('muchMore', 'title', e.target.value, index, 'homePage')}
                                        // onChange={e => this.fileSelectedHandler(e, "homePage", 'muchMore', index)}
                                        //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                        value={value.title || ""}
                                    />
                                </div>

                                <div className="col-6">
                                    <h5 style={{ color: 'transparent' }}>not show</h5>
                                    <label for="categoryName">Description</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="categoryName"
                                        onChange={e => this.onHeaderItmeChange('muchMore', 'des', e.target.value, index, 'homePage')}
                                        //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                        value={value.des || ""}
                                    />
                                </div>

                                <div className="col-6 ">
                                    <label>Upload Header Image:</label>
                                    <div className="custom-file">
                                        <input
                                            type="file"
                                            // onChange={this.handleSelectedCategroyImage}
                                            // onChange={e => this.onHeaderItmeChange('muchMore', 'img_url', e.target.value, index)}
                                        onChange={e => this.fileSelectedHandler(e, "homePage", 'muchMore', index)}

                                            className="custom-file-input"
                                            id="customFile"
                                        />
                                        <label className="custom-file-label" for="customFile">
                                            {value.img_url.slice(0,30) + '...' || ""}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>


                    <h4>Footer Content</h4>
                    <div class="form-row mb-3">
                        <div style={{ borderBottomColor: '#d1d1d1', borderBottomWidth: 1, borderBottomStyle: 'solid' }} className="row col-12 p-5">
                            <div className="col-6">
                                <label for="categoryName">Enter Title:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="categoryName"
                                    onChange={e => this.onHeaderDataChange('footer', 'mainTitle', e.target.value, 'homePage')}
                                    //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                    value={footer.mainTitle || ""}
                                />
                            </div>

                            <div className="col-6">
                                <label for="categoryName">Enter Description:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="categoryName"
                                    onChange={e => this.onHeaderDataChange('footer', 'des', e.target.value, 'homePage')}
                                    //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                    value={footer.des || ""}
                                />
                            </div>

                            <div className="col-6 ">
                                <label for="categoryName">Android App Link:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="categoryName"
                                    onChange={e => this.onHeaderDataChange('footer', 'iOS_link', e.target.value, 'homePage')}
                                    //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                    value={footer.iOS_link || ""}
                                />
                            </div>

                            <div className="col-6 ">
                                <label for="categoryName">IOS App Link:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="categoryName"
                                    //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                    onChange={e => this.onHeaderDataChange('footer', 'android_link', e.target.value, 'homePage')}
                                    value={footer.android_link || ""}
                                />
                            </div>



                            <div className="col-6 ">
                                <label>Upload Header Image:</label>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        // onChange={this.handleSelectedCategroyImage}
                                        // onChange={e => this.onHeaderDataChange('footer', 'img_url', e.target.value)}
                                        onChange={e => this.fileSelectedHandler(e, "homePage", 'footer', 'homePage')}
                                        className="custom-file-input"
                                        id="customFile"
                                    />
                                    <label className="custom-file-label" for="customFile">
                                        {footer.img_url.slice(0,30) + '...' || ""}
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="col-12 px-0 px-md-3">
                        <button
                            type="button"
                            onClick={() => this.updateHomeContent()}
                            className="btn btn-light btn-sm mb-2 "
                            id="saloon-save-social-link"
                        >
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    renderServicesPage = () => {
        const {
            data,
            linkId,
        } = this.state;
        if (!data) return <h1>Loading ...</h1>

        const { ourServices: { body, header, muchMore, footer, benefits } } = data

        return (
            <div className="col-12 p-3">
                <div className="col-12 p-3 bg-white" style={{ borderRadius: "1rem" }}>

                    <h4>Header Content</h4>
                    <div className="col-12 mb-3">
                        <div style={{ borderBottomColor: '#d1d1d1', borderBottomWidth: 5, borderBottomStyle: 'solid' }} className="row col-12 p-5">
                            <div className="col-6">
                                <label for="categoryName">Enter Title:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="categoryName"
                                    placeholder={"Enter Title"}
                                    onChange={e => this.onHeaderDataChange('header', 'title', e.target.value, 'ourServices')}
                                    value={header ? header.title : ""}
                                />
                            </div>

                            <div className="col-6 ">
                                <label>Upload Header Image:</label>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        // onChange={e => this.onHeaderDataChange('header', 'img_url', e.target.value)}
                                        onChange={e => this.fileSelectedHandler(e, "ourServices", 'header')}
                                        // onChange={this.handleSelectedCategroyImage}
                                        className="custom-file-input"
                                        id="customFile"
                                    />
                                    <label className="custom-file-label" for="customFile">
                                        {header ? header.img_url.slice(0,30) + '...' : "Choose image"}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <h4>Body Content</h4>
                    <div class="form-row mb-3">
                        <div style={{ borderBottomColor: '#d1d1d1', borderBottomWidth: 1, borderBottomStyle: 'solid' }} className="row col-12 p-5">
                            <div className="col-6">
                                <label for="categoryName">Enter Title:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="categoryName"
                                    placeholder={"Enter Title"}
                                    onChange={e => this.onHeaderDataChange('body', 'mainTitle', e.target.value, 'ourServices')}

                                    //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                    value={body.mainTitle || ""}
                                />
                            </div>

                            <div className="col-6 ">
                                <label>Upload Header Image:</label>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        // onChange={this.handleSelectedCategroyImage}
                                        // onChange={e => this.onHeaderDataChange('body', 'img_url', e.target.value)}
                                        onChange={e => this.fileSelectedHandler(e, "ourServices", 'body')}

                                        className="custom-file-input"
                                        id="customFile"
                                    />
                                    <label className="custom-file-label" for="customFile">
                                        {body ? body.img_url.slice(0,30) + '...' : "Choose image"}
                                    </label>
                                </div>
                            </div>
                        </div>

                        {body.listItem.map((value, index) => (
                            <div key={index} style={{ borderBottomColor: '#d1d1d1', borderBottomWidth: 1, borderBottomStyle: 'solid' }} className="row col-12 p-5">
                                <div className="col-6">
                                    <h5>List Item  {index + 1}</h5>
                                    <label for="categoryName">Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="categoryName"
                                        //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                        onChange={e => this.onHeaderItmeChange('body', 'title', e.target.value, index, 'ourServices')}
                                        value={value.title || ""}
                                    />
                                </div>

                                <div className="col-6">
                                    <h5 style={{ color: 'transparent' }}>not show</h5>
                                    <label for="categoryName">Description</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="categoryName"
                                        onChange={e => this.onHeaderItmeChange('body', 'des', e.target.value, index, 'ourServices')}

                                        //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                        value={value.des || ""}
                                    />
                                </div>

                                <div className="col-6 ">
                                    <label>Upload Header Image:</label>
                                    <div className="custom-file">
                                        <input
                                            type="file"
                                            // onChange={this.handleSelectedCategroyImage}
                                            // onChange={e => this.onHeaderItmeChange('body', 'img_url', e.target.value, index)}
                                            onChange={e => this.fileSelectedHandler(e, "ourServices", 'body', index)}

                                            className="custom-file-input"
                                            id="customFile"
                                        />
                                        <label className="custom-file-label" for="customFile">
                                            {value.img_url.slice(0,30) + '...' || ""}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>


                    <h4>Much More Content</h4>
                    <div class="form-row mb-3">
                        <div style={{ borderBottomColor: '#d1d1d1', borderBottomWidth: 1, borderBottomStyle: 'solid' }} className="row col-12 p-5">
                            <div className="col-6">
                                <label for="categoryName">Enter Title:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="categoryName"
                                    onChange={e => this.onHeaderDataChange('muchMore', 'mainTitle', e.target.value, 'ourServices')}
                                    //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                    value={muchMore.mainTitle || ""}
                                />
                            </div>

                            <div className="col-6 ">
                                <label>Upload Header Image:</label>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        // onChange={this.handleSelectedCategroyImage}
                                        // onChange={e => this.onHeaderDataChange('muchMore', 'img_url', e.target.value)}
                                        onChange={e => this.fileSelectedHandler(e, "ourServices", 'muchMore')}

                                        className="custom-file-input"
                                        id="customFile"
                                    />
                                    <label className="custom-file-label" for="customFile">
                                        {muchMore.img_url.slice(0,30) + '...' || ""}
                                    </label>
                                </div>
                            </div>
                        </div>

                        {muchMore.listItem.map((value, index) => (
                            <div key={index} style={{ borderBottomColor: '#d1d1d1', borderBottomWidth: 1, borderBottomStyle: 'solid' }} className="row col-12 p-5">
                                <div className="col-6">
                                    <h5>List Item  {index + 1}</h5>
                                    <label for="categoryName">Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="categoryName"
                                        onChange={e => this.onHeaderItmeChange('muchMore', 'title', e.target.value, index, 'ourServices')}
                                        //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                        value={value.title || ""}
                                    />
                                </div>

                                <div className="col-6">
                                    <h5 style={{ color: 'transparent' }}>not show</h5>
                                    <label for="categoryName">Description</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="categoryName"
                                        onChange={e => this.onHeaderItmeChange('muchMore', 'des', e.target.value, index, 'ourServices')}
                                        //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                        value={value.des || ""}
                                    />
                                </div>

                                <div className="col-6 ">
                                    <label>Upload Header Image:</label>
                                    <div className="custom-file">
                                        <input
                                            type="file"
                                            // onChange={this.handleSelectedCategroyImage}
                                            // onChange={e => this.onHeaderItmeChange('muchMore', 'img_url', e.target.value, index)}
                                            onChange={e => this.fileSelectedHandler(e, "ourServices", "muchMore", index)}

                                            className="custom-file-input"
                                            id="customFile"
                                        />
                                        <label className="custom-file-label" for="customFile">
                                            {value.img_url.slice(0,30) + '...' || ""}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>

                    <h4>Benefits Content</h4>
                    <div class="form-row mb-3">
                        <div style={{ borderBottomColor: '#d1d1d1', borderBottomWidth: 1, borderBottomStyle: 'solid' }} className="row col-12 p-5">
                            <div className="col-6">
                                <label for="categoryName">Enter Title:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="categoryName"
                                    onChange={e => this.onHeaderDataChange('benefits', 'mainTitle', e.target.value, 'ourServices')}
                                    //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                    value={benefits.mainTitle || ""}
                                />
                            </div>

                            <div className="col-6 ">
                                <label>Upload Header Image:</label>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        // onChange={this.handleSelectedCategroyImage}
                                        // onChange={e => this.onHeaderDataChange('benefits', 'img_url', e.target.value)}
                                        onChange={e => this.fileSelectedHandler(e, "ourServices" ,"benefits",)}

                                        className="custom-file-input"
                                        id="customFile"
                                    />
                                    <label className="custom-file-label" for="customFile">
                                        {benefits.img_url.slice(0,30) + '...' || ""}
                                    </label>
                                </div>
                            </div>
                        </div>

                        {benefits.listItem.map((value, index) => (
                            <div key={index} style={{ borderBottomColor: '#d1d1d1', borderBottomWidth: 1, borderBottomStyle: 'solid' }} className="row col-12 p-5">
                                <div className="col-6">
                                    <h5>List Item  {index + 1}</h5>
                                    <label for="categoryName">Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="categoryName"
                                        onChange={e => this.onHeaderItmeChange('benefits', 'title', e.target.value, index, 'ourServices')}
                                        // onChange={e => this.fileSelectedHandler(e, "benefits", 'body', index)}
                                        // onChange={(event) => this.handleOnChangeCategoryName(event)}
                                        value={value.title || ""}
                                    />
                                </div>

                                <div className="col-6">
                                    <h5 style={{ color: 'transparent' }}>not show</h5>
                                    <label for="categoryName">Description</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="categoryName"
                                        onChange={e => this.onHeaderItmeChange('benefits', 'des', e.target.value, index, 'ourServices')}
                                        //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                        value={value.des || ""}
                                    />
                                </div>

                                <div className="col-6 ">
                                    <label>Upload Header Image:</label>
                                    <div className="custom-file">
                                        <input
                                            type="file"
                                            // onChange={this.handleSelectedCategroyImage}
                                        onChange={e => this.fileSelectedHandler(e, "ourServices", "benefits", index)}
                                        // onChange={e => this.onHeaderItmeChange('benefits', 'img_url', e.target.value, index)}
                                            className="custom-file-input"
                                            id="customFile"
                                        />
                                        <label className="custom-file-label" for="customFile">
                                            {value.img_url.slice(0,30) + '...' || ""}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>


                    <h4>Footer Content</h4>
                    <div class="form-row mb-3">
                        <div style={{ borderBottomColor: '#d1d1d1', borderBottomWidth: 1, borderBottomStyle: 'solid' }} className="row col-12 p-5">
                            <div className="col-6">
                                <label for="categoryName">Enter Title:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="categoryName"
                                    onChange={e => this.onHeaderDataChange('footer', 'mainTitle', e.target.value, 'ourServices')}
                                    //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                    value={footer.mainTitle || ""}
                                />
                            </div>

                            <div className="col-6">
                                <label for="categoryName">Enter Description:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="categoryName"
                                    onChange={e => this.onHeaderDataChange('footer', 'des', e.target.value, 'ourServices')}
                                    //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                    value={footer.des || ""}
                                />
                            </div>

                            <div className="col-6 ">
                                <label for="categoryName">Android App Link:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="categoryName"
                                    onChange={e => this.onHeaderDataChange('footer', 'iOS_link', e.target.value, 'ourServices')}
                                    //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                    value={footer.iOS_link || ""}
                                />
                            </div>

                            <div className="col-6 ">
                                <label for="categoryName">IOS App Link:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="categoryName"
                                    //   onChange={(event) => this.handleOnChangeCategoryName(event)}
                                    onChange={e => this.onHeaderDataChange('footer', 'android_link', e.target.value, 'ourServices')}
                                    value={footer.android_link || ""}
                                />
                            </div>



                            <div className="col-6 ">
                                <label>Upload Header Image:</label>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        // onChange={this.handleSelectedCategroyImage}
                                        onChange={e => this.fileSelectedHandler(e, "ourServices", 'footer')}
                                        // onChange={e => this.onHeaderDataChange('footer', 'img_url', e.target.value)}
                                        className="custom-file-input"
                                        id="customFile"
                                    />
                                    <label className="custom-file-label" for="customFile">
                                        {footer.img_url.slice(0,30) + '...' || ""}
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="col-12 px-0 px-md-3">
                    <button
                            type="button"
                            onClick={() => this.updateHomeContent()}
                            className="btn btn-light btn-sm mb-2 "
                            id="saloon-save-social-link"
                        >
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return (
            <Sidebar
                sidebar={<SidebarContent {...this.props} />}
                open={this.state.sidebarOpen}
                onSetOpen={(val) => this.onSetSidebarOpen(val)}
                sidebarClassName="custom-sidebar"
                styles={Styles}
            >
                {this.renderLoader()}
                <div
                    className="container-fluid min-vh-100 px-0"
                    style={{ backgroundColor: "#F7F7F7" }}
                >
                    {<Header {...this.props} onSetSidebarOpen={this.onSetSidebarOpen} />}
                    <div className="container py-5 mb-4 mb-md-5">
                        <div className="row">
                            {this.renderSaloonDashboardHeading("Add Home Page Content")}
                            {this.renderHomePage()}
                            {this.renderSaloonDashboardHeading("Add Our Services Content")}
                            {this.renderServicesPage()}
                        </div>
                    </div>
                    <Footer />
                </div>
            </Sidebar>
        );
    }
}

const mapStateToProps = (state) => ({
    createSaloonSocialMedia: state.createSaloonSocialMedia,
});

const action = {
    //   create_social_media,
};

export default connect(mapStateToProps, action)(Setting);
