import { connect } from "react-redux";
import React, { Component } from "react";
import Sidebar from "react-sidebar";
import {
  getResource,
  createResource,
  updateResource,
  deleteResource,
} from "../../config/simpleApiCalls";
import {
  get_faqs_Api,
  create_faqs_Api,
  update_faqs,
  delete_faq_Api,
} from "../../config/WebServices";
import { Images } from "../../theme";
import { DotsLoader, Header } from "../../components";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import {} from "../../config/simpleApiCalls";
import "./styles.css";
import Swal from "sweetalert2";

import Sytles from "./styles";
import {
  SidebarContent,
  Footer,
  CustomTable,
  TableContainerHeader,
  Popup,
} from "../../components/SuperAdmin";

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      question: "",
      answer: "",
      isloading: true,
      isUpdate: false,
    };
  }

  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    this.setState({ superAdminData }, () => {
      this.handleGetFaqs();
    });
  }

  handleGetFaqs = (el) => {
    const { superAdminData } = this.state;
    getResource(get_faqs_Api, superAdminData?.access_token)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.success) {
          this.setState({
            isloading: false,
            data: res.data.data,
            showModal: el,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  handleAddFAQS = () => {
    const { question, answer, superAdminData } = this.state;

    this.setState({ isloading: true });

    let payload = {
      question,
      answer,
    };
    createResource(create_faqs_Api, payload, superAdminData?.access_token)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(`Question successfully added.`, true);
          this.setState({ showModal: true, isloading: false });
          this.handleGetFaqs(true);
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  handleQuestionChange = (e) => {
    // const userQuestion = this.state.question;
    this.setState({
      question: e.currentTarget.value,
    });
  };

  handleAnswerChange = (e) => {
    // const userQuestion = this.state.question;
    this.setState({
      answer: e.currentTarget.value,
    });
  };

  handleUpdateFaqs() {
    // alert("sfsdf");
    const { question, answer, faqId, superAdminData } = this.state;

    const payload = {
      faqId,
      question,
      answer,
    };

    this.setState({ isloading: true });

    updateResource(update_faqs, payload, superAdminData?.access_token)
      .then((res) => {
        console.log("update_employee: ", res);
        if (res.data.success) {
          this.setState({
            isloading: false,
            data: res.data.data,
          });
          SuccessHelper.handleSuccess(`Faqs successfully updated.`, true);
          this.handleGetFaqs();
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });

        ErrorHelper.handleErrors("Something Went Wrong", true);
        console.log(error);
      });
  }

  handleDeleteFaqs = (_id) => {
    const { superAdminData } = this.state;

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#354e68",
      cancelButtonColor: "#ff3600",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ isloading: true });
        deleteResource(delete_faq_Api(_id), superAdminData?.access_token)
          .then((res) => {
            console.log("deeeeeee: ", res);
            if (res.data.success) {
              SuccessHelper.handleSuccess(`FAQ's successfully delete.`, true);
              this.handleGetFaqs();
            } else {
              this.setState({ isloading: false });
            }
          })
          .catch((error) => {
            this.setState({ isloading: false });
            console.log(error);
          });
      }
    });
  };

  renderData = () => {
    const { answer, isUpdate } = this.state;

    return (
      <div class="container">
        {/* Company Short Description: */}

        <div className="row">
          <div className="form-group col-md-12">
            <label for="exampleInputEmail1" style={{ color: "lightgray" }}>
              Question:
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={this.state.question}
              onChange={this.handleQuestionChange}
            />
            <div className="form-group">
              <label
                for="exampleFormControlTextarea1"
                style={{ color: "lightgray" }}
              >
                Answer
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                value={answer}
                onChange={this.handleAnswerChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderDetail = () => {
    const data = this.state.data;
    return (
      <div className="p-3">
        {!!data.length &&
          data.map((value) => (
            <div>
              <h6>{value.question}</h6>
              <div className="d-flex justify-content-between">
                <p className="pr-5">{value.answer}</p>

                <div>
                  <img
                    src={Images.table_edit}
                    style={{ width: 25, height: 25 }}
                    data-target="#exampleModal"
                    data-toggle="modal"
                    className={"pv-2"}
                    onClick={() =>
                      this.setState({
                        answer: value.answer,
                        question: value.question,
                        isUpdate: true,
                        faqId: value._id,
                      })
                    }
                  />
                  <img
                    src={Images.table_delete}
                    className={"pv-2 ml-3"}
                    style={{ width: 25, height: 25 }}
                    onClick={() => this.handleDeleteFaqs(value._id)}
                  />
                </div>
              </div>
              <hr />
            </div>
          ))}
      </div>
    );
  };

  render() {
    const { columns, data, isUpdate } = this.state;
    return (
      <Sidebar
        sidebar={<SidebarContent {...this.props} />}
        open={this.state.sidebarOpen}
        onSetOpen={(val) => this.onSetSidebarOpen(val)}
        sidebarClassName="custom-sidebar"
        styles={Sytles}
      >
        {this.renderLoader()}
        <div
          className="container-fluid p-5 min-vh-100 px-0"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          {<Header {...this.props} onSetSidebarOpen={this.onSetSidebarOpen} />}
          <div className="container py-5 mb-4 mb-md-5">
            <div className="row">
              <div className="col-12 mt-4 bg-white shadow rounded px-0">
                <Popup
                  exampleModal="exampleModal"
                  heading="Add FAQs"
                  data={this.renderData()}
                  cancel={"Cancel"}
                  confirm={!isUpdate ? "Add" : "Update"}
                  confirmBtn={() => {
                    !isUpdate ? this.handleAddFAQS() : this.handleUpdateFaqs();
                  }}
                  backEvent={() =>
                    this.setState({
                      question: "",
                      answer: "",
                      isUpdate: false,
                    })
                  }
                />
                <div className="col-12 shadow rounded px-0">
                  <TableContainerHeader
                    headingText="FAQs"
                    buttonText="+Add"
                    dataTarget="#exampleModal"
                    dataToggle="modal"
                    buttonOnClick={() => {}}
                  />
                  {this.renderDetail()}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(FAQ);
