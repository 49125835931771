import React from "react";
import { useTable } from "react-table";
import "./styles.css";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
  },
};

const Popup = ({
  heading,
  cancel,
  confirm,
  exampleModal,
  data,
  confirmBtn,
  renderModal,
  modalIsOpen,
  afterOpenModal,
  closeModal,
  backEvent,
}) => {
  return (
    <div className="modal" id={exampleModal} tabindex="-1">
      <div className="modal-dialog modal-lg container">
        <div className="modal-content">
          <div className="modal-header" style={{ borderBottom: "0px" }}>
            <h5 className="modal-title" id="exampleModalLabel">
              {heading}
            </h5>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              aria-label="Close"
              onClick={backEvent}
            >
              <span aria-hidden="true">&times;</span>
              {/* {renderModal && renderDismiss()} */}
            </button>
          </div>
          <div className="container-fluid">{data}</div>
          <div className="modal-footer" style={{ borderTop: "none" }}>
            {cancel && (
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#FF3600",
                  color: "#000",
                }}
                onClick={backEvent}
              >
                {cancel}
              </button>
            )}
            {confirm && (
              <button
                onClick={confirmBtn}
                type="button"
                data-dismiss="modal"
                className="btn btn-primary"
                style={{ backgroundColor: "#FF3600", borderColor: "#FF3600" }}
              >
                {confirm}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Popup;
