import { connect } from "react-redux";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Login from "../containers/Login";
import Dashboard from "../containers/Dashboard";
// import ChatBox from "../containers/ChatBox";
import Chat from "../containers/Chat/chat-box.component";
import SalonManagement from "../containers/SalonManagement";
import AppointmentManagement from "../containers/AppointmentManagement";
import TopSalonList from "../containers/TopSalonList";
import SalonCategory from "../containers/SalonCategory";
import SalonRatingAndReview from "../containers/SalonRatingAndReview";
import Employees from "../containers/Employees";
import Customer from "../containers/Customer";
import TermAndCondition from "../containers/TermAndCondition";
import FAQ from "../containers/FAQ";
import Salon from "../containers/Wallet/Salon";
import Employee from "../containers/Wallet/Employee";
import PricingPlan from "../containers/Pricing&Plan";
import LinkSocialMedia from "../containers/LinkSocialMedia";
import Setting from "../containers/Setting";
import DemostrationReservations from "../containers/DemostrationReservations";
import Announcements from "../containers/Announcements";

const isUser = { isAuthenticated: false };

const isUserAuthenticate = () => {
  const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
  if (
    superAdminData &&
    superAdminData.access_token &&
    superAdminData.role == 1
  ) {
    isUser.isAuthenticated = true;
  } else {
    isUser.isAuthenticated = false;
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  let user = rest.props;
  let isAuthenticated = user && user.isAdmin;
  isUserAuthenticate();
  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        isAuthenticated || (isUser && isUser.isAuthenticated) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

class SuperAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.login) {
      if (
        !nextProps.login.failure &&
        !nextProps.login.isFetching &&
        !nextProps.login.errorMessage &&
        nextProps.login.data &&
        nextProps.login.data.data &&
        nextProps.login.data.data.access_token &&
        nextProps.login.data.data.role == 1
      ) {
        this.setState({ user: nextProps.login.data.data });
      }
    }
  }

  render() {
    const { user } = this.state;
    return (
      <Switch>
        <Route exact path="/" component={Login} props={user}></Route>
        <PrivateRoute
          exact
          path="/super-dashboard"
          component={Dashboard}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/super-chart-box"
          component={Chat}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/super-salon-management"
          component={SalonManagement}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/super-salon-category"
          component={SalonCategory}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/super-appointment-management"
          component={AppointmentManagement}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/super-salon-top-list"
          component={TopSalonList}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/super-salon-rating-review"
          component={SalonRatingAndReview}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/super-employee"
          component={Employees}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/super-customer"
          component={Customer}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/super-term-policies"
          component={TermAndCondition}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/super-faq"
          component={FAQ}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/saloon-salon-wallet"
          component={Salon}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/saloon-employee-wallet"
          component={Employee}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/pricing-plan"
          component={PricingPlan}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/link-social-media"
          component={LinkSocialMedia}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/setting"
          component={Setting}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/demostration-reservation"
          component={DemostrationReservations}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/announcements"
          component={Announcements}
          props={user}
        ></PrivateRoute>
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({ login: state.login });

const action = {};

export default connect(mapStateToProps, action)(SuperAdmin);
