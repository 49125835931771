import { put, takeEvery } from "redux-saga/effects";

import * as types from "../actions/ActionTypes";
import { upsert, remove, set, requestFailure } from "../actions/Announcements";

import axios from "axios";
import { announcements } from "../../config/WebServices";

const initializeToken = () => {
  const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
  return superAdminData.access_token
}

function* getSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    const token = yield initializeToken()
    data = yield axios.get(announcements, {
      params: payload,
      headers: {
        Authorization: token,
      },
    });
    data = data.data
    
    if(data.success) {
      if(callbackMap.success && typeof callbackMap.success == 'function') {
        callbackMap.success(data)
      }
      yield put(set(data.data));
    } else {
      if(callbackMap.failure && typeof callbackMap.failure == 'function') {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if(callbackMap.failure && typeof callbackMap.failure == 'function') {
      callbackMap.success(data)
    }
    yield put(requestFailure(data));
  }
}

function* upsertSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    const token = yield initializeToken()
    data = yield axios.post(announcements, payload, {
      headers: {
        Authorization: token,
      },
    });
    data = data.data
    console.log(data, callbackMap)
    if(data.success) {
      if(callbackMap.success && typeof callbackMap.success == 'function') {
        callbackMap.success(data)
      }
      yield put(upsert(data.data));
    } else {
      if(callbackMap.failure && typeof callbackMap.failure == 'function') {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if(callbackMap.failure && typeof callbackMap.failure == 'function') {
      callbackMap.success(data)
    }
    yield put(requestFailure(data));
  }
}

function* removeSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    const token = yield initializeToken()
    data = yield axios
      .delete(announcements, {
        params: payload,
        headers: {
          Authorization: token,
        },
      })
    data = data.data
    if(data.success) {
      if(callbackMap.success && typeof callbackMap.success == 'function') {
        callbackMap.success(data)
      }
      yield put(remove(data.data));
    } else {
      if(callbackMap.failure && typeof callbackMap.failure == 'function') {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if(callbackMap.failure && typeof callbackMap.failure == 'function') {
      callbackMap.success(data)
    }
    yield put(requestFailure(data));
  }
}

export default function* root() {
  yield takeEvery(types.ANNOUNCEMENTS.GET_SEGA, getSaga);
  yield takeEvery(types.ANNOUNCEMENTS.UPSERT_SEGA, upsertSaga);
  yield takeEvery(types.ANNOUNCEMENTS.DELETE_SEGA, removeSaga);
}
