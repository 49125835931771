import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Images } from "../../theme";
import { clearLogout as clear_logout } from "../../redux/actions/LogOut";
import { SuccessHelper, ErrorHelper } from "../../helpers";
import './style.css'
import Swal from 'sweetalert2'

class Header extends Component {

  state = {
    showDropDown: false,
    access_token: '',
  }

  renderLogout = () => {
    // const { routeName } = this.state;
    return (
      <li
        className={"sidebar-inactive-list buttonColor"}
        onClick={() => this.handleLogout()}
      >
        <img src={Images.saloon_drawer_log_out} style={{ width: 20 }} />
        <span style={{ marginLeft: 12 }}>Log Out</span>
      </li>
    );
  };

  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    const user = { ...superAdminData }
    this.setState({ user, access_token: user.access_token })
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  handleLogout = () => {
    // sessionStorage.setItem("superAdminData", "{}");
    sessionStorage.removeItem("superAdminData");
    SuccessHelper.handleSuccess("You have successfully logged out.", true);
    this.props.history.push("/");
  };

  render = () => {
    const { onSetSidebarOpen } = this.props
    const { showDropDown, user } = this.state

    if (!user) return null

    return (
      <div
        className="container-fluid d-flex justify-content-between align-items-center py-1"
        style={{
          backgroundColor: "#EBEBEB",
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          zIndex: 1,
        }}
      >
        <img
          onClick={() => onSetSidebarOpen(true)}
          src={Images.saloon_navbar_menu}
          style={{ width: 30, height: 30, cursor: "pointer" }}
        />
        <div className="mr-3 d-flex align-items-center" onClick={() => this.setState({ showDropDown: !this.state.showDropDown })}>
          <img src={Images.saloon_profile_icon} style={{ width: 35 }} />
          <div className="mx-2">
            <span className="saloon-user-name">{user.firstName + " " + user.lastName && user.userName}</span>
            <span className="saloon-sub-name">{user.email}</span>
          </div>
          <span className="ml-1" >
            <img
              src={Images.saloon_navbar_profile_dropdown}
              style={{ width: 12 }}
            />
          </span>
          {/* <div className="dropDown">
            {!!showDropDown && this.renderLogout()}
          </div> */}
        </div>
      </div>
    );
  };

}


const mapStateToProps = () => ({});

const action = { clear_logout };

export default connect(mapStateToProps, action)(Header);