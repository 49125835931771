import { connect } from "react-redux";
import React, { Component, useImperativeHandle } from "react";
import Sidebar from "react-sidebar";

import { Images } from "../../theme";
import {
  SidebarContent,
  Footer,
  CustomTable,
  TableContainerHeader,
  Popup,
} from "../../components/SuperAdmin";
import { DotsLoader, Header } from "../../components";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import {
  getResource,
  deleteResource,
} from "../../config/simpleApiCalls";
import {
  demonstration
} from "../../config/WebServices";
import moment from "moment";
import "./styles.css";
import Sytles from "./styles";

import "react-datepicker/dist/react-datepicker.css"
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css'

import Swal from "sweetalert2";

class SalonManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleValidationFirst: true,
      toggleValidationSecond: true,
      toggleValidationThird: true,
      allReservations: [],
      plans: [],
      columns: [
        {
          Header: " Name",
          accessor: "name",
        },
        {
          Header: "Company Name",
          accessor: "companyName",
        },
        {
          Header: "Email",
          accessor: "email",
        },
        {
          Header: "Telephone",
          accessor: "phoneNo",
        },
        {
          Header: "Date",
          accessor: "date",
        },
        {
          Header: "Time",
          accessor: "time",
        },
        {
          Header: "Status",
          Cell: (row) => this.renderTableEditDeleteIcon(row.row.original),
          accessor: "",
        },
      ],
      isloading: true,
      data: [],
      unformattedDate: undefined
    };
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    this.setState({ superAdminData }, () => {
      this.getAllReservations();
    });
  }

  getAllReservations = () => {
    const { superAdminData, unformattedDate } = this.state;
    const payload = {}
    if(unformattedDate) {
      payload.date = moment(unformattedDate).format('MM-DD-YYYY')
    }
    getResource(demonstration, superAdminData?.access_token, payload)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            allReservations: res.data.body,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  deleteDemoReservation = (_id) => {
    const { superAdminData, allReservations } = this.state;
    deleteResource(demonstration, superAdminData?.access_token, { _id })
    .then((res) => {
      if (res.data.success) {
        this.setState({
          isloading: false,
          allReservations: allReservations.filter(reservation => reservation._id != _id),
        });
      } else {
        this.setState({ isloading: false });
      }
    })
    .catch((error) => {
      this.setState({ isloading: false });
      console.log(error);
    });
  }

  
  onChangeDate = (unformattedDate) => {
    this.setState({ unformattedDate, isloading: true }, this.getAllReservations)
  }

  
  renderTableEditDeleteIcon = (reservation) => {
    return (
      <div>
        <img
          // onClick={() => { this.renderDeleteBooking(getByOneAppointment) }}
          onClick={() => {
            Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, delete it!",
            }).then(({value}) => {
              console.log(reservation)
              if(value) {
                this.deleteDemoReservation(reservation._id);
              }
            });
          }}
          style={{ height: 18, width: 18, marginLeft: 8, cursor: "pointer" }}
          src={Images.table_delete}
        />
      </div>
    );
  };

  renderTime = () => {
    const { time, unformattedDate } = this.state
    const timeSlots = [
        { value: 'undefined', text: 'All Times'},
        { value: '9', text: '9AM - 10AM'},
        { value: '10', text: '10AM - 11AM'},
        { value: '11', text: '11AM - 12PM'},
        { value: '12', text: '12PM - 1PM'},
        { value: '1', text: '1PM - 2PM'},
        { value: '2', text: '2PM - 3PM'},
        { value: '3', text: '3PM - 4PM'},
        { value: '4', text: '4PM - 5PM'},
        ...(unformattedDate && unformattedDate.getDay() != 6 ? [
            { value: '6', text: '6PM - 7PM'},
            { value: '7', text: '7PM - 8PM'},
            { value: '8', text: '8PM - 9PM'},
        ] : [])
    ];
    return (
        <div className="form-label-group mb-4 fullwidth">
          <select
            id="function-role"
            class="form-control mt-1"
            onChange={(e) => {
              console.log(e.target)
              this.setState({ time: e.target.value })
            }}
          >
            <option
              selected={!time}
              value={''}
            >
              Please select a time frame
            </option>
            {
              timeSlots.map(slot => {
                return (
                    <option
                      selected={time == slot.value}
                      value={slot.value}
                    >
                      {slot.text}
                    </option>
                )
              })
            }
          </select>
        </div>
    )
  }

  render() {
    const { columns, time, allReservations:all } = this.state;
    let allReservations = []
    console.log(all, time)
    allReservations = all 
    if(time && time != 'undefined') {
      allReservations = all.filter(val => {
        if(!time) return true
        return val.time == time
      })
    }

    return (
      <Sidebar
        sidebar={<SidebarContent {...this.props} />}
        open={this.state.sidebarOpen}
        onSetOpen={(val) => this.onSetSidebarOpen(val)}
        sidebarClassName="custom-sidebar"
        styles={Sytles}
      >
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          {<Header {...this.props} onSetSidebarOpen={this.onSetSidebarOpen} />}
          <div className="container pt-5" style={{ paddingBottom: 70}}>
            <div className="row">
              <div className="col-12 mt-4 bg-white shadow rounded px-0">
                <TableContainerHeader
                  headingText="Salon Management"
                  // buttonText="+Add"
                  // dataTarget="#exampleModal"
                  // dataToggle="modal"
                  buttonOnClick={() => { }}
                />
                <div class='row px-2'>
                  <div class='col-6'>
                    <DatePicker
                        className="form-control mt-1"
                        minDate={new Date()}
                        selected={this.state.unformattedDate}
                        
                        onChange={(date) =>
                            this.onChangeDate(date)
                        }
                        dayClassName={(date) => {
                            if(date.getDay() == 0 || date.getDay() == 6) {
                                return 'disabled-date'
                            }
                          }
                        }
                        placeholderText="Click to select a date"
                    />
                  </div>
                  <div class='col-6'>
                    {this.renderTime()}
                  </div>
                </div>

                <CustomTable columns={columns} data={allReservations} />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(SalonManagement);
