import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from "prop-types";

import { request as user_logout } from "../../redux/actions/LogOut";
import { clearLogout as clear_logout } from "../../redux/actions/LogOut";
import { SuccessHelper } from "../../helpers";

import './styles.css'

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            routeName: ''
        }
    }

    static propTypes = {
        showSidebar: PropTypes.bool
    };

    static defaultProps = {
        showSidebar: false
    };

    async componentDidMount() {
        let routeName = "";
        if (this.props.location.pathname.split('/')[1]) {
            routeName = this.props.location.pathname.split('/')[1];
        } else {
            routeName = this.props.location.pathname;
        }
        const user = await JSON.parse(sessionStorage.getItem('user'));
        this.setState({ user, routeName });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.logout) {
            if (
                !nextProps.logout.failure &&
                !nextProps.logout.isFetching &&
                !nextProps.logout.errorMessage &&
                nextProps.logout.data &&
                nextProps.logout.data.success
            ) {
                nextProps.clear_logout()
                sessionStorage.setItem("user", "{}")
                SuccessHelper.handleSuccess('You have successfully logged out.', true);
                this.setState({ user: null })
            } else if (
                nextProps.logout.failure &&
                !nextProps.logout.isFetching &&
                nextProps.logout.errorMessage
            ) {
                this.setState({ isloading: false });
            }
        }
    }

    handleNavigation = (route) => {
        this.props.history.push(route)
    }

    handleLogout = () => {
        const { user } = this.state;
        this.props.user_logout({ access_token: user.access_token });
        sessionStorage.setItem("employee", "{}");
        sessionStorage.setItem("saloon", "{}");
        SuccessHelper.handleSuccess("You have successfully logged out.", true);
        this.props.history.push("/");
    }

    renderNavbarItem = (route, title) => {
        const { routeName } = this.state
        return (
            <li
                className={routeName == route ? 'navbar-item-active' : 'navbar-item'}
                onClick={() => this.handleNavigation(route)}
            >{title}</li>
        )
    }

    renderLoginRegister = () => {
        return (
            <>
                {this.renderNavbarItem('login', "Login")}
                {this.renderNavbarItem('register', "Register")}
            </>
        )
    }

    renderLogout = () => {
        return (
            <li className='navbar-item' onClick={() => this.handleLogout()} >Log Out</li>
        )
    }

    render() {
        const { user } = this.state
        const { showSidebar } = this.props;
        return (
            <div className="sidebar-wrapper" style={{ display: showSidebar ? 'block' : 'none' }}>
                <ul className="navbar-list">
                    {this.renderNavbarItem('our-services', "Our Services")}
                    {this.renderNavbarItem('saloon-login', "Own a Salon")}
                    {this.renderNavbarItem('/employee-login', "Staff")}
                    {user && user.access_token ? this.renderLogout() : this.renderLoginRegister()}
                </ul>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ logout: state.logout });

const action = { user_logout, clear_logout };

export default connect(mapStateToProps, action)(Sidebar);