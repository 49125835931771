import { connect } from "react-redux";
import React, { Component } from "react";
import Sidebar from "react-sidebar";

import { Images } from "../../../theme";
import { SidebarContent, Footer, CustomTable, TableContainerHeader } from "../../../components/SuperAdmin";
import { DotsLoader, Header } from "../../../components";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import "./styles.css";
import Sytles from "./styles";


class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          Header: '',
          Cell: (row) => {
            return <div><img height={45} src={row.row.original.companyImage} /></div>
          },
          accessor: 'companyImage',
        },
        {
          Header: 'Salon Name',
          accessor: 'salonName',
        },
        {
          Header: 'Employee Name',
          accessor: 'employeeName',
        },
        {
          Header: 'Telephone',
          accessor: 'telephone',
        },
        {
          Header: 'Points',
          accessor: 'points',
        },
        {
          Header: 'Status',
          Cell: (row) => this.renderTableEditDeleteIcon(row.row.original.status),
          accessor: 'status',
        },
      ],
      data: [
        {
          companyImage: 'https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg',
          salonName: 'Bar Bar Shop 1',
          employeeName: '4434 Hall Place',
          telephone: '+1 999 999 999',
          points: '344444',
          status: '',
        },
      ],
    }
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  renderTableEditDeleteIcon = (_id) => {
    return (
      <div>
        <img
          onClick={() => { console.log(_id, 'edit') }}
          style={{ height: 18, width: 18, marginRight: 8, cursor: 'pointer' }}
          src={Images.table_edit}
        />
        <img
          onClick={() => { console.log(_id, 'delete') }}
          style={{ height: 18, width: 18, marginLeft: 8, cursor: 'pointer' }}
          src={Images.table_delete}
        />
      </div>
    )
  }


  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  render() {
    const { columns, data } = this.state;
    return (
      <Sidebar
        sidebar={<SidebarContent {...this.props} />}
        open={this.state.sidebarOpen}
        onSetOpen={(val) => this.onSetSidebarOpen(val)}
        sidebarClassName="custom-sidebar"
        styles={Sytles}
      >
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          {<Header {...this.props} onSetSidebarOpen={this.onSetSidebarOpen} />}
          <div className="container pt-5">
            <div className="row">
              <div className='col-12 mt-4 bg-white shadow rounded px-0'>
                <TableContainerHeader headingText="Employee Wallet"  buttonOnClick={() => { }} />
                <CustomTable columns={columns} data={data} />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(Employee);
