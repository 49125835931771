import { connect } from "react-redux";
import React, { Component } from "react";
import Sidebar from "react-sidebar";

import { Images } from "../../../theme";
import {
  SidebarContent,
  Footer,
  CustomTable,
  TableContainerHeader,
  Popup,
} from "../../../components/SuperAdmin";
import { DotsLoader, Header } from "../../../components";
import {
  getResource,
  updateResource,
  deleteResource,
  createResource,
} from "../../../config/simpleApiCalls";
import { get_wallet, create_cashback } from "../../../config/WebServices";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import "./styles.css";
import Sytles from "./styles";

class Salon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          Header: "Salon Image",
          // accessor: "",
          Cell: (row) => {
            return (
              <div>
                <img
                  style={{ width: 50 }}
                  height={45}
                  src={row.row.original.company.companyLogo}
                />
              </div>
            );
          },
          accessor: "company.companyLogo",
        },
        {
          Header: "Name",
          accessor: "company.name",
        },
        {
          Header: "Address",
          accessor: "company.address",
        },
        {
          Header: "Telephone",
          accessor: "company.businessTelephone",
        },
        {
          Header: "Points",
          accessor: "wallet[0].totalAmount",
        },
        {
          Header: "Action",
          Cell: (row) => this.renderTableEditDeleteIcon(row.row.original),
          accessor: "",
        },
      ],
      isloading: true,
      data: [],
      walletData: [],
      points: "",
      cash: "",
      cashBakPoints: "",
    };
  }

  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    this.setState({ superAdminData }, () => {
      this.handleGetWallet();
    });
  }

  handleGetWallet = (el) => {
    const { superAdminData } = this.state;
    getResource(get_wallet, superAdminData?.access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            walletData: res.data.data,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  handleCashAmmount = () => {
    const { superAdminData, cashBakPoints, companyId } = this.state;

    const payload = {
      points: cashBakPoints,
      companyId,
    };

    this.setState({ isloading: true });

    createResource(create_cashback, payload, superAdminData?.access_token)
      .then((res) => {
        console.log("handleCashAmmount -> res", res)
        if (res.data.success) {
          this.setState({ isloading: false, points: "", cashBakPoints: "", cash: "" });
          SuccessHelper.handleSuccess(`Points successfully added.`, true);
          this.handleGetWallet();
        } else {
          this.setState({ isloading: false, points: "", cashBakPoints: "", cash: "" }, () => {
            ErrorHelper.handleErrors(res.data.msg, true)
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  renderTableEditDeleteIcon = (data) => {
    return (
      <div
        className="col-md-12 d-flex"
        data-toggle="modal"
        data-target="#exampleModal"
        onClick={() => {
          this.setState({
            points: data.wallet[0]?.totalAmount,
            companyId: data.company._id,
          });
        }}
      >
        <span className="tableContainerHeaderbtn">Cash Back</span>
      </div>
    );
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  convertPoints = (e) => {
    let v = e.target.value;
    this.setState({ cash: v / 1000, cashBakPoints: v });
  };

  renderData = () => {
    const { points, cash, cashBakPoints } = this.state;

    return (
      <div class="container mt-3">
        {/* Company Short Description: */}

        <div className="row">
          <div className="form-group col-md-12">
            <label for="exampleInputEmail1" style={{ color: "lightgray" }}>
              Points
            </label>
            <input
              type="number"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={cashBakPoints}
              onChange={(e) => this.convertPoints(e)}
            />
            <label
              for="exampleFormControlTextarea1"
              style={{ color: "lightgray" }}
            >
              Amount
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => this.convertPoints(e)}
              value={cash}
              disabled={true}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { columns, data, walletData:all, points, searchText } = this.state;
    const walletData = all.filter(val => {
      if(!val?.company?.name) return false
      return val.company.name.toLocaleLowerCase().includes(searchText || '')
    })

    return (
      <Sidebar
        sidebar={<SidebarContent {...this.props} />}
        open={this.state.sidebarOpen}
        onSetOpen={(val) => this.onSetSidebarOpen(val)}
        sidebarClassName="custom-sidebar"
        styles={Sytles}
      >
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          {<Header {...this.props} onSetSidebarOpen={this.onSetSidebarOpen} />}
          <div className="container pt-5" style={{ paddingBottom: 70}}>
            <div className="row">
              <div className="col-12 mt-4 bg-white shadow rounded px-0">
                <TableContainerHeader
                  headingText="Salon Wallet"
                  buttonOnClick={() => { }}
                  dataTarget="#exampleModal"
                  onSearchVal={searchText => this.setState({searchText})}
                  dataToggle="modal"
                />
                <CustomTable columns={columns} data={walletData} />
              </div>
              <Popup
                exampleModal="exampleModal"
                heading={`Total current points available are : ${
                  points ? points : null
                  } Points`}
                data={this.renderData()}
                cancel={"Cancel"}
                confirm={"Redeem"}
                confirmBtn={() => this.handleCashAmmount()}
              />
            </div>
          </div>
          <Footer />
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(Salon);
