import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StarRatingComponent from 'react-star-rating-component';
import './styles.css';

class StarRating extends Component {
  constructor() {
    super();

    // this.state = {
    //   rating: 1
    // };
  }

  onStarClick(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }

  render() {
    const { rating } = this.props;

    return (
      <div className="row" style={{ flexDirection: 'row' }}>
        {/* <div className="col-sm-2">
          <div className="mr-4 mt-1">
            <h5 className="str">{rating}</h5>
          </div>
        </div> */}
          <div className="star-rating mt-2">
            <StarRatingComponent
              name="rate1"
              starCount={5}
              starColor="#FFD530"
              starDimension="100px"
              emptyStarColor="#E1E1E1"
              value={rating}
              onStarClick={this.onStarClick.bind(this)}
            />
          </div>
        </div>
    );
  }
}
export default StarRating;
