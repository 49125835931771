import { connect } from "react-redux";
import React, { Component } from "react";

import { Images } from "../../../theme";
import { clearLogout as clear_logout } from "../../../redux/actions/LogOut";
import { SuccessHelper } from "../../../helpers";

import "./styles.css";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeName: "",
      showDropdown: "",
      access_token: "",
    };
  }

  async componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    let routeName = "";
    if (
      this.props.location.pathname.split("/")[1].split("-")[0] === "superAdmin"
    ) {
      routeName = this.props.location.pathname;
    }
    this.setState({ routeName, access_token: superAdminData.access_token });
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.saloonLogout) {
  //     if (
  //       !nextProps.saloonLogout.failure &&
  //       !nextProps.saloonLogout.isFetching &&
  //       !nextProps.saloonLogout.errorMessage &&
  //       nextProps.saloonLogout.data &&
  //       nextProps.saloonLogout.data.success
  //     ) {
  //       nextProps.clear_logout();
  //       sessionStorage.setItem("superAdmin", "{}");
  //       sessionStorage.removeItem("superAdmin");
  //       SuccessHelper.handleSuccess("You have successfully logged out.", true);
  //       this.handleNavigation("/");
  //     } else if (
  //       nextProps.saloonLogout.failure &&
  //       !nextProps.saloonLogout.isFetching &&
  //       nextProps.saloonLogout.errorMessage
  //     ) {
  //       this.setState({ isloading: false });
  //     }
  //   }
  // }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  handleLogout = () => {
    const { access_token } = this.state;
    // sessionStorage.setItem("superAdmin", "{}");
    sessionStorage.removeItem("superAdmin");
    SuccessHelper.handleSuccess("You have successfully logged out.", true);
    this.props.history.push("/");
  };

  renderLogout = () => {
    return (
      <li
        className={"sidebar-inactive-list"}
        onClick={() => this.handleLogout()}
      >
        <img src={Images.saloon_drawer_log_out} style={{ width: 20 }} />
        <span style={{ marginLeft: 12 }}>Log Out</span>
      </li>
    );
  };

  renderListItem = (route, image, title) => {
    const { routeName } = this.state;
    return (
      <li
        className={
          routeName == route ? "sidebar-active-list" : "sidebar-inactive-list"
        }
        onClick={() => this.handleNavigation(route)}
      >
        <img src={image} style={{ width: 20 }} />
        <span style={{ marginLeft: 12 }}>{title}</span>
      </li>
    );
  };

  renderDropdownListItem = (list) => {
    return (
      <ul style={{ listStyle: "none", marginTop: 10, paddingLeft: 0 }}>
        {list &&
          list.map((val, ind) => {
            return (
              <li
                key={ind}
                onClick={() => this.handleNavigation(val.route)}
                className="sidebar-dropdown-item"
              >
                {val.title}
              </li>
            );
          })}
      </ul>
    );
  };

  renderDropdownList = (image, title, listItems) => {
    const { showDropdown } = this.state;
    return (
      <li
        className={
          showDropdown === title
            ? "sidebar-dropdown-active-list"
            : "sidebar-dropdown-list"
        }
        onClick={() =>
          this.setState({ showDropdown: showDropdown === title ? "" : title })
        }
      >
        <div className="d-flex align-items-center justify-content-between">
          <span className="d-flex align-items-center">
            <img src={image} style={{ width: 20 }} />
            <span style={{ marginLeft: 12 }}>{title}</span>
          </span>
          <span className="">
            <img src={Images.saloon_drawer_dropdown} style={{ width: 12 }} />
          </span>
        </div>
        {showDropdown === title && this.renderDropdownListItem(listItems)}
      </li>
    );
  };

  render() {
    return (
      <div
        className="col-12 px-0 min-vh-100"
        style={{ backgroundColor: "#424242" }}
      >
        <div
          className="text-center py-1"
          style={{ backgroundColor: "#FBEFEF" }}
        >
          <img src={Images.easy1_logo_800x300} style={{ width: 94 }} />
        </div>
        <div className="">
          <ul className="sidebar-list">
            {this.renderListItem(
              "/super-dashboard",
              Images.saloon_drawer_dashboard,
              "Dashboard"
            )}
            {this.renderListItem(
              "/super-chart-box",
              Images.saloon_drawer_chat_box,
              "Chat Box"
            )}
            {this.renderListItem(
              "/super-salon-management",
              Images.salon_management,
              "Salon Management"
            )}
            {this.renderListItem(
              "/super-salon-category",
              Images.salon_category,
              "Salon Category"
            )}
            {this.renderListItem(
              "/super-appointment-management",
              Images.appointment_management,
              "Appointment Management"
            )}
            {this.renderListItem(
              "/super-salon-rating-review",
              Images.Salon_Rating_reviews,
              "Salon Rating & reviews"
            )}
            {this.renderListItem(
              "/super-salon-top-list",
              Images.Top_Salons_List,
              "Top Salons List"
            )}
            {this.renderListItem(
              "/super-employee",
              Images.Employee_Drawer,
              "Employee"
            )}
            {this.renderListItem(
              "/super-customer",
              Images.Customer_Drawer,
              "Customer"
            )}
            {this.renderListItem("/super-faq", Images.Faqs_Drawer, "FAQ")}
            {this.renderListItem(
              "/super-term-policies",
              Images.terms_conditon,
              "Term And Cancellation Policies"
            )}
            {/* {this.renderListItem(
              "/super-promotion-events",
              Images.saloon_drawer_chat_box,
              "Promotion And Events"
            )} */}
            {this.renderListItem(
              "/saloon-salon-wallet",
              Images.wallet_icon,
              "Salon Wallet"
            )}
            {this.renderListItem(
              "/pricing-plan",
              Images.pricing,
              "Pricing & Plan"
            )}
            {this.renderListItem(
              "/link-social-media",
              Images.link,
              "Link Social Media"
            )}
            {this.renderListItem(
              "/demostration-reservation",
              Images.appointment_management,
              "Demonstration Reservations"
            )}
            {this.renderListItem("/setting", Images.setting, "Setting")}
            {this.renderListItem(
              "/announcements",
              Images.setting,
              "Announcements"
            )}
            {/* {this.renderDropdownList(
              Images.saloon_drawer_chat_box,
              "Salon Wallet",
              [
                {
                  title: "Salon",
                  route: "/saloon-salon-wallet",
                },
                // {
                //   title: "Employee",
                //   route: "/saloon-employee-wallet",
                // },
              ]
            )} */}
            {this.renderLogout()}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ saloonLogout: state.saloonLogout });

const action = { clear_logout };

export default connect(mapStateToProps, action)(SidebarContent);
