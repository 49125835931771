import { connect } from "react-redux";
import React, { Component } from "react";
import Sidebar from "react-sidebar";
import axios from "axios";
import { Images } from "../../theme";
import { SidebarContent, Footer } from "../../components/SuperAdmin";
import {
  createResource,
  getResource,
  updateResource,
} from "../../config/simpleApiCalls";
import {
  add_social_link,
  get_social_links,
  update_social_links,
} from "../../config/WebServices";
import { DotsLoader, Header } from "../../components";
import { SuccessHelper, ErrorHelper } from "../../helpers";

import "./styles.css";
import Sytles from "./sytles";

class LinkSocialMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      facebookLink: "",
      instagramLink: "",
      twitterLink: "",
      youtubeLink: "",
      snapchatLink: "",
      linkedinLink: "",

      facebookIcon: "https://img.icons8.com/color/2x/facebook-new.png",
      instagramIcon:
        "https://image.flaticon.com/icons/png/128/2111/2111463.png",
      twitterIcon: "https://img.icons8.com/fluent/2x/twitter.png",
      youtubeIcon: "https://img.icons8.com/fluent/2x/youtube-squared.png",
      snapChatIcon: "https://img.icons8.com/fluent/2x/snapchat.png",
      linkedInIcon: "https://image.flaticon.com/icons/png/512/145/145807.png",
      // facebookLink: 'https://www.facebook.com/test-user-91',
      // instagramLink: 'https://www.instagram.com/test-user-91',
      // twitterLink: 'https://twitter.com/test-user-91',
      // youtubeLink: 'https://www.youtube.com/test-user-91',
      // snapchatLink: 'https://www.snapchat.com/test-user-91',
      // linkedinLink: 'https://www.linkedin.com/test-user-91',
      hasLink: false,
      access_token: "",
      companyId: "",
      isloading: false,
      linkId: "",
    };
  }

  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    this.setState({ superAdminData }, () => this.handleGetLinks());
  }

  handleGetLinks = () => {
    const { superAdminData } = this.state;
    getResource(get_social_links, superAdminData?.access_token)
      .then((res) => {
        console.log("res: ", res.data.data[0].__id);
        if (res.data.success) {
          this.setState({
            isloading: false,
            linkId: res.data.data[0] && res.data.data[0]._id,
            facebookLink: res.data.data[0].socialURL[0]
              ? res.data.data[0]?.socialURL[0].url
              : "",
            instagramLink: res.data.data[0].socialURL[3]
              ? res.data.data[0]?.socialURL[3].url
              : "",
            twitterLink: res.data.data[0].socialURL[1]
              ? res.data.data[0]?.socialURL[1].url
              : "",
            youtubeLink: res.data.data[0].socialURL[4]
              ? res.data.data[0]?.socialURL[4].url
              : "",
            snapchatLink: res.data.data[0].socialURL[2]
              ? res.data.data[0]?.socialURL[2].url
              : "",
            linkedinLink: res.data.data[0].socialURL[5]
              ? res.data.data[0]?.socialURL[5].url
              : "",
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  onCreateSocialMedia = async () => {
    this.setState({ isloading: true });
    const {
      access_token,
      facebookLink,
      instagramLink,
      twitterLink,
      youtubeLink,
      linkedinLink,
      snapchatLink,
      companyId,
      superAdminData,
    } = this.state;
    const payload = {
      socialURL: [
        { socialType: "facebook", url: facebookLink },
        // { socialType: "twitter", url: twitterLink },
        // { socialType: "snapchat", url: snapchatLink },
        { socialType: "instagram", url: instagramLink },
        { socialType: "youtube", url: youtubeLink },
        { socialType: "linkedin", url: linkedinLink },
      ],
    };

    createResource(add_social_link, payload, superAdminData.access_token)
      .then((res) => {
        console.log("res: ", res);
        if (res.data.success) {
          SuccessHelper.handleSuccess(`Links Successfully Updated!.`, true);
          this.setState({ isloading: false });
          this.handleGetLinks();
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        // ErrorHelper.handleSuccess(`Something Went Wrong!.`, true);
        console.log("ll", error);
      });
  };

  onUpdateSocialMedia = async () => {
    this.setState({ isloading: true });
    const {
      superAdminData,
      facebookLink,
      instagramLink,
      twitterLink,
      youtubeLink,
      linkedinLink,
      snapchatLink,
      linkId,
    } = this.state;
    const payload = {
      socialURL: [
        { socialType: "facebook", url: facebookLink },
        // { socialType: "twitter", url: twitterLink },
        // { socialType: "snapchat", url: snapchatLink },
        { socialType: "instagram", url: instagramLink },
        { socialType: "youtube", url: youtubeLink },
        { socialType: "linkedin", url: linkedinLink },
      ],
      linkId,
    };

    updateResource(update_social_links, payload, superAdminData?.access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
          });
          SuccessHelper.handleSuccess(`Links successfully updated.`, true);
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });

        ErrorHelper.handleErrors("Something Went Wrong", true);
        console.log(error);
      });

    // console.log(access_token);
    // console.log(companyId);
    // UpdateSaloonSocialMedia(payload, access_token)
    //   .then((res) => {
    //     if (res.data.success) {
    //       this.getAllSocialMedia();
    //     } else {
    //       this.setState({ isloading: false });
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error, "errorrrrrrrrrrrrrrrrrrr");
    //   });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  onChangeFacebook = (text) =>
    this.setState({ facebookLink: text.target.value });
  onChangeInstagram = (text) =>
    this.setState({ instagramLink: text.target.value });
  onChangeTwitter = (text) => this.setState({ twitterLink: text.target.value });
  onChangeYoutube = (text) => this.setState({ youtubeLink: text.target.value });
  onChangeSnapchat = (text) =>
    this.setState({ snapchatLink: text.target.value });
  onChangeLinkedin = (text) =>
    this.setState({ linkedinLink: text.target.value });

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 p-3">
        <h1 className="saloon-dashboard-heading">Social Media Icon:</h1>
        {/* <p className="saloon-dashboard-sub-heading">
          Lorem ipsum dolor sit amet, consectetur
        </p> */}
      </div>
    );
  };

  renderInputCol6 = (socialIcon, inputType, placeholder, onChange, value) => {
    return (
      <div class="form-group col-md-6 px-0 px-md-3 d-flex align-items-center">
        <img
          onClick={() => alert("SF")}
          class="mr-2"
          src={socialIcon}
          style={{
            width: 40,
            height: 40,
            borderRadius: "30px",
          }}
        />
        <input
          value={value}
          type={inputType}
          placeholder={placeholder}
          class="form-control saloon-link-social-input"
          onChange={(text) => onChange(text)}
        />
      </div>
    );
  };

  renderSocialLinkCard = () => {
    const {
      hasLink,
      facebookLink,
      twitterLink,
      instagramLink,
      youtubeLink,
      snapchatLink,
      linkedinLink,
      facebookIcon,
      instagramIcon,
      twitterIcon,
      youtubeIcon,
      linkedInIcon,
      snapChatIcon,
      linkId,
    } = this.state;
    return (
      <div className="col-12 p-3">
        <div className="col-12 p-3 bg-white" style={{ borderRadius: "1rem" }}>
          <div className="col-12 px-0 px-md-3">
            <p id="social-media-text" className="mb-3">
              Upload Social Media Icons & Links
            </p>
          </div>
          <div class="form-row mb-3">
            {this.renderInputCol6(
              facebookIcon,
              "text",
              "https://www.facebook.com/",
              this.onChangeFacebook,
              facebookLink,
              "sfsdfsdf"
            )}
            {this.renderInputCol6(
              instagramIcon,
              "text",
              "https://www.instagram.com/",
              this.onChangeInstagram,
              instagramLink,
              "sfsdfsdf"
            )}
            {this.renderInputCol6(
              youtubeIcon,
              "text",
              "https://www.youtube.com/",
              this.onChangeYoutube,
              youtubeLink,
              "sfsdfsdf"
            )}
            {/* {this.renderInputCol6(
              twitterIcon,
              "text",
              "https://twitter.com/",
              this.onChangeTwitter,
              twitterLink,
              "sfsdfsdf"
            )} */}
            {/* {this.renderInputCol6(
              youtubeIcon,
              "text",
              "https://www.youtube.com/",
              this.onChangeYoutube,
              youtubeLink,
              "sfsdfsdf"
            )} */}
            {/* {this.renderInputCol6(
              snapChatIcon,
              "text",
              "https://www.snapchat.com/",
              this.onChangeSnapchat,
              snapchatLink,
              "sfsdfsdf"
            )} */}
            {this.renderInputCol6(
              linkedInIcon,
              "text",
              "https://www.linkedin.com/",
              this.onChangeLinkedin,
              linkedinLink,
              "sfsdfsdf"
            )}
          </div>
          <div className="col-12 px-0 px-md-3">
            {!linkId ? (
              <button
                type="button"
                onClick={() => this.onCreateSocialMedia()}
                className="btn btn-light btn-sm mb-2 "
                id="saloon-save-social-link"
              >
                Save Changes
              </button>
            ) : (
              <button
                type="button"
                onClick={() => this.onUpdateSocialMedia()}
                className="btn btn-light btn-sm mb-2 "
                id="saloon-save-social-link"
              >
                Update Changes
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Sidebar
        sidebar={<SidebarContent {...this.props} />}
        open={this.state.sidebarOpen}
        onSetOpen={(val) => this.onSetSidebarOpen(val)}
        sidebarClassName="custom-sidebar"
        styles={Sytles}
      >
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          {<Header {...this.props} onSetSidebarOpen={this.onSetSidebarOpen} />}
          <div className="container py-5 mb-4 mb-md-5">
            <div className="row">
              {this.renderSaloonDashboardHeading()}
              {this.renderSocialLinkCard()}
            </div>
          </div>
          <Footer />
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => ({
  createSaloonSocialMedia: state.createSaloonSocialMedia,
});

const action = {
  //   create_social_media,
};

export default connect(mapStateToProps, action)(LinkSocialMedia);
