/** @format */

import * as firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfigChat = {
  apiKey: "AIzaSyA2THsdvgvkLWyLpCj39gPHlbCp65Djus4",
  authDomain: "scenic-lane-275515.firebaseapp.com",
  databaseURL: "https://scenic-lane-275515.firebaseio.com",
  projectId: "scenic-lane-275515",
  storageBucket: "scenic-lane-275515.appspot.com",
  messagingSenderId: "942023842711",
  appId: "1:942023842711:web:44bdbf7c28a4a5014ec2d2",
  measurementId: "G-H198Q4RWQ3",
};

// var firebaseConfigChat = {
//   apiKey: "AIzaSyCF5KL3JyVe5SjxqmBnT_Tgn6LUwf7HQ7c",
//   authDomain: "enhanced-chat.firebaseapp.com",
//   projectId: "enhanced-chat",
//   storageBucket: "enhanced-chat.appspot.com",
//   messagingSenderId: "225189523511",
//   appId: "1:225189523511:web:eee821735e222514d40fb2",
//   measurementId: "G-PK8QR9MM86",
// };
// const firebaseApp = firebase.initializeApp(firebaseConfig);

const firebaseApp = firebase.initializeApp(firebaseConfigChat);

// firebaseApp.writeDocument = (collection, document, data, merge=true) =>
//   firebaseApp
//     .firestore()
//     .collection(collection)
//     .doc(document)
//     .set(data, { merge });

// firebaseApp.addDocument = (collection, data) =>
//   firebaseApp
//     .firestore()
//     .collection(collection)
//     .add(data);

// firebaseApp.readDocument = (collection, document) =>
//   firebaseApp
//     .firestore()
//     .collection(collection)
//     .doc(document)
//     .get();

// firebaseApp.readCollection = collection =>
//   firebaseApp
//     .firestore()
//     .collection(collection)
//     .get();

export default firebaseApp;
// export {firebaseChat}
